<template>
  <div class="en-carousel">
    <vueper-slides
      ref="vueperslides1"
      class="en-carousel-photo"
      :touchable="false"
      fade
      :autoplay="false"
      fixed-height="530px"
      :bullets="false"
      @slide="
        vueperslides2.goToSlide($event.currentSlide.index, {
          emit: false,
        })
      "
    >
      <template v-for="(item, i) in items">
        <vueper-slide :key="i" :image="item.url" />
      </template>
    </vueper-slides>
    <vueper-slides
      v-if="items.length > 1"
      class="en-carousel-thumbs no-shadow"
      ref="vueperslides2"
      @slide="
        vueperslides1.goToSlide($event.currentSlide.index, {
          emit: false,
        })
      "
      :visible-slides="4"
      fixed-height="117.5px"
      :bullets="false"
      :autoplay="false"
      :touchable="false"
      :gap="2.5"
      :arrows="false"
    >
      <template v-for="(item, i) in items">
        <vueper-slide
          :key="i"
          :image="item.url"
          @click.native="vueperslides2.goToSlide(i)"
        />
      </template>
    </vueper-slides>
  </div>
</template>

<script>
import { ref } from 'vue';
import { VueperSlides, VueperSlide } from 'vueperslides';

export default {
  props: {
    items: { type: Array, default: () => [] },
  },
  components: {
    VueperSlides,
    VueperSlide,
  },
  setup() {
    const vueperslides1 = ref();
    const vueperslides2 = ref();
    return {
      vueperslides1,
      vueperslides2,
    };
  },
};
</script>

<style lang="scss">
.vueperslides:not(.no-shadow):not(.vueperslides--3d) .vueperslides__parallax-wrapper:after,
.vueperslides:not(.no-shadow):not(.vueperslides--3d) .vueperslides__parallax-wrapper:before {
  box-shadow: none !important;
}

.en-carousel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &-photo {
    margin-bottom: 10px;
    max-width: 434px;
    width: 100%;

    .vueperslides__track-inner {
      .vueperslide {
        border-radius: 5px;
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
  }

  &-thumbs {
    width: 100%;
    margin-bottom: 0px !important;
    .vueperslides__track-inner {
      .vueperslide {
        border-radius: 5px;
      }
    }
  }
}
</style>
