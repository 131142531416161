import Vue from 'vue';
import Vuex from 'vuex';
import userStore from '@/store/modules/userStore';
import adStore from '@/store/modules/adStore';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    userStore,
    adStore,
  },
});
