<template>
  <div>
    <div v-for="locationInfo in locationsInfo" :key="locationInfo.id"
         v-show="location === locationInfo.id"  class="mt-5">
      <h1 class="text-center">{{locationInfo.title}}</h1>
      <p v-html="locationInfo.text" />
    </div>
  </div>
</template>

<script>
import locationsInfo from '@/json/locationsInfo.json';

export default {
  props: {
    location: { type: String, default: '' },
  },
  setup() {
    return {
      locationsInfo,
    };
  },
};
</script>
