<template>
  <container class="d-flex justify-content-center my-15">
    <div class="login w-100">
      <h1 class="fs-20 mb-11">Recover Password</h1>

      <a-tabs v-model="activeTab" default-active-key="1">
        <a-tab-pane key="1">
          <div slot="tab"><a-icon type="pink-mail" /> E-mail</div>
          <a-form-model
            ref="emailForm"
            class="mt-5"
            :model="form"
            :rules="emailFormRules"
            layout="vertical"
          >
            <a-form-model-item :colon="false" prop="email">
              <a-input
                v-model="form.email"
                placeholder="Enter your email here"
              />
            </a-form-model-item>
            <a-button
              type="secondary"
              class="mb-5"
              :loading="isProcessingRecoverEmail"
              @click="submitRecoverByEmail"
              block
            >
              Advance
            </a-button>
          </a-form-model>
        </a-tab-pane>
        <a-tab-pane key="2" :disabled="!isRecoverEmailSuccess" force-render>
          <div slot="tab"><a-icon type="pink-calendar" /> Code</div>
          <a-form-model class="mt-5" layout="vertical">
            <a-form-model-item :colon="false" prop="code">
              <a-input v-model="form.code" placeholder="Enter your code here" />
            </a-form-model-item>
            <a-button
              type="secondary"
              class="mb-5"
              :disabled="!form.code"
              :loading="isProcessingRecoverByCode"
              @click="submitRecoverByCode"
              block
            >
              Advance
            </a-button>
          </a-form-model>
        </a-tab-pane>
        <a-tab-pane key="3" :disabled="!isRecoverByCodeSuccess">
          <div slot="tab"><a-icon type="pink-lock" />Password</div>
          <a-form-model
            ref="passwordForm"
            :model="form"
            class="mt-5"
            :rules="passwordFormRules"
            layout="vertical"
          >
            <a-form-model-item :colon="false" prop="password">
              <a-input
                v-model="form.password"
                placeholder="Enter your password here"
              />
            </a-form-model-item>
            <a-button
              type="secondary"
              class="mb-5"
              :loading="isProcessingRecoverPassword"
              @click="submitRecoverByPassword"
              block
            >
              Advance
            </a-button>
          </a-form-model>
        </a-tab-pane>
      </a-tabs>
    </div>
  </container>
</template>

<script>
import axios from 'axios';
import { computed, reactive, ref } from 'vue';
import { message } from 'ant-design-vue';
import AuthService from '@/services/AuthService';
import useGlobal from '@/composables/useGlobal';

export default {
  metaInfo() {
    return {
      title: 'Reality Escorts',
      meta: [{
        name: 'description', content: 'Find the best independent and agency escorts at Reality Escorts.',
      }],
    };
  },
  setup() {
    const { goTo } = useGlobal();
    const activeTab = ref('1');
    const form = reactive({
      email: '',
      password: '',
      code: '',
    });
    const emailValidation = (rule, value, callback) => {
      const re = /\S+@\S+\.\S+/;
      if (!re.test(value)) callback(new Error('Please input a valid e-mail'));
      else callback();
    };

    const emailForm = ref();
    const emailFormRules = computed(() => ({
      email: [{ validator: emailValidation, trigger: 'change' }],
    }));

    const isProcessingRecoverEmail = ref(false);
    const isRecoverEmailSuccess = ref(false);
    const recoverByEmail = async () => {
      try {
        isProcessingRecoverEmail.value = true;
        const payload = { email: form.email };
        const { data } = await AuthService.recoverByEmail(payload);
        message.success(data);
        isRecoverEmailSuccess.value = true;
        activeTab.value = '2';
      } catch (error) {
        message.error(error.response.data);
      } finally {
        isProcessingRecoverEmail.value = false;
      }
    };

    const submitRecoverByEmail = () => {
      emailForm.value.validate((valid) => {
        if (valid) recoverByEmail();
      });
    };

    const headers = ref({
      'Content-Type': 'application/json',
      Authorization: '',
    });

    const isProcessingRecoverByCode = ref(false);
    const isRecoverByCodeSuccess = ref(false);

    const submitRecoverByCode = async () => {
      try {
        isProcessingRecoverByCode.value = true;
        const payload = { code: form.code };
        const { data } = await AuthService.recoverByCode(payload);
        headers.value.Authorization = data.accesstoken;
        message.success('Now you can change your password');
        isRecoverByCodeSuccess.value = true;
        activeTab.value = '3';
      } catch (error) {
        message.error(error.response.data);
      } finally {
        isProcessingRecoverByCode.value = false;
      }
    };

    const passwordValidation = (rule, value, callback) => {
      if (value.length < 6) {
        callback(new Error('Please enter at least 6 characters'));
      }
      callback();
    };
    const passwordForm = ref();
    const passwordFormRules = computed(() => ({
      password: [{ validator: passwordValidation, trigger: 'change' }],
    }));

    const isProcessingRecoverPassword = ref(false);
    const recoverPassword = async () => {
      try {
        isProcessingRecoverPassword.value = true;
        const payload = { password: form.password };
        await axios.post(
          `${process.env.VUE_APP_BASE_URL}/recovery/newpassword`,
          payload,
          { headers: headers.value },
        );
        message.success('Password recovered with success.');
        goTo('/login');
      } catch (error) {
        message.error(error.response.data);
      } finally {
        isProcessingRecoverPassword.value = false;
      }
    };

    const submitRecoverByPassword = () => {
      passwordForm.value.validate((valid) => {
        if (valid) recoverPassword();
      });
    };

    return {
      activeTab,
      form,
      emailForm,
      emailFormRules,
      submitRecoverByEmail,
      isProcessingRecoverEmail,
      isRecoverEmailSuccess,
      submitRecoverByCode,
      isProcessingRecoverByCode,
      isRecoverByCodeSuccess,
      passwordForm,
      passwordFormRules,
      isProcessingRecoverPassword,
      submitRecoverByPassword,
    };
  },
};
</script>

<style lang="scss">
.login {
  max-width: 489px;
}
</style>
