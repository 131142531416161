<template>
  <a-modal
    title="Upgrade ad"
    :visible="value"
    @cancel="$emit('input', false)"
    width="600px"
  >
    <div class="d-flex mb-5">
      <div
        class="ad-info-image pointer mr-5"
        @click="goTo(`/model/${currentAd.id}`)"
      >
        <a-avatar :src="currentAd.photos[0]" shape="square" :size="150"/>
      </div>
      <div class="ad-info-details">
        <div class="ad-info-details-views">
          {{ currentAd.views }}
          <a-icon class="ml-2 ih-11" type="white-eye" />
        </div>
        <p class="fs-10 fw-medium mb-2 white-color" v-if="currentAd.created_at">
          Created at
          {{ formatRelative(new Date(currentAd.created_at), new Date()) }}
        </p>

        <div class="d-flex mb-2">
          <div
            v-if="currentAd.plan !== 'basic'"
            :class="`ad-info-details-plan-${currentAd.plan}${
              currentAd.isNew ? ' mr-2' : ''
            }`"
          >
            <a-icon class="ih-11" :type="getPlanIcon(currentAd.plan)" />
          </div>
          <div v-if="currentAd.isNew" class="ad-info-details-plan-new">
            <a-icon class="ih-14" type="white-fire" />
          </div>
        </div>

        <div class="fs-18 fw-semi-bold mb-1">
          {{ currentAd.name }}
        </div>

        <div class="fs-12 fw-normal mb-2">
          {{ currentAd.title }}
        </div>

        <div>
          <a-tag
            v-for="(tag, index) in currentAd.tag"
            color="#14CDD4"
            :key="index"
          >
            {{ tag.name }}
          </a-tag>
        </div>
      </div>
    </div>

    <div v-if="getGoldPrice() === 'unavailable'" class="text-center w-100">
      You can't upgrade to gold because there are few days left to expire.
      <br>Please upgrade to gold on renew.
    </div>

    <a-row class="mb-3" :gutter="[20, 20]">
      <a-col :md="12" :sm="24" v-if="currentAd.plan === 'basic' || currentAd.plan === 'silver'">
        <en-plan-card
          v-if="getGoldPrice() !== 'unavailable'"
          icon="white-crown"
          name="Gold Vip"
          :value="getGoldLabel()"
          id="gold"
          :isLoading="isPricesLoading"
          :selected="currentAd.selectedPlan === 'gold'"
          color="#ffffff"
          backgroundColor="#F7B422"
          :items="goldItems"
          @click:selected="$emit('update:selectedPlan', $event)"
        />
      </a-col>
      <a-col :md="12" :sm="24" v-if="currentAd.plan === 'basic'">
        <en-plan-card
          name="Silver"
          id="silver"
          :value="`£${plansPrices.silver}`"
          color="#ffffff"
          :isLoading="isPricesLoading"
          backgroundColor="#838282"
          icon="white-star"
          :selected="currentAd.selectedPlan === 'silver'"
          :items="silverItems"
          @click:selected="$emit('update:selectedPlan', $event)"
        />
      </a-col>
    </a-row>
    <en-service-tag
      name="Tag new"
      v-if="!currentAd.isNew"
      value="£4,99"
      icon="white-fire"
      :selected="currentAd.isNewSelected"
      @click:selected="$emit('update:isNewSelected', $event)"
    />

    <template slot="footer">
      <a-button key="back" @click="$emit('input', false)"> Cancel </a-button>
      <a-button
        key="submit"
        type="primary"
        :loading="isLoading"
        :disabled="confirmDisabled"
        @click="upgradeAd"
      >
        Upgrade
      </a-button>
    </template>
  </a-modal>
</template>

<script>
import { computed } from 'vue';
import {
  formatDistanceToNowStrict,
  formatRelative,
  isBefore,
  formatDistanceToNow,
} from 'date-fns';
import useAd from '@/composables/useAd';
import EnPlanCard from '@/components/molecules/EnPlanCard.vue';
import EnServiceTag from '@/components/molecules/EnServiceTag.vue';

export default {
  props: {
    value: { type: Boolean },
    isLoading: { type: Boolean },
    currentAd: { type: Object },
    isPricesLoading: { type: Boolean },
    plansPrices: { type: Object },
  },
  components: {
    EnPlanCard,
    EnServiceTag,
  },
  setup(props, { emit }) {
    const {
      goldItems, silverItems, freeItems, getPlanIcon,
    } = useAd();

    const getGoldPrice = () => {
      const isLondon = props.currentAd?.city?.id === '4';
      const expirationString = formatDistanceToNow(props.currentAd.expires_at
        ? new Date(props.currentAd.expires_at) : new Date());
      if (props.currentAd.plan === 'silver') {
        if (expirationString.includes('7')) return isLondon ? 7.68 : 6.00;
        if (expirationString.includes('6')) return isLondon ? 5.69 : 5.00;
        if (expirationString.includes('5')) return isLondon ? 3.70 : 4.00;
        return 'unavailable';
      }
      return props.plansPrices.gold;
    };

    const getGoldLabel = () => `£${getGoldPrice()}`.replace('.', ',');

    const upgradeAd = () => {
      const payload = {
        plan: props.currentAd.selectedPlan === props.currentAd.plan ? '' : props.currentAd.selectedPlan,
        isNew: props.currentAd.isNew ? false : props.currentAd.isNewSelected,
        id: props.currentAd.id,
      };
      const goldPrice = getGoldPrice() === 'unavailable' ? props.plansPrices.gold : getGoldPrice();
      emit('onUpgrade', payload);
      emit('update:goldPrice', goldPrice);
      emit('input', false);
    };

    const confirmDisabled = computed(() => (
      (props.currentAd.selectedPlan === '' || props.currentAd.selectedPlan === props.currentAd.plan)
     && (props.currentAd.isNewSelected === Boolean(props.currentAd.isNew)))
      || props.isPricesLoading);

    return {
      goldItems,
      silverItems,
      freeItems,
      upgradeAd,
      formatDistanceToNowStrict,
      formatRelative,
      isBefore,
      formatDistanceToNow,
      getPlanIcon,
      getGoldPrice,
      getGoldLabel,
      confirmDisabled,
    };
  },
};
</script>

<style lang="scss">
@import "../EnAdInfo.scss";
</style>
