import { ref } from 'vue';

const useAd = () => {
  const goldItems = ref([
    { label: 'up to 8 photos' },
    // { label: 'Carousel at the top of the page' },
    { label: 'TOP of the page' },
    { label: 'Unlimited manual boosts (one per 30min)' },
    { label: '3 Locations' },
    { label: '3 TAGS on the photo' },
    { label: '1 video' },
  ]);

  const silverItems = ref([
    { label: 'up to 6 photos' },
    { label: 'Above of basic ADS' },
    { label: '3 locations ' },
    { label: '5 boost day (once an hour)' },
    { label: '3 TAGS on the photo', unavailable: true },
    { label: '1 video', unavailable: true },
    { label: 'Unlimited manual boosts (one per 30min)', unavailable: true },
  ]);

  const freeItems = ref([
    { label: 'up to 4 photos' },
    { label: '1 Location' },
    { label: '5 boost day (once an hour)', unavailable: true },
    { label: '3 TAGS on the photo', unavailable: true },
    { label: '1 video', unavailable: true },
    { label: 'Unlimited manual boosts (one per 30min)', unavailable: true },
  ]);

  const getPlanIcon = (plan) => {
    if (plan === 'gold') return 'white-crown';
    if (plan === 'silver') return 'white-star';
    return '';
  };
  return {
    goldItems,
    silverItems,
    freeItems,
    getPlanIcon,
  };
};

export default useAd;
