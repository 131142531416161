<template>
  <div>
    <div class="d-flex">
      <en-skeleton
        type="rect"
        width="134px"
        rounded
        :radius="5"
        height="168px"
        class="mx-5 d-none md-d-block"
        wave-color="rgba(255, 255, 255, 0.3)"
        animation="wave"
      />
      <en-skeleton
        type="rect"
        width="200px"
        rounded
        :radius="5"
        height="200px"
        class="mx-5 md-d-none"
        wave-color="rgba(255, 255, 255, 0.3)"
        animation="wave"
      />
      <div>
        <en-skeleton
          class="mb-7"
          type="rect"
          rounded
          wave-color="rgba(255, 255, 255, 0.3)"
          :width="150"
          :height="10"
          :radius="50"
          animation="wave"
        />
        <en-skeleton
          type="rect"
          width="24px"
          rounded
          :radius="5"
          height="24px"
          class="mb-2"
          wave-color="rgba(255, 255, 255, 0.3)"
          animation="wave"
        />
        <en-skeleton
          class="mb-3"
          type="rect"
          rounded
          wave-color="rgba(255, 255, 255, 0.3)"
          :width="180"
          :height="18"
          :radius="50"
          animation="wave"
        />
        <en-skeleton
          class="mb-7"
          type="rect"
          rounded
          wave-color="rgba(255, 255, 255, 0.3)"
          :width="100"
          :height="12"
          :radius="50"
          animation="wave"
        />
      </div>
    </div>
    <en-skeleton
      type="rect"
      width="calc(100% - 60px)"
      height="53px"
      rounded
      :radius="5"
      class="mt-4 mx-5 d-none md-d-block"
      wave-color="rgba(255, 255, 255, 0.3)"
      animation="wave"
    />
    <hr />
  </div>
</template>

<script>
export default {
  setup() {
    return {};
  },
};
</script>
