<template>
  <div class="my-15">
    <container>
      <div class="d-flex flex-column align-center">
        <div :style="{ maxWidth: '700px' }">
          <a-row :gutter="[20, 20]" class="w-100">
            <a-col :sm="24" :md="12">
              <en-label iconWidth="colors" class="mb-4">
                <span class="fs-13 fw-medium ml-3">Search:</span>
              </en-label>
              <en-search
                class="z-0 w-100"
                placeholder="Search"
                @change="search = $event.target.value"
              />
            </a-col>
            <a-col :sm="24" :md="12">
              <en-label iconWidth="colors" class="mb-4">
                <span class="fs-13 fw-medium ml-3">Country:</span>
              </en-label>
              <a-select
                class="w-100"
                :default-value="countryId"
                @change="getCities"
              >
                <a-select-option
                  v-for="country in countries"
                  :key="country.id"
                  :value="country.id"
                >
                  {{ country.id === '1' ? 'UK' : country.name }}
                </a-select-option>
              </a-select>
            </a-col>
            <!--
          <a-col :sm="24" :md="12">
            <en-label iconWidth="colors" class="mb-4">
              <span class="fs-13 fw-medium ml-3">Region:</span>
            </en-label>
            <a-auto-complete
              :value="''"
              :data-source="cities"
              :disabled="isLoading"
              class="w-100 z-0"
              :placeholder="isLoading ? 'Loading...' : 'Search city here'"
              @select="onCityIdChange"
            />
          </a-col>
          <a-col :sm="24" :md="12">
            <en-label iconWidth="colors" class="mb-4">
              <span class="fs-13 fw-medium ml-3">Subregion:</span>
            </en-label>
            <a-auto-complete
              :value="''"
              :data-source="cities"
              :disabled="isLoading"
              class="w-100 z-0"
              :placeholder="isLoading ? 'Loading...' : 'Search city here'"
              @select="onCityIdChange"
            />
          </a-col>
          -->
            <a-col :sm="24" :md="12">
              <en-label iconWidth="colors" class="mb-4">
                <span class="fs-13 fw-medium ml-3">City:</span>
              </en-label>
              <a-auto-complete
                :value="cityId"
                :data-source="cities"
                :disabled="isLoading"
                class="w-100 z-0"
                :placeholder="isLoading ? 'Loading...' : 'Search city here'"
                @select="onCityIdChange"
              />
            </a-col>
            <a-col :sm="24" :md="12">
              <en-label iconWidth="colors" class="mb-4">
                <span class="fs-13 fw-medium ml-3">Local area:</span>
              </en-label>
              <a-auto-complete
                :value="districtId"
                class="w-100"
                :disabled="isLoading || isDistrictsLoading"
                :data-source="districts"
                :placeholder="
                  isLoading || isDistrictsLoading
                    ? 'Loading...'
                    : 'Search local area here'
                "
                @select="onDistrictSelect"
                :filter-option="filterOption"
              />
            </a-col>
          </a-row>

          <div class="text-center mb-8 mt-10">
            <a-button
              type="primary"
              block
              :loading="isSearching"
              @click="searchAds"
            >
              Search
            </a-button>
          </div>

          <p class="fs-18 fw-semi-bold my-15">
            Welcome to Reality Escorts where you can find the
            <span class="pink-color-1">perfect courtesan for you.</span>
          </p>

          <a-row :gutter="[20, 20]">
            <a-col :sm="24" :md="12">
              <div class="re-category-card input-shadow">
                <div class="re-category-card-header"></div>
                <img :src="escortsCategory" />
                <div class="re-category-card-footer">
                  <a-button
                    type="primary"
                    block
                    :loading="isSearching"
                    @click="onFindTheBestEscortsClick"
                  >
                    Find the best escorts
                  </a-button>
                  <hr />
                  <p class="fs-13 fw-medium pink-color-1">Escorts London</p>
                </div>
              </div>
            </a-col>
          </a-row>
        </div>
      </div>

      <hr class="my-15" />

      <div class="d-flex flex-column align-center">
        <div :style="{ maxWidth: '700px' }">
          <a-row :gutter="[20, 20]">
            <a-col :sm="24" :md="8">
              <div class="text-center">
                <img :src="logo" class="mt-2" />
              </div>
            </a-col>
            <a-col :sm="24" :md="16">
              <p>
                No matter where you travel in the uk or what time it may be,
                business trip or leisure we have the perfect platform for you to
                search and find the lady of your dreams. With a touch of a
                button you can get through to very sexy gorgeous escorts who are
                gagging to please you for as long as you desire.
              </p>
            </a-col>
            <!-- <a-col :sm="24">
            <p class="text-center">
              We have a brilliant selection of women from all around the world
              so wether your in the mood for a bubbly British blonde, spicy
              exotic Asian woman, flirtatious European slut or a hot South
              American we have the selection ready for you, or maybe you want
              two girls at the same time? Treat yourself!
            </p>
          </a-col> -->
          </a-row>

          <!-- <p class="pink-color-1 fs-18 fw-semi-bold text-center mb-15">
          The escorts specialize in their own services such as:
        </p>

        <a-row :gutter="[20, 20]">
          <a-col :sm="24" :md="12" v-for="service in services" :key="service">
            <en-display-box>{{ service }}</en-display-box>
          </a-col>
        </a-row> -->
        </div>
      </div>
    </container>
    <a-row class="w-100 mt-15 input-shadow">
      <a-col :sm="24" :md="24" :lg="12">
        <a-avatar
          :src="publiOne"
          shape="square"
          :style="{ width: '100%', height: '300px' }"
        />
      </a-col>
      <a-col :sm="24" :md="24" :lg="12" class="position-relative">
        <a-avatar
          :src="publiTwo"
          shape="square"
          :style="{ width: '100%', height: '300px' }"
        />
        <div
          class="w-100 position-absolute d-flex justify-content-center align-items-center"
          :style="{ top: '0', height: '300px' }"
        >
          <!-- <p
          class="fs-14 fw-semi-bold m-0 p-3"
          :style="{ top: '0', maxWidth: '575px' }"
        >
          and much much more, don’t be shy to ask our wonderful ladies of all
          ages, shapes, sizes and ethnic backgrounds if they can act out your
          fantasies. We are here to provide the best and most beautiful women
          for the best prices. Do not hesitate and try it out for yourself.
          <br />
          <br />
          If you feel lonely and you want to have some fun, hire independent
          escorts from our easy to choose platform today.
        </p> -->
        </div>
      </a-col>
    </a-row>
    <div class="mx-auto mt-10" :style="{ maxWidth: '960px' }">
      <en-location-info location="home"/>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import EnSearch from '@/components/atoms/EnSearch.vue';
import EnLabel from '@/components/atoms/EnLabel.vue';
import EnLocationInfo from '@/components/atoms/EnLocationInfo.vue';
import logo from '@/assets/images/svg/reality-escorts-vertical.svg';
// import EnDisplayBox from '@/components/atoms/EnDisplayBox.vue';
import publiOne from '@/assets/images/png/reality-escorts-publi-1.png';
import publiTwo from '@/assets/images/png/reality-escorts-publi-2.png';
import escortsCategory from '@/assets/images/png/escorts-category.png';
import LocationService from '@/services/LocationService';
import router from '@/router/router';
import store from '@/store/store';

export default {
  metaInfo() {
    return {
      title: 'Reality Escorts',
      meta: [{
        name: 'description', content: 'Find the best independent and agency escorts at Reality Escorts.',
      }],
    };
  },
  components: {
    EnSearch,
    EnLabel,
    EnLocationInfo,
    // EnDisplayBox,
  },
  setup() {
    const services = [
      'Girlfriend experience',
      'Porn star experience',
      'Foot Fetish & Worship',
      'Massages and happy endings',
      'OWO',
      'Mistress',
      'Domination',
      'Water sports and other',
      'Dinner dates',
      'Travel companion ',
      'Anal',
      'Roleplays',
    ];

    const search = ref('');

    const cities = ref([]);
    const cityId = ref('');
    const countries = ref([]);
    const countryId = ref('1');

    const isDistrictsLoading = ref(false);
    const districtId = ref('uk');

    const districts = ref([]);
    const isNumber = (string) => /^\d+$/.test(string);
    const allDistricts = ref([]);

    const fetchDistrict = async () => {
      try {
        districtId.value = '';
        isDistrictsLoading.value = true;
        let finalDistricts = [];
        if (isNumber(cityId.value)) {
          const { data } = await LocationService.fetchDistrict({ cityId: cityId.value });
          finalDistricts = data;
        } else {
          finalDistricts = allDistricts.value;
        }
        districts.value = finalDistricts.map(({ id, name, urlName }) => ({
          value: id,
          text: name,
          urlName,
        }));
      } catch (error) {
        console.log(error);
      } finally {
        isDistrictsLoading.value = false;
      }
    };

    const getCities = (selectedCountryId) => {
      countryId.value = selectedCountryId;
      const selectedCountry = countries.value.find(
        (country) => country.id === selectedCountryId,
      );
      if (selectedCountry) {
        const selectedCities = selectedCountry.regions.flatMap((
          region,
        ) => region.subRegion.flatMap((subRegion) => subRegion.cities));
        cities.value = selectedCities.map(({ id, name }) => ({
          value: id,
          text: name,
        }));
        cityId.value = cities.value[0].value;

        allDistricts.value = selectedCountry.regions.flatMap((
          region,
        ) => region.subRegion.flatMap((subRegion) => subRegion.cities.flatMap((
          city,
        ) => city.districts)));

        if (selectedCountryId === '1') {
          const allUk = { value: 'uk', text: 'All UK' };
          cities.value = [allUk, ...cities.value];
          cityId.value = 'uk';
        } else {
          const allCountry = { value: selectedCountry.name, text: `All ${selectedCountry.name}` };
          cities.value = [allCountry, ...cities.value];
          cityId.value = selectedCountry.name;
        }

        fetchDistrict();
      }
    };

    const isLoading = ref(false);

    const fetchCountries = async () => {
      try {
        isLoading.value = true;
        const { data } = await LocationService.fetchCountry();
        countries.value = data;
        getCities(countryId.value);
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };

    fetchCountries();

    const onDistrictSelect = (value) => {
      districtId.value = value;
    };

    const filterOption = (input, option) => option.componentOptions.children[0].text
      .toUpperCase()
      .indexOf(input.toUpperCase()) >= 0;

    const getDistrictName = () => {
      const district = districts.value.find(
        ({ value }) => value === districtId.value,
      );
      return district?.urlName || '';
    };

    fetchDistrict();

    const onCityIdChange = (value) => {
      cityId.value = value;
      fetchDistrict();
    };

    const isSearching = ref(false);
    const searchAds = async () => {
      const district = getDistrictName();
      const locationType = district ? 'district' : 'city';
      const locationId = district ? districtId.value : cityId.value;
      try {
        isSearching.value = true;
        const { data } = await LocationService.fetchLocation(
          locationType,
          locationId,
        );
        let url = '';
        if (locationId === 'uk') url = '/escorts/uk';
        else if (locationId === 'Ireland') url = '/escorts/ireland';
        else {
          url = `/escorts/${data.country.urlName}/${data.region.urlName}/${
            data.sub_region.urlName
          }/${data.city.urlName}/${district}${
            search.value ? `?name=${search.value}` : ''
          }`;
        }
        store.dispatch('adStore/setAds', []);
        store.dispatch('adStore/setDefaultParams');
        router.push(url);
      } catch (error) {
        console.log(error);
      } finally {
        isSearching.value = false;
      }
    };

    const onFindTheBestEscortsClick = () => {
      store.dispatch('adStore/setAds', []);
      store.dispatch('adStore/setDefaultParams');
      router.push('/escorts/uk/london-region/greater-london/london');
    };

    return {
      logo,
      services,
      publiOne,
      publiTwo,
      escortsCategory,
      search,
      districtId,
      onDistrictSelect,
      districts,
      filterOption,
      cities,
      onCityIdChange,
      cityId,
      isDistrictsLoading,
      searchAds,
      isSearching,
      countries,
      getCities,
      countryId,
      isLoading,
      onFindTheBestEscortsClick,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_variables.scss';
.re-category-card {
  position: relative;
  width: 100%;
  height: 354px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:after {
    position: absolute;
    bottom: 0;
    left: -1px;
    content: '';
    width: 100%;
    height: 250px;
    background: linear-gradient(
      to top,
      #000,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0)
    );
  }

  &-header {
    position: absolute;
    width: 100%;
    border-top: 3px solid $primary-color;
    top: 0;

    &:after {
      content: url('../assets/images/svg/black-lips.svg');
      position: absolute;
      right: 0;
      background-color: $primary-color;
      height: 31px;
      padding-top: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 31px;
    }
  }

  &-footer {
    width: 100%;
    z-index: 1;
    position: absolute;
    bottom: 0;
    padding: 0 20px;

    hr {
      border-top: 1px solid;
    }
  }
}
</style>
