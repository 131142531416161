<template>
  <div class="d-flex align-center">
    <a-icon v-if="icon" :style="{width: iconWidth}" :type="icon" />
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    icon: String,
    iconWidth: String,
  },
};
</script>
