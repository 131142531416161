const defaultState = {
  name: '',
  email: '',
  acessToken: '',
  id: '',
  age: null,
  photo: '',
  isLightMode: false,
  isVerified: false,
};

export default {
  namespaced: true,
  state: {
    ...defaultState,
  },
  mutations: {
    SET_USER(state, payload) {
      state.name = payload.name;
      state.email = payload.email;
      state.acessToken = payload.acessToken;
      state.id = payload.id;
      state.isVerified = Boolean(payload.verified);
    },
    SET_IS_LIGHT_MODE(state, payload) {
      state.isLightMode = payload;
    },
  },
  getters: {
    getUser(state) {
      return state;
    },
    isAuthenticated(state) {
      return !!state.name && !!state.email && !!state.acessToken;
    },
    isLightMode(state) {
      return state.isLightMode;
    },
  },
  actions: {
    setUser({ commit }, payload) {
      commit('SET_USER', payload);
    },
    setIsLightMode({ commit }, payload) {
      commit('SET_IS_LIGHT_MODE', payload);
    },
  },
};
