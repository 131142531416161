<template>
  <div>
    <div class="d-flex justify-content-between mb-2">

      <div class="fs-12 mr-3 mt-1">
        <div class="fs-12 mr-3 mt-1 ">
          <span class="fw-semi-bold">City:</span> <span class="">{{ ad.city.name }}</span>
        </div>
        #code: <span class="fw-semi-bold">{{ ad.id }}</span>
      </div>

      <a-dropdown :trigger="['click']" placement="bottomRight">
        <div
          class="d-flex align-items-center pointer"
          @click="(e) => e.preventDefault()"
        >
          <span class="mr-1">Edit</span><a-icon type="more" />
        </div>
        <a-menu slot="overlay" style="background-color: #ddd">
          <a-menu-item key="0" @click="goTo(`/post-ad/${ad.id}`)">
            <div class="pointer" type="link">Modify Ad</div>
          </a-menu-item>
          <a-menu-divider />
          <a-menu-item key="1" @click="$emit('showDeleteModal', ad)">
            <div class="pointer" type="link">Delete Ad</div>
          </a-menu-item>
          <a-menu-divider />
          <a-menu-item
            v-if="ad.isPublished"
            key="2"
            @click="$emit('showDeactivateModal', ad)"
          >
            <div class="pointer" type="link">Desactive Ad</div>
          </a-menu-item>
          <a-menu-item
            v-else
            key="4"
            :disabled="isBefore(new Date(ad.expires_at), new Date()) || !Boolean(ad.isPayed)"
            @click="$emit('showActivateModal', ad)"
          >
            <div class="pointer" type="link">Activate Ad</div>
          </a-menu-item>
          <a-menu-divider />
          <a-menu-item
            key="3"
            :disabled="!ad.isPublished"
            @click="goTo(`/model/${ad.id}`)"
          >
            <div class="pointer" type="link">View Ad</div>
          </a-menu-item>
        </a-menu>
      </a-dropdown>
    </div>

    <div class="ad-info-footer mt-3"  v-if="Boolean(ad.isPublished)">
      <a-row :gutter="[10, 10]">
        <a-col
          :sm="24"
          :md="12"
          v-if="!isBefore(new Date(ad.expires_at), new Date())"
        >
          <div class="ad-info-footer-expiration fs-13 fw-semi-bold w-100">
            Expires in
            <span class="pink-color-1 fw-bold">
              {{ formatDistanceToNow(new Date(ad.expires_at)) }}
            </span>
          </div>
        </a-col>
        <a-col :sm="24" :md="12">
          <a-button
            type="green"
            :loading="isBoosting && selectedAdId === ad.id"
            block
            size="small"
            :disabled="
              isBefore(new Date(ad.expires_at), new Date()) ||
                !isBoostFinished(ad.lastBoosted) ||
                !ad.isPublished ||
                ad.plan === 'basic'
            "
            @click="$emit('boostAd', ad.id)"
          >
            <template v-if="isBoostFinished(ad.lastBoosted)">
              {{ ad.plan === "basic" ? "No boost available" : "Boost" }}
              <a-icon
                v-if="ad.plan !== 'basic'"
                class="ih-14"
                type="white-trending"
              />
            </template>
            <div
              v-else
              class="d-flex justify-content-center align-items-center"
            >
              <span class="lh-0 fs-16 fw-500">Next boost:</span>
              <a-statistic-countdown
                class="ml-2 lh-0"
                :value="ad.deadline"
                :value-style="{ color: '#fff', fontSize: '16px' }"
              />
            </div>
          </a-button>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import {
  formatDistanceToNowStrict,
  formatRelative,
  isBefore,
  formatDistanceToNow,
  getTime,
} from 'date-fns';
import useGlobal from '@/composables/useGlobal';

export default {
  props: {
    ad: { type: Object },
    isBoosting: { type: Boolean },
  },
  setup() {
    const { goTo } = useGlobal();

    const isBoostFinished = (lastBoosted) => {
      const value = new Date(lastBoosted).getTime() + 1000 * 60 * 30 - new Date().getTime();
      return value < 0;
    };

    return {
      formatDistanceToNowStrict,
      formatRelative,
      isBefore,
      formatDistanceToNow,
      getTime,
      goTo,
      isBoostFinished,
    };
  },
};
</script>
