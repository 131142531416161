<template>
  <div class="plan-card">
    <div
      v-if="discountValue"
      style="transform: scale(0.3); left: -35px; top: -35px"
      class="
        special-price-polygon
        d-flex
        align-items-center
        justify-content-center
      "
    >
      <div class="position-absolute white-color fs-25 fw-bold">
        <en-skeleton :height="25" :width="45" v-if="isLoading" />
        <template v-else>
          {{ calculateDiscountPercentage(value, discountValue)}}
        </template>
      </div>
      <img :src="polygon" />
    </div>
    <div class="plan-card-content" :style="{ backgroundColor, color }">
      <a-icon v-if="icon" slot="prefix" class="mb-3" :type="icon" />
      <div class="fs-13 fw-semi-bold">{{ name }}</div>
      <en-skeleton :height="18" :width="39" class="mt-3" v-if="discountValue && isLoading" />
      <div class="fs-12 fw-semi-bold mb-2 mt-2 discount" v-if="discountValue && !isLoading">
        <template >
          {{ value }}
        </template>
      </div>
      <div class="fs-32 fw-semi-bold mb-2">
        <en-skeleton :height="35" :width="110" class="mt-3" v-if="isLoading" />
        <template v-else>
          {{ discountValue ? discountValue : value }}
        </template>

      </div>
      <ul class="plan-card-content-items">
        <li
          v-for="(item, index) in items"
          :key="index"
          :class="item.unavailable && 'line-through'"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 8.194 8.194"
          >
            <path
              id="ic_check_box_24px"
              d="M10.284,3H3.91A.91.91,0,0,0,3,3.91v6.373a.91.91,0,0,0,
              .91.91h6.373a.91.91,0,0,0,.91-.91V3.91A.91.91,0,0,0,10.284,
              3Zm-4.1,6.373L3.91,7.1l.642-.642,1.634,1.63L9.642,4.63l.642.646Z"
              transform="translate(-3 -3)"
              :fill="color"
            />
          </svg>
          <span class="ml-2">{{ item.label }}</span>
        </li>
      </ul>
    </div>
    <div class="plan-card-footer">
      <div class="fs-9 white-color">I want the plan {{ name }}</div>
      <en-toggle-button
        :selected="selected"
        @click:selected="$emit('click:selected', id)"
      />
    </div>
  </div>
</template>

<script>
import polygon from '@/assets/images/svg/polygon.svg';
import EnToggleButton from '../atoms/EnToggleButton.vue';

export default {
  components: { EnToggleButton },
  props: {
    backgroundColor: { type: String },
    color: { type: String },
    icon: { type: String },
    id: { type: String },
    name: { type: String },
    value: { type: String },
    items: { type: Array },
    selected: { type: Boolean },
    isLoading: { type: Boolean },
    discountValue: { type: String },
  },
  setup() {
    const calculateDiscountPercentage = (initialValueStr, finalValueStr) => {
      finalValueStr = finalValueStr.replace(',', '.');
      const initialValue = parseFloat(initialValueStr.replace(/[^\d.-]/g, ''));
      const finalValue = parseFloat(finalValueStr.replace(/[^\d.-]/g, ''));

      const discount = initialValue - finalValue;
      const discountPercentage = Math.floor((discount / initialValue) * 100);

      return `${discountPercentage}%`;
    };

    return {
      polygon,
      calculateDiscountPercentage,
    };
  },
};
</script>

<style lang="scss" scoped>
.plan-card {
  &-content {
    padding: 15px 12px;
    border-radius: 5px 5px 0px 0px;
    display: flex;
    align-items: center;
    flex-direction: column;

    .discount {
      position: relative;
      &:after {
        content: "";
        width: 60px;
        position: absolute;
        left: -10px;
        height: 1px;
        top: 8px;
        transform: rotate(352deg);
        display: block;
        background-color: red;
      }
    }

    i {
      height: 21px;
    }

    &-items {
      padding: 0 24px;
      list-style-type: none;
      margin: 0;

      li {
        margin-bottom: 4px;
      }
    }
  }

  &-footer {
    background-color: #000;
    border-radius: 0px 0px 5px 5px;
    padding: 15px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
