<template>
  <div>
    <a-alert
      class="mt-10 mb-8"
      message="PLEASE NOTE: verification required,
        please upload the documents as specified below."
      type="warning"
      show-icon
    />

    <div class="d-flex  mb-4">
      <div class="mr-4 w-50">
        <en-label icon="pink-people" iconWidth="colors" class="mb-4">
          <span class="fs-13 fw-medium ml-3">Name</span>
        </en-label>
        <a-input v-model="name" class="mb-5 w-100" placeholder="Enter your name" />
      </div>

      <div class="w-50">
        <en-label icon="pink-calendar" iconWidth="colors" class="mb-4">
          <span class="fs-13 fw-medium ml-3">Birthday</span>
        </en-label>
        <a-date-picker @change="onBirthDayChange"
                       placeholder="Enter your birthday" :showToday="false"
                       :format="'DD/MM/YYYY'" :disabledDate="disabledDate"/>

      </div>
    </div>

    <en-label icon="pink-photo" iconWidth="colors" class="mb-4">
      <span class="fs-13 fw-medium ml-3">
        <strong>Upload 1:</strong> Passport, Europe Drivers License or Europe ID card.
      </span>
    </en-label>

    <div class="d-flex align-items-center mb-10">
      <a-upload
        name="attachment"
        list-type="picture-card"
        class="avatar-uploader"
        :class="passportImageUrl ? 'has-image' : ''"
        :show-upload-list="false"
        :action="actionUrl"
        :before-upload="beforeUpload"
        :data="() => ({  passport: true})"
        :headers="{ authorization: user.acessToken }"
        @change="handlePassportImageChange"
      >
        <a-avatar
          :src="passportImageUrl"
          v-if="passportImageUrl"
          shape="square"
          :size="200"
        />
        <div v-else>
          <a-icon :type="isPassportLoading ? 'loading' : 'white-camera'"/>
        </div>
      </a-upload>
      <img :src="passPortDoc" class="ws-200" />
    </div>

    <en-label icon="pink-photo" iconWidth="colors" class="my-4">
      <span class="fs-13 fw-medium ml-3">
        <strong>Upload 2:</strong> Photo of you, holding the same ID document next to your face.
      </span>
    </en-label>

    <div class="d-flex align-items-center">
      <a-upload
        name="attachment"
        list-type="picture-card"
        class="avatar-uploader"
        :class="selfieImageUrl ? 'has-image' : ''"
        :show-upload-list="false"
        :data="() => ({  passport: true})"
        :action="actionUrl"
        :before-upload="beforeUpload"
        :headers="{ authorization: user.acessToken }"
        @change="handleSelfieImageChange"
      >
        <a-avatar
          :src="selfieImageUrl"
          v-if="selfieImageUrl"
          shape="square"
          :size="200"
        />
        <div v-else>
          <a-icon :type="isSelfieLoading ? 'loading' : 'white-camera'" />
        </div>
      </a-upload>
      <img :src="passPortWoman" class="ws-200"  />
    </div>

    <a-button
      type="secondary"
      block
      class="mt-10"
      :disabled="isSaveDisabled"
      :loading="isProcessing"
      @click="submitVerification"
    >
      Submit
    </a-button>

  </div>
</template>

<script>
import { ref, computed } from 'vue';
import EnLabel from '@/components/atoms/EnLabel.vue';
import { message } from 'ant-design-vue';
import useUser from '@/composables/useUser';
import AuthService from '@/services/AuthService';
import passPortDoc from '@/assets/images/png/passport-doc.png';
import passPortWoman from '@/assets/images/png/passport-woman.png';

export default {
  components: { EnLabel },
  setup(_, { emit }) {
    const { user } = useUser();

    const name = ref('');
    const birthday = ref('');
    const onBirthDayChange = ({ _d: date }) => {
      birthday.value = date.toISOString().slice(0, 10);
    };

    const actionUrl = `${process.env.VUE_APP_BASE_URL}/model-ad/upload`;

    const passportImageUrl = ref('');

    const beforeUpload = (file) => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        message.error('You can only upload JPG file!');
      }
      const isLt15M = file.size / 1024 / 1024 < 15;
      if (!isLt15M) {
        message.error('Image must smaller than 15MB!');
      }
      return isJpgOrPng && isLt15M;
    };

    const isPassportLoading = ref(false);

    const handlePassportImageChange = (info) => {
      if (info.file.status === 'uploading') {
        isPassportLoading.value = true;
        return;
      }

      if (info.file.status === 'done') {
        passportImageUrl.value = info.file.response;
        // profile.photo = info.file.response;
        isPassportLoading.value = false;
      }
    };

    const selfieImageUrl = ref('');

    const isSelfieLoading = ref(false);

    const handleSelfieImageChange = (info) => {
      if (info.file.status === 'uploading') {
        isSelfieLoading.value = true;
        return;
      }

      if (info.file.status === 'done') {
        selfieImageUrl.value = info.file.response;
        // profile.photo = info.file.response;
        isSelfieLoading.value = false;
      }
    };

    const isProcessing = ref(false);

    const isSaveDisabled = computed(() => !name.value
     || !birthday.value || !selfieImageUrl.value || !passportImageUrl.value);

    const submitVerification = async () => {
      try {
        isProcessing.value = true;
        const payload = {
          fullName: name.value,
          dateOfBirth: birthday.value,
          passportPhoto: passportImageUrl.value,
          passportSelfie: selfieImageUrl.value,
        };
        await AuthService.submitVerification(payload);
        emit('verificationSent');
        message.success('Your data has been sent for analysis.');
      } catch (error) {
        message.error(error.response.data.name ? error.response.data.name : error.response.data);
      } finally {
        isProcessing.value = false;
      }
    };

    const today = new Date();
    const minDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());

    const disabledDate = (current) => current > minDate;

    return {
      name,
      passportImageUrl,
      actionUrl,
      beforeUpload,
      user,
      handlePassportImageChange,
      isPassportLoading,
      selfieImageUrl,
      handleSelfieImageChange,
      isSelfieLoading,
      birthday,
      onBirthDayChange,
      isProcessing,
      isSaveDisabled,
      submitVerification,
      disabledDate,
      passPortDoc,
      passPortWoman,
    };
  },
};
</script>
