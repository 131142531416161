import ApiClientService from './ApiClientService';

const AuthService = {
  signUp(payload) {
    return ApiClientService.post('/signup', payload);
  },
  removeAccount(payload) {
    return ApiClientService.post('/remove', payload);
  },
  recoverByEmail(payload) {
    return ApiClientService.post('/recovery', payload);
  },
  signupConfirm(confirmationCode) {
    return ApiClientService.get(`/confirmSignup/${confirmationCode}`);
  },
  recoverByCode(payload) {
    return ApiClientService.post('/recovery/code', payload);
  },
  login(payload) {
    return ApiClientService.post('/login', payload);
  },
  validate() {
    return ApiClientService.get('/validate');
  },
  resendConfirmationLink() {
    return ApiClientService.get('/resendEmail');
  },
  deleteAccount(payload) {
    return ApiClientService.delete('/remove', payload);
  },
  updateAccount(payload) {
    return ApiClientService.put('/updateAccount', payload);
  },
  sendEmail(payload) {
    return ApiClientService.post('/help/sendMail', payload);
  },
  checkStatus() {
    return ApiClientService.get('/checkStatus');
  },
  submitVerification(payload) {
    return ApiClientService.post('/submitVerification', payload);
  },
};

export default AuthService;
