<template>
  <div class="my-15">
    <container>
      <div class="d-flex flex-column align-center">
        <div class="flex-column w-75">
          <div class="d-flex justify-center flex-column align-items-center">
            <en-search
              :style="{ maxWidth: '700px', minWidth: '330px' }"
              @change="onSearchChange"
              class="mb-10 input-shadow z-0"
              placeholder="Search"
            />

            <div class="d-flex">
              <a-card
                v-for="tab in tabItems"
                :key="tab.key"
                class="main-button pointer input-shadow"
                @click="onTabSelect(tab)"
              >
                <div class="main-button-body">
                  <a-icon :type="tab.icon" />
                  <div class="fs-11 main-button-body-label">
                    {{ tab.label }}
                  </div>
                </div>
              </a-card>
            </div>

            <div
              class="mt-4 w-50 text-center"
              v-if="usingFilters || isUsingQueryUrl"
            >
              <template v-for="(label, index) of Object.keys(query)">
                <a-tag
                  color="#444"
                  :key="index"
                  class="fs-14 fw-light mt-2 text-capitalize"
                >
                  {{ translateLabel(label, query[label]) }}
                </a-tag>
              </template>
            </div>

            <a-button
              v-if="usingFilters || isUsingQueryUrl"
              type="link"
              @click="clearFilters"
              block
            >
              Clear filters
            </a-button>
          </div>
        </div>
      </div>
    </container>
    <container>
      <en-skeleton
        v-if="isLocationInfoLoading"
        type="rect"
        width="500px"
        class="mt-10"
        rounded
        :radius="5"
        height="21px"
        wave-color="rgba(255, 255, 255, 0.3)"
        animation="wave"
      />
      <re-breadcrumb
        v-else
        class="mt-10"
        :locationInfo="locationInfo"
        @click:breadcrumb="onBreadcrumbClick"
      />
    </container>
    <container v-if="selectedTab">
      <div class="d-flex align-items-center justify-content-between">
        <en-label :icon="selectedTab.icon" class="mt-7 mb-7">
          <div class="fs-30 ml-5 fw-semi-bold lh-0 mt-1 text-left">
            {{ selectedTab.label }}
          </div>
        </en-label>
        <a-icon type="close" :style="{ fontSize: '24px'}" @click="selectedTab = ''"/>
      </div>
    </container>
    <div :class="`${selectedTab.key}-divider`" />
    <container>
      <tab-filter
        class="mt-10"
        v-if="selectedTab.key === 'filter'"
        :ethnicities="ethnicities"
        :adTags="adTags"
        :filters="params"
        @click:filter="onFilterSelect"
      />
      <tab-location
        v-if="selectedTab.key === 'location'"
        :isAdsLoading="loading"
        :params="params"
        :locationType="currentLocationType"
        :locationInfo="locationInfo"
        class="mt-10"
        @click:onLocationSelect="onLocationSelect"
      />
      <template>
        <a-row class="mt-10" :gutter="[10, 10]">
          <a-col
            v-for="(ad) in ads"
            :key="ad.id"
            :xs="12"
            :sm="12"
            :md="12"
            :lg="4"
          >
            <en-gallery-item :data="ad" />
          </a-col>
          <template v-if="loading">
            <a-col
              v-for="skeleton in !ads.length ? params.limit : 18"
              :key="skeleton"
              :xs="12"
              :sm="12"
              :md="12"
              :lg="4"
            >
              <en-skeleton
                type="rect"
                width="100%"
                height="300px"
                wave-color="rgba(255, 255, 255, 0.3)"
                animation="wave"
              />
            </a-col>
          </template>
        </a-row>
        <div
          class="d-flex justify-content-center mt-15"
          v-if="!ads.length && !loading"
        >
          No models found
        </div>
      </template>

      <a-button
        type="link"
        v-if="hasNextPage && !loading"
        @click="loadMoreData"
        block
      >
        Load more
      </a-button>

      <en-location-info :location="currentLocationId"/>
    </container>
  </div>
</template>

<script>
import {
  ref, computed, onBeforeUnmount, nextTick,
} from 'vue';
import { message } from 'ant-design-vue';
import EnSearch from '@/components/atoms/EnSearch.vue';
import EnLabel from '@/components/atoms/EnLabel.vue';
import EnLocationInfo from '@/components/atoms/EnLocationInfo.vue';
import EnGalleryItem from '@/components/molecules/EnGalleryItem.vue';
import ReBreadcrumb from '@/components/molecules/ReBreadcrumb.vue';
import AdService from '@/services/AdService';
import router from '@/router/router';
import LocationService from '@/services/LocationService';
import store from '@/store/store';
import TabFilter from './TabFilter.vue';
import TabLocation from './TabLocation.vue';

export default {
  components: {
    EnSearch,
    EnLabel,
    TabFilter,
    TabLocation,
    EnGalleryItem,
    ReBreadcrumb,
    EnLocationInfo,
  },
  metaInfo() {
    return { ...this.metaInfo };
  },
  name: 'Home',
  setup() {
    const metaInfo = ref({
      title: 'Reality Escorts',
      meta: [{
        name: 'description', content: 'Find the best independent and agency escorts at Reality Escorts.',
      }],
    });
    const tabItems = [
      { icon: 'yellow-crown', key: 'gold', label: 'Gold' },
      { icon: 'pink-filter', key: 'filter', label: 'Filter' },
      { icon: 'pink-location', key: 'location', label: 'Location' },
      { icon: 'green-fire', key: 'new', label: 'New' },
    ];

    const ads = computed(() => store.state.adStore.ads);
    const hasNextPage = computed(() => store.state.adStore.hasNextPage);
    const params = computed(() => store.state.adStore.params);
    const usingFilters = computed(() => store.state.adStore.usingFilters);
    const locationInfo = computed(() => store.state.adStore.locationInfo);
    const scrollPosition = computed(() => store.state.adStore.scrollPosition);

    const selectedTab = ref('');

    const defaultParams = { ...params.value, ...router.history.current.query };

    store.dispatch('adStore/setParams', { ...defaultParams });

    const loading = ref(false);

    const isUsingQueryUrl = ref(false);

    const getPath = (country, region, subRegion, city, district) => {
      const countryUrl = country ? `/${country}` : '';
      const regionUrl = region ? `/${region}` : '';
      const subRegionUrl = subRegion ? `/${subRegion}` : '';
      const cityUrl = city ? `/${city}` : '';
      const districtUrl = district ? `/${district}` : '';
      return `/escorts${countryUrl}${regionUrl}${subRegionUrl}${cityUrl}${districtUrl}`;
    };

    const decideRegion = (country, region, subRegion, city, district) => {
      if (district) return { district };
      if (city) return { city };
      if (subRegion) return { subregion: subRegion };
      if (region) return { region };
      return { country };
    };

    const getLocationInfo = () => {
      const {
        country, region, subRegion, city, district,
      } = router.history.current.params;
      if (district) return { locationType: 'district', id: district };
      if (city) return { locationType: 'city', id: city };
      if (subRegion) return { locationType: 'subregion', id: subRegion };
      if (region) return { locationType: 'region', id: region };
      return { locationType: 'country', id: country };
    };

    const currentLocationType = ref('');

    const isSubRegion = computed(() => currentLocationType.value === 'subregion');
    const getMetaInfo = () => {
      const location = isSubRegion.value ? locationInfo.value.sub_region.name
        : locationInfo.value[currentLocationType.value].name;
      return {
        title: `${location} Escorts | Local Escorts Near You | Reality Escorts`,
        meta: [{
          name: 'description', content: `${location}, PARTY GIRLS, GFE, INCALLS and OUTCALLS. Find the best independent and agency escorts at Reality Escorts.`,
        }],
      };
    };

    const isLocationInfoLoading = ref(false);
    const fetchLocationInfo = async () => {
      isLocationInfoLoading.value = true;
      const { locationType, id } = getLocationInfo();
      try {
        const { data } = await LocationService.fetchLocation(locationType, id, {
          isNew: params.value.isnew,
          gold: params.value.plan === 'gold' ? true : undefined,
        });
        store.dispatch('adStore/setLocationInfo', data);
        currentLocationType.value = locationType;
        metaInfo.value = getMetaInfo();
      } catch (error) {
        console.log(error);
      } finally {
        isLocationInfoLoading.value = false;
        loading.value = false;
      }
    };

    const query = ref({});
    const fetchAds = async (parameters) => {
      try {
        loading.value = true;
        isLocationInfoLoading.value = true;
        query.value = (({
          page, limit, district, ...urlParams
        }) => ({
          district: parameters.district ? district : 'all',
          ...Object.entries(urlParams).reduce(
            (acc, [k, v]) => (v
              ? {
                ...acc,
                [k]: v,
              }
              : acc),
            {},
          ),
        }))(parameters);
        store.dispatch('adStore/setParams', parameters);
        const {
          country, region, subRegion, city, district,
        } = router.history.current.params;
        const path = getPath(country, region, subRegion, city, district);
        router.replace({ path, query: { name: query.value.name } }).catch(() => {});
        if (Object.keys(params.value).length <= 2) { isUsingQueryUrl.value = false; } else if (
          Object.keys(params.value).length === 3
          && params.value.district === 'all'
        ) { isUsingQueryUrl.value = false; } else isUsingQueryUrl.value = true;

        const { data } = await AdService.fetchAds({
          ...parameters,
          ...decideRegion(country, region, subRegion, city, district),
        });
        fetchLocationInfo();

        store.dispatch('adStore/setAds', [...ads.value, ...data.result]);
        store.dispatch('adStore/setHasNextPage', !!data.next.page);
      } catch (error) {
        message.error(error.message, 4);
        loading.value = false;
      }
    };

    if (!ads.value.length) fetchAds({ ...params.value });

    const updateScrollPosition = () => {
      if (window.scrollY !== 0) {
        store.dispatch('adStore/setScrollPosition', window.scrollY);
      }
    };

    window.addEventListener('scroll', updateScrollPosition);

    onBeforeUnmount(() => {
      window.removeEventListener('scroll', updateScrollPosition);
    });

    const restoreScrollPosition = () => {
      window.scrollTo(0, scrollPosition.value);
    };

    const loadMoreData = async () => {
      store.dispatch('adStore/setParams', { ...params.value, page: params.value.page + 1 });
      await fetchAds(params.value);
      await nextTick();
      restoreScrollPosition();
    };

    const onTabSelect = (tab) => {
      selectedTab.value = tab;
      const currentParams = params.value;
      if (tab.key === 'gold') {
        delete currentParams.isnew;
        currentParams.plan = 'gold';
        currentParams.page = 1;
        store.dispatch('adStore/setUsingFilters', true);
        store.dispatch('adStore/setParams', currentParams);
        store.dispatch('adStore/setAds', []);
        fetchAds(params.value);
      } else if (tab.key === 'new') {
        delete currentParams.plan;
        currentParams.page = 1;
        currentParams.isnew = true;
        store.dispatch('adStore/setUsingFilters', true);
        store.dispatch('adStore/setParams', currentParams);
        store.dispatch('adStore/setAds', []);
        fetchAds(params.value);
      }
    };

    let debounce;
    const onSearchChange = (e) => {
      clearTimeout(debounce);
      store.dispatch('adStore/setAds', []);
      const currentParams = params.value;
      currentParams.page = 1;
      currentParams.name = e.target.value;
      store.dispatch('adStore/setParams', currentParams);
      debounce = setTimeout(() => {
        fetchAds(params.value);
      }, 500);
    };

    const clearFilters = () => {
      store.dispatch('adStore/setParams', { page: 1, limit: 18 });
      store.dispatch('adStore/setAds', []);
      store.dispatch('adStore/setUsingFilters', false);
      selectedTab.value = '';
      fetchAds(params.value);
    };

    const onFilterSelect = (parameters) => {
      selectedTab.value = '';
      store.dispatch('adStore/setUsingFilters', true);
      store.dispatch('adStore/setParams', { ...parameters, page: 1 });
      store.dispatch('adStore/setAds', []);
      fetchAds(params.value);
    };

    function replaceLastPartOfUrl(url, newLastPart) {
      const urlArray = url.split('/');
      urlArray[urlArray.length - 1] = newLastPart;
      return urlArray.join('/');
    }

    const getUrlLastPart = (url) => {
      const urlArray = url.split('/');
      return urlArray[urlArray.length - 1];
    };

    const currentLocationId = ref(getUrlLastPart(router.history.current.path));

    const onLocationSelect = ({ url }) => {
      currentLocationId.value = url;
      let finalUrl = '';
      if (currentLocationType.value === 'district') {
        finalUrl = replaceLastPartOfUrl(router.history.current.path, url);
        selectedTab.value = '';
      } else {
        finalUrl = `${router.history.current.path}/${url}`;
      }
      router.push(finalUrl);
      const currentParams = params.value;
      currentParams.page = 1;
      delete currentParams.city;
      delete currentParams.district;
      delete currentParams.region;
      delete currentParams.subregion;
      delete currentParams.country;
      store.dispatch('adStore/setParams', currentParams);
      store.dispatch('adStore/setAds', []);
      fetchAds(params.value);
    };

    const ethnicities = ref([]);
    const fetchEthnicity = async () => {
      try {
        const { data } = await LocationService.fetchEthnicity();
        ethnicities.value = data.map(({ id, name }) => ({
          value: id,
          text: name,
        }));
      } catch (error) {
        console.log(error);
      }
    };
    fetchEthnicity();

    const adTags = ref([]);
    const fetchTags = async () => {
      try {
        const { data } = await AdService.fetchTags();
        adTags.value = data;
      } catch (error) {
        console.log(error);
      }
    };
    fetchTags();

    const translateLabel = (label, value) => {
      if (label === 'district') { return value === 'all' ? 'All districts' : value; }
      if (value === 'gold') return value;
      if (label === 'isnew') return 'New';
      if (label === 'age') return `${value} years old`;
      if (label === 'eyes') return `${value} eyes`;
      if (label === 'hair') return `${value} hair`;
      if (label === 'height') return `${value} cm height`;
      if (label === 'ethnicityid') { return `${ethnicities.value.find((e) => e.value === value)?.text}`; }
      if (label === 'tag') { return `${adTags.value.find((e) => e.id === value).name}`; }
      return label;
    };

    const onBreadcrumbClick = (url) => {
      currentLocationId.value = getUrlLastPart(url);
      store.dispatch('adStore/setAds', []);
      router.push(url);
      fetchAds({ ...params.value });
    };

    return {
      tabItems,
      onTabSelect,
      selectedTab,
      ads,
      loading,
      onSearchChange,
      params,
      usingFilters,
      clearFilters,
      onFilterSelect,
      hasNextPage,
      loadMoreData,
      onLocationSelect,
      isUsingQueryUrl,
      query,
      translateLabel,
      ethnicities,
      adTags,
      locationInfo,
      isLocationInfoLoading,
      onBreadcrumbClick,
      currentLocationType,
      currentLocationId,
      metaInfo,
    };
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_variables.scss";

.main-button {
  .ant-card-body {
    padding: 0;
  }

  text-align: center;

  @media screen and (max-width: 680px) {
    margin: 0 6px !important;
    width: 75px;
  }
  @media screen and (min-width: 681px) {
    width: 100px;
    margin: 0 10px !important;
  }

  @media screen and (min-width: 1000px) {
    width: 150px;
    margin: 0 14px !important;
  }

  &-body {
    @media screen and (max-width: 770px) {
      padding: 8px;
      i {
        width: 20px;
        height: 20px;
      }
    }
    @media screen and (min-width: 771px) {
      padding: 16px;
    }
    @media screen and (min-width: 1000px) {
      padding: 24px;
    }
  }
}
</style>
