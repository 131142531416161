import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home/Home.vue';
import Login from '@/views/Login.vue';
import Help from '@/views/Help.vue';
import FAQ from '@/views/FAQ.vue';
import Register from '@/views/Register.vue';
import Terms from '@/views/Terms.vue';
import RealityEscorts from '@/views/RealityEscorts.vue';
import PostAd from '@/views/PostAd.vue';
import PostAdDone from '@/views/PostAdDone.vue';
import Recover from '@/views/Recover.vue';
import Privacy from '@/views/Privacy.vue';
import PageNotFound from '@/views/PageNotFound.vue';
import AdDetail from '@/views/AdDetail/AdDetail.vue';
import EnLayout from '@/components/organisms/EnLayout.vue';
// import Locations from '@/views/Locations.vue';
// import Language from '@/views/Language.vue';
import MyAccount from '@/views/MyAccount/MyAccount.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '',
    component: EnLayout,
    children: [
      {
        path: '/',
        component: RealityEscorts,
      },
      {
        path: '/escorts/uk/london-region',
        redirect: '/escorts/uk/london-region/greater-london/london',
      },
      {
        path: '/escorts/uk/london-region/greater-london',
        redirect: '/escorts/uk/london-region/greater-london/london',
      },
      {
        path: '/escorts/:country?/:region?/:subRegion?/:city?/:district*',
        component: Home,
        props: true,
      },
      {
        path: '/model/:id',
        props: true,
        component: AdDetail,
      },
      {
        path: '/home',
      },
      {
        path: '/login',
        component: Login,
      },
      {
        path: '/register',
        component: Register,
      },
      {
        path: '/recover',
        component: Recover,
      },
      {
        path: '/faq',
        component: FAQ,
      },
      {
        path: '/contact-us/:id*',
        component: Help,
        props: true,
      },
      {
        path: '/post-ad/result/:id*',
        component: PostAdDone,
        props: true,
      },
      {
        path: '/post-ad',
        component: PostAd,
      },
      {
        path: '/post-ad/:id',
        component: PostAd,
        props: true,
      },
      {
        path: '/privacy',
        component: Privacy,
      },
      {
        path: '/terms',
        component: Terms,
      },
      // {
      //   path: '/locations',
      //   component: Locations,
      // },
      // {
      //   path: '/language',
      //   component: Language,
      // },
      {
        path: '/my-account',
        component: MyAccount,
      },
      {
        path: '*',
        component: PageNotFound,
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.afterEach(() => {
  window.scrollTo({ top: 0, behavior: 'auto' });
});

export default router;
