<template>
  <div class="display-box">
    <div class="display-box-label" v-if="label">
      {{ label }}
    </div>
    <div class="display-box-content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: { type: String },
  },
};
</script>

<style lang="scss">
.light-mode {
  .display-box {
    &-label {
       background-color: #F2F2F2;
    }
  }
}
.display-box {
  position: relative;
  &-label {
    position: absolute;
    top: -2px;
    left: 12px;
    background-color: #222;
    padding: 0px 8px;
    line-height: 1;
    font-size: 8px;
    font-weight: 500;
  }

  &-content {
    border: 1px solid;
    border-radius: 5px;
    padding: 18px;
  }
}
</style>
