import Vue from 'vue';
import Antd from 'ant-design-vue';
import money from 'v-money';
import VueSkeletonLoader from 'skeleton-loader-vue';
import Container from '@/components/atoms/Container.vue';
import App from '@/App.vue';
import router from '@/router/router';
import store from '@/store/store';
import VueTheMask from 'vue-the-mask';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import 'ant-design-vue/dist/antd.less';
import 'antd-css-utilities/utility.min.css';
import '@/assets/scss/global.scss';
import VueMeta from 'vue-meta';

Vue.config.productionTip = false;
Vue.use(Antd);
Vue.use(VueMeta);
Vue.component('en-skeleton', VueSkeletonLoader);
Vue.component('en-phone-number', VuePhoneNumberInput);
Vue.component('container', Container);
Vue.use(money);
Vue.use(VueTheMask);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
