const defaultState = {
  ads: [],
  hasNextPage: false,
  params: {
    page: 1,
    limit: 18,
  },
  usingFilters: false,
  locationInfo: {},
  scrollPosition: 0,
};
export default {
  namespaced: true,
  state: {
    ...defaultState,
  },
  mutations: {
    SET_ADS(state, payload) {
      state.ads = payload;
    },
    SET_HAS_NEXT_PAGE(state, payload) {
      state.hasNextPage = payload;
    },
    SET_PARAMS(state, payload) {
      state.params = payload;
    },
    SET_DEFAULT_PARAMS(state, payload) {
      state.params = payload;
    },
    SET_USING_FILTERS(state, payload) {
      state.usingFilters = payload;
    },
    SET_LOCATION_INFO(state, payload) {
      state.locationInfo = payload;
    },
    SET_SCROLL_POSITION(state, payload) {
      state.scrollPosition = payload;
    },
  },
  actions: {
    setAds({ commit }, payload) {
      commit('SET_ADS', payload);
    },
    setHasNextPage({ commit }, payload) {
      commit('SET_HAS_NEXT_PAGE', payload);
    },
    setParams({ commit }, payload) {
      commit('SET_PARAMS', payload);
    },
    setDefaultParams({ commit }) {
      commit('SET_DEFAULT_PARAMS', defaultState.params);
    },
    setUsingFilters({ commit }, payload) {
      commit('SET_USING_FILTERS', payload);
    },
    setLocationInfo({ commit }, payload) {
      commit('SET_LOCATION_INFO', payload);
    },
    setScrollPosition({ commit }, payload) {
      commit('SET_SCROLL_POSITION', payload);
    },
  },
};
