<template>
  <container class="d-flex justify-content-center my-15">
    <div class="w-mobile w-100">
      <h1 class="fs-20 mb-11">Contact us</h1>
      <a-form-model
        ref="contactForm"
        :model="form"
        :rules="rules"
        @submit="handleSubmit"
      >
        <en-label iconWidth="15px" class="mb-4">
          <span class="fs-13 fw-medium ml-3">I have a question about… </span>
        </en-label>
        <a-form-model-item prop="about">
          <a-select class="w-100" v-model="form.about">
            <a-select-option value="payment">Payment</a-select-option>
            <a-select-option value="technical_issue"
            >Technical issue</a-select-option
            >
            <a-select-option value="other">Other</a-select-option>
          </a-select>
        </a-form-model-item>

        <en-label iconWidth="15px" class="my-4">
          <span class="fs-13 fw-medium ml-3">Full name</span>
        </en-label>
        <a-form-model-item prop="name">
          <a-input placeholder="Type your full name" v-model="form.name" />
        </a-form-model-item>

        <en-label iconWidth="15px" class="my-4">
          <span class="fs-13 fw-medium ml-3">E-mail</span>
        </en-label>
        <a-form-model-item prop="email">
          <a-input
            placeholder="Type your e-mail"
            v-model="form.email"
            :disabled="isAuthenticated"
          />
        </a-form-model-item>

        <en-label iconWidth="15px" class="my-4">
          <span class="fs-13 fw-medium ml-3">Subject</span>
        </en-label>
        <a-form-model-item prop="subject">
          <a-input
            placeholder="Type the subject"
            v-model="form.subject"
            :disabled="!!id"
          />
        </a-form-model-item>

        <en-label iconWidth="15px" class="my-4">
          <span class="fs-13 fw-medium ml-3">How can we help you?</span>
        </en-label>
        <a-form-model-item prop="message">
          <a-textarea
            placeholder="Type the message"
            v-model="form.message"
            :auto-size="{ minRows: 4, maxRows: 16 }"
            style="padding: 10px 40px 10px 20px"
          />
        </a-form-model-item>

        <template v-if="isAuthenticated">
          <en-label iconWidth="15px" class="my-4">
            <span class="fs-13 fw-medium ml-3">Upload</span>
          </en-label>

          <a-upload
            name="file"
            :multiple="true"
            accept=".jpg, .jpeg, .png, .gif"
            :action="actionUrl"
            :data="(file) => ({ attachment: file })"
            :headers="{ authorization: user.acessToken }"
            @change="onPhotosUploadChange"
          >
            <a-button> <a-icon type="upload" /> Click to Upload </a-button>
          </a-upload>
        </template>

        <a-form-model-item>
          <a-button type="primary" class="mt-10" html-type="submit">
            Submit
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
  </container>
</template>

<script>
import { ref } from 'vue';
import EnLabel from '@/components/atoms/EnLabel.vue';
import useUser from '@/composables/useUser';
import AuthService from '@/services/AuthService';
import { message } from 'ant-design-vue';

export default {
  props: {
    id: { type: String },
  },
  metaInfo() {
    return {
      title: 'Reality Escorts',
      meta: [{
        name: 'description', content: 'Find the best independent and agency escorts at Reality Escorts.',
      }],
    };
  },
  components: { EnLabel },
  setup(props) {
    const { user, isAuthenticated } = useUser();
    const contactForm = ref();

    const defaultForm = {
      about: '',
      name: '',
      email: user.value.email || '',
      subject:
        props.id ? `Model Report: ${props.id}` : '',
      message: '',
      attachments: [],
    };
    const form = ref({ ...defaultForm });

    const loading = ref(false);
    const handleSubmit = (e) => {
      e.preventDefault();
      contactForm.value.validate(async (valid) => {
        if (valid) {
          try {
            loading.value = true;
            await AuthService.sendEmail(form.value);
            message.success('We received your message!', 4);
            form.value = { ...defaultForm };
          } catch (error) {
            message.error(error.response.data, 4);
          } finally {
            loading.value = false;
          }
        }
      });
    };

    const emailValidation = (rule, value, callback) => {
      const re = /\S+@\S+\.\S+/;
      if (!re.test(value)) callback(new Error('Please input a valid e-mail'));
      else callback();
    };

    const rules = ref({
      about: [
        {
          required: true,
          message: 'Please select the issue!',
          trigger: 'blur',
        },
      ],
      name: [
        { required: true, message: 'Please input your name!', trigger: 'blur' },
      ],
      email: [{ validator: emailValidation, trigger: 'change' }],
      subject: [
        {
          required: true,
          message: 'Please input the subject!',
          trigger: 'blur',
        },
      ],
      message: [
        {
          required: true,
          message: 'Please input the message!',
          trigger: 'blur',
        },
      ],
    });

    const actionUrl = `${process.env.VUE_APP_BASE_URL}/model-ad/upload`;

    const onPhotosUploadChange = ({ file }) => {
      if (file.response) {
        form.value.attachments = [...form.value.attachments, file.response];
      }
    };

    return {
      form,
      handleSubmit,
      rules,
      contactForm,
      actionUrl,
      user,
      onPhotosUploadChange,
      loading,
      isAuthenticated,
    };
  },
};
</script>
