import ApiClientService from './ApiClientService';

const LocationService = {
  fetchLocation(locationType, id, params) {
    return ApiClientService.get(`/${locationType}/${id}/location`, { params });
  },
  fetchCountry(params) {
    return ApiClientService.get('/country/get', { params });
  },
  fetchRegion(params) {
    return ApiClientService.get('/region/get', { params });
  },
  fetchSubRegion(params) {
    return ApiClientService.get('/subregion/get', { params });
  },
  fetchCity(params) {
    return ApiClientService.get('/city/get', { params });
  },
  fetchDistrict(params) {
    return ApiClientService.get('/district/get', { params });
  },
  createCity(payload) {
    return ApiClientService.post('/city/add', payload);
  },
  createDistrict(payload) {
    return ApiClientService.post('/district/add', payload);
  },
  fetchEthnicity(params) {
    return ApiClientService.get('/ethnicity/get', { params });
  },
  createEthnicity(payload) {
    return ApiClientService.post('/ethnicity/add', payload);
  },
};

export default LocationService;
