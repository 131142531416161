import ApiClientService from './ApiClientService';

const AdService = {
  fetchAds(params) {
    return ApiClientService.get('/model-ad/get', { params });
  },
  createAd(payload) {
    return ApiClientService.post('/model-ad/add', payload);
  },
  createAdNotAuthenticated(payload) {
    return ApiClientService.post('/model-ad/add-nologin', payload);
  },
  updateAd(id, payload) {
    return ApiClientService.put(`/model-ad/${id}/update`, payload);
  },
  fetchTags(params) {
    return ApiClientService.get('/tag/get', { params });
  },
  boostAd(id) {
    return ApiClientService.put(`/model-ad/${id}/boost`);
  },
  renewAd(id, payload) {
    return ApiClientService.put(`/model-ad/${id}/renew`, payload);
  },
  upgradeAd(id, payload) {
    return ApiClientService.put(`/model-ad/${id}/upgrade`, payload);
  },
  updatePayment(id, payload) {
    return ApiClientService.put(`/model-ad/${id}/update-payment`, payload);
  },
  uploadFile(formData) {
    return ApiClientService.post('/model-ad/upload', formData);
  },
  fetchServices(params) {
    return ApiClientService.get('/service/get', { params });
  },
  incrementViews(id) {
    return ApiClientService.put(`/model-ad/${id}/increment-views`);
  },
  deleteAd(id) {
    return ApiClientService.delete(`/model-ad/${id}/remove`);
  },
  deactivateAd(id) {
    return ApiClientService.put(`/model-ad/${id}/deactivate`);
  },
  activateAd(id) {
    return ApiClientService.put(`/model-ad/${id}/activate`);
  },
  initPayment(payload) {
    return ApiClientService.post('/payment/initiate', payload);
  },
  checkPayment(id) {
    return ApiClientService.post(`/payment/success_url/${id}`);
  },
  clearPayment(id) {
    return ApiClientService.put(`/model-ad/${id}/clear-payment`);
  },
  fetchPlansCost(id) {
    return ApiClientService.get(`/model-ad/${id}/getPlans`);
  },
};

export default AdService;
