<template>
  <container class="d-flex justify-content-center my-15">
    <div class="w-mobile w-100">
      <h1 class="fs-20 mb-11">FAQ</h1>
      <h2 class="fs-18 mb-5 fw-bold pink-color-1">Login</h2>
      <en-help-item
        v-for="({ title, response }, index) in loginHelpItems"
        :key="title"
        :title="title"
        :class="index === 0 ? '' : 'mt-8'"
        :response="response"
      />

      <h2 class="fs-18 mb-5 mt-11 fw-bold pink-color-1">Posting advertising</h2>
      <en-help-item
        v-for="({ title, response }, index) in postingHelpItems"
        :key="title"
        :title="title"
        :class="index === 0 ? '' : 'mt-8'"
        :response="response"
      />

      <h2 class="fs-18 mb-5 mt-11 fw-bold pink-color-1">Report an ad</h2>
      <en-help-item
        v-for="({ title, response }, index) in reportHelpItems"
        :key="title"
        :title="title"
        :class="index === 0 ? '' : 'mt-8'"
        :response="response"
      />

      <h2 class="fs-18 mb-5 mt-11 fw-bold pink-color-1">
        My registration details
      </h2>
      <en-help-item
        v-for="({ title, response }, index) in registrationHelpItems"
        :key="title"
        :title="title"
        :class="index === 0 ? '' : 'mt-8'"
        :response="response"
      />

      <h2 class="fs-18 mb-5 mt-11 fw-bold pink-color-1">Manage your ads</h2>
      <en-help-item
        v-for="({ title, response }, index) in manageHelpItems"
        :key="title"
        :title="title"
        :class="index === 0 ? '' : 'mt-8'"
        :response="response"
      />
    </div>
  </container>
</template>

<script>
import EnHelpItem from '../components/molecules/EnHelpItem.vue';

export default {
  components: { EnHelpItem },
  metaInfo() {
    return {
      title: 'Reality Escorts',
      meta: [{
        name: 'description', content: 'Find the best independent and agency escorts at Reality Escorts.',
      }],
    };
  },
  setup() {
    const loginHelpItems = [
      {
        title: 'How do I login to My Account?',
        response: 'Go to www.realityescorts.com/login',
      },
    ];

    const postingHelpItems = [
      {
        title: 'Can I advertise in multiple locations?',
        response: 'Yes. Always using original photos.',
      },
      {
        title: 'How many ads can I post with same login details?',
        response: '1 to 8 ads.',
      },
      {
        title: 'How to post an ad?',
        response: `
          1) Click in the button Post your Ad -
          2) Choosing your plan and fill all the information for you ad. -
          3) Pay for you advertise and accept the verification email was send to your email box.
        `,
      },
    ];

    const reportHelpItems = [
      {
        title: 'How do I report an ad?',
        response: 'click the report button at the end of the profile.',
      },
      {
        title: 'Someone is using my personal information or Photos.',
        response:
          'If someone is using your personal information or photos please Contact Us on the help center or report it on the end of the profile.',
      },
    ];

    const registrationHelpItems = [
      {
        title: 'I don’t remember my registration e-mail.',
        response: 'Please send us an e-mail and we will try our best to help.',
      },
      {
        title: 'How do I change my password?',
        response: `
          1) Select login -
          2) Go to my account > profile -
          3) Select change password
        `,
      },
    ];

    const manageHelpItems = [
      {
        title: 'How do I delete my ad?',
        response:
          'Please go to my ads and select the edit button, inside the profile click delete ad.',
      },
    ];

    return {
      loginHelpItems,
      postingHelpItems,
      reportHelpItems,
      registrationHelpItems,
      manageHelpItems,
    };
  },
};
</script>
