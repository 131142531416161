<template>
  <div class="ad-info">
    <div class="d-flex">
      <div
        class="ad-info-image mr-5"
        :class="{
          pointer: ad.isPublished,
          'black-white-filter':
            isBefore(new Date(ad.expires_at), new Date()) || !ad.isPublished,
        }"
        @click="ad.isPublished && goTo(`/model/${ad.id}`)"
      >
        <div
          class="ad-info-details-plan z-1 d-flex align-items-center md-d-none mb-2"
        >
          <div
            :class="`ad-info-details-plan-${ad.plan}${ad.isNew ? ' mr-2' : ''}`"
          >
            <div v-if="ad.plan === 'basic'" class="text-white">Basic</div>
            <a-icon v-else class="ih-11" :type="getPlanIcon(ad.plan)" />
          </div>
          <div v-if="ad.isNew" class="ad-info-details-plan-new">
            <a-icon class="ih-14" type="white-fire" />
          </div>
        </div>
        <a-avatar
          :src="ad.mainPhoto || ad.photos[1]"
          shape="square"
          :size="200"
        />
        <div class="ad-info-image-tag md-d-none">
          <a-tag v-for="(tag, index) in ad.tag" :color="tag.color" :key="index">
            {{ tag.name }}
          </a-tag>
        </div>
      </div>
      <div class="ad-info-details d-flex flex-column justify-content-between">
        <div>
          <div
            class="ad-info-details-views"
            :class="{
              pointer: ad.isPublished,
              'black-white-filter': isBefore(
                new Date(ad.expires_at),
                new Date()
              ),
            }"
          >
            {{ ad.views }}
            <a-icon class="ml-2 ih-11" type="white-eye" />
          </div>
          <p class="fs-10 fw-medium mb-0 pr-16 mr-1">
            Created at {{ formatRelative(new Date(ad.created_at), new Date()) }}
          </p>

          <div
            class="d-none md-d-flex mt-3 mb-2"
            :class="{
              pointer: ad.isPublished,
              'black-white-filter': isBefore(
                new Date(ad.expires_at),
                new Date()
              ),
            }"
          >
            <div
              :class="`ad-info-details-plan-${ad.plan}${
                ad.isNew ? ' mr-2' : ''
              }`"
            >
              <div v-if="ad.plan === 'basic'">Basic</div>
              <a-icon v-else class="ih-11" :type="getPlanIcon(ad.plan)" />
            </div>

            <div v-if="ad.isNew" class="ad-info-details-plan-new">
              <a-icon class="ih-14" type="white-fire" />
            </div>
          </div>
          <div class="fs-18 fw-semi-bold mb-1 d-flex align-items-center">
            {{ ad.name }}
            <img v-if="ad.userVerified" class="verified-icon ml-1 ws-15" />
          </div>

          <div class="fs-12 fw-normal mb-2">
            {{ ad.title }}
          </div>

          <a-row :gutter="[10, 10]">
            <a-col :sm="24">
              <div
                class="d-none md-d-block"
                :class="{
                  pointer: ad.isPublished,
                  'black-white-filter': isBefore(
                    new Date(ad.expires_at),
                    new Date()
                  ),
                }"
              >
                <a-tag
                  v-for="(tag, index) in ad.tag"
                  :color="tag.color"
                  :key="index"
                >
                  {{ tag.name }}
                </a-tag>
              </div>
            </a-col>
            <a-col :sm="24" :md="24" v-if="ad.lastBuy.includes('pending')">
              <a-alert :message="alertMessage" type="warning" show-icon />

              <a-button
                class="mt-2 mb-3"
                size="small"
                :loading="isCancelPaymentLoading"
                @click="cancelPendingPayment(ad.id)"
              >
                Cancel
              </a-button>
              <a-button
                type="primary"
                class="ml-2"
                :loading="isPaymentLoading"
                size="small"
                @click="initPendingPayment(ad)"
              >
                Publish ad
              </a-button>
            </a-col>
            <a-col v-if="isRenewAdVisible(ad)" :sm="12" :md="6">
              <a-button
                :disabled="!isExpiring(ad.expires_at)"
                block
                type="primary"
                size="small"
                @click="showRenewModal(ad)"
              >
                Renew ad
              </a-button>
            </a-col>
            <a-col :sm="12" :md="6" v-if="isUpgradeVisible(ad)">
              <div class="w-100">
                <a-button
                  size="small"
                  type="primary"
                  :disabled="ad.plan === 'gold' && !!ad.isNew"
                  block
                  @click="showUpgradeModal(ad)"
                >
                  Upgrade
                </a-button>
              </div>
            </a-col>
            <a-col v-if="isPublishAdVisible(ad)" :sm="12" :md="6">
              <a-button
                block
                type="primary"
                size="small"
                @click="showPublishModal(ad)"
              >
                Publish ad
              </a-button>
            </a-col>
          </a-row>
        </div>
        <en-ad-info-footer
          class="md-d-none"
          :ad="ad"
          @showDeleteModal="showDeleteModal"
          @showDeactivateModal="showDeactivateModal"
          @showActivateModal="showActivateModal"
          @boostAd="boostAd"
        />
      </div>
    </div>

    <en-ad-info-footer
      class="d-none md-d-block mt-5"
      :ad="ad"
      @showDeleteModal="showDeleteModal"
      @showDeactivateModal="showDeactivateModal"
      @showActivateModal="showActivateModal"
      @boostAd="boostAd"
    />

    <a-modal
      v-model="isConfirmPaymentModalVisible"
      title="Confirm payment"
      @ok="onConfirmPayment"
      okText="Confirm"
      :confirmLoading="isPaymentLoading"
    >
      <template v-if="isDowngrade(currentAd.plan, paymentInfo.plan)">
        <a-result status="warning" class="p-0">
          <template #title>
            <h1 class="white-color">Warning</h1>
          </template>
          <template #subTitle>
            <p class="white-color text-start">
              The plan you are choosing is inferior to the one you created, so
              somethings will be removed to fit the chosen plan.
            </p>
          </template>
        </a-result>
        <hr />
      </template>

      The Ad will be available after payment is confirmed. Click in confirm to
      redirect to payment page and create Ad.
    </a-modal>

    <a-modal
      v-model="isDowngradeToBasicModalVisible"
      title="Confirm payment"
      @ok="onDowngradeToBasicModalConfirm"
      okText="Confirm"
      :confirmLoading="isRenewing"
    >
      <a-result status="warning" class="p-0">
        <template #title>
          <h1 class="white-color">Warning</h1>
        </template>
        <template #subTitle>
          <p class="white-color text-start">
            The plan you are choosing is inferior to the one you created, so
            somethings will be removed to fit the chosen plan.
          </p>
        </template>
      </a-result>
    </a-modal>

    <renew-ad-modal
      v-model="renewModalVisible"
      :currentAd="currentAd"
      :isPricesLoading="isPricesLoading"
      :plansPrices="planPrices"
      :isLoading="isRenewing"
      @update:selectedPlan="onSelectedPlanUpdate"
      @update:isNewSelected="onIsNewSelectedUpdate"
      @onRenew="onRenewConfirm"
    />

    <publish-ad-modal
      v-model="publishModalVisible"
      :currentAd="currentAd"
      :isPricesLoading="isPricesLoading"
      :plansPrices="planPrices"
      :isLoading="isRenewing"
      @update:selectedPlan="onSelectedPlanUpdate"
      @update:isNewSelected="onIsNewSelectedUpdate"
      @onPublish="onPublishConfirm"
    />

    <UpgradeAdModal
      v-model="upgradeModalVisible"
      :currentAd="currentAd"
      :isPricesLoading="isPricesLoading"
      :plansPrices="planPrices"
      @onUpgrade="onUpgradeConfirm"
      @update:selectedPlan="onSelectedPlanUpdate"
      @update:isNewSelected="onIsNewSelectedUpdate"
      @update:goldPrice="planPrices.gold = $event"
    />

    <delete-ad-modal
      v-model="deleteModalVisible"
      :currentAd="currentAd"
      @onConfirm="$emit('onConfirm')"
    />

    <deactivate-ad-modal
      v-model="deactivateModalVisible"
      :currentAd="currentAd"
      @onConfirm="$emit('onConfirm')"
    />

    <activate-ad-modal
      v-model="activateModalVisible"
      :currentAd="currentAd"
      @onConfirm="$emit('onConfirm')"
    />
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { message } from 'ant-design-vue';
import AdService from '@/services/AdService';
import useGlobal from '@/composables/useGlobal';
import {
  formatDistanceToNowStrict,
  formatRelative,
  isBefore,
  formatDistanceToNow,
  getTime,
} from 'date-fns';
import RenewAdModal from './Modals/RenewAdModal.vue';
import PublishAdModal from './Modals/PublishAdModal.vue';
import UpgradeAdModal from './Modals/UpgradeAdModal.vue';
import DeleteAdModal from './Modals/DeleteAdModal.vue';
import DeactivateAdModal from './Modals/DeactivateAdModal.vue';
import ActivateAdModal from './Modals/ActivateAdModal.vue';
import EnAdInfoFooter from './EnAdInfoFooter.vue';

export default {
  components: {
    RenewAdModal,
    UpgradeAdModal,
    PublishAdModal,
    DeleteAdModal,
    DeactivateAdModal,
    ActivateAdModal,
    EnAdInfoFooter,
  },
  props: {
    ad: { type: Object },
  },
  setup(props, { emit }) {
    const { goTo } = useGlobal();
    const getPlanIcon = (plan) => {
      if (plan === 'gold') return 'white-crown';
      if (plan === 'silver') return 'white-star';
      return '';
    };

    const isBoosting = ref(false);
    const selectedAdId = ref('');
    const boostAd = async (id) => {
      try {
        selectedAdId.value = id;
        isBoosting.value = true;
        await AdService.boostAd(id);
        emit('onConfirm');
        message.success('Ad boosted!', 4);
      } catch (error) {
        message.error(error.message, 4);
      } finally {
        isBoosting.value = false;
      }
    };

    const defaultCurrentAd = {
      selectedPlan: '',
      isNewSelected: false,
      photos: [],
      plan: '',
    };

    const poundsToFloat = (poundsString) => {
      const cleanedString = poundsString.replace(',', '.');
      const floatNumber = parseFloat(cleanedString).toFixed(2);
      return parseFloat(floatNumber);
    };

    const planPrices = ref({
      gold: 0,
      silver: 0,
      basic: 0,
    });

    const isPricesLoading = ref(false);
    const fetchPlansCost = async () => {
      try {
        isPricesLoading.value = true;
        const { data } = await AdService.fetchPlansCost(props.ad.city.id);

        planPrices.value = {
          gold: poundsToFloat(data.goldPrice),
          silver: poundsToFloat(data.silverPrice),
          basic: 0,
        };
      } catch (error) {
        console.log(error);
      } finally {
        isPricesLoading.value = false;
      }
    };

    const currentAd = ref(defaultCurrentAd);

    const onSelectedPlanUpdate = (value) => {
      currentAd.value.selectedPlan = value;
    };

    const onIsNewSelectedUpdate = (value) => {
      currentAd.value.isNewSelected = value;
    };

    const renewModalVisible = ref(false);
    const showRenewModal = (value) => {
      fetchPlansCost();
      currentAd.value = { ...defaultCurrentAd, ...value };
      currentAd.value.selectedPlan = currentAd.value.plan;
      currentAd.value.isNewSelected = Boolean(currentAd.value.isNew);
      renewModalVisible.value = true;
    };

    const publishModalVisible = ref(false);
    const showPublishModal = (value) => {
      fetchPlansCost();
      currentAd.value = { ...defaultCurrentAd, ...value };
      currentAd.value.selectedPlan = currentAd.value.plan;
      currentAd.value.isNewSelected = Boolean(currentAd.value.isNew);
      publishModalVisible.value = true;
    };

    const upgradeModalVisible = ref(false);
    const showUpgradeModal = (value) => {
      fetchPlansCost();
      currentAd.value = { ...defaultCurrentAd, ...value };
      currentAd.value.selectedPlan = currentAd.value.plan;
      currentAd.value.isNewSelected = Boolean(currentAd.value.isNew);
      upgradeModalVisible.value = true;
    };

    const deleteModalVisible = ref(false);
    const showDeleteModal = (value) => {
      currentAd.value = { ...value };
      deleteModalVisible.value = true;
    };

    const deactivateModalVisible = ref(false);
    const showDeactivateModal = (value) => {
      currentAd.value = { ...value };
      deactivateModalVisible.value = true;
    };

    const activateModalVisible = ref(false);
    const showActivateModal = (value) => {
      currentAd.value = { ...value };
      activateModalVisible.value = true;
    };

    const isExpiring = (expiresAt) => {
      const daysToExpire = Number(
        formatDistanceToNowStrict(new Date(expiresAt), { unit: 'day' }).split(
          ' ',
        )[0],
      );
      return daysToExpire <= 2 || new Date(expiresAt) < new Date();
    };

    const isConfirmPaymentModalVisible = ref(false);

    const paymentInfo = ref({});

    const isRenewing = ref(false);
    const renewAd = async ({ plan, isNew, id }, isPublishing) => {
      try {
        isRenewing.value = true;
        const payload = { plan, isNew, isPublishing };
        await AdService.renewAd(id, payload);
        message.success('Ad renewed!', 4);
        emit('onRenew');
        emit('input', false);
        emit('onConfirm');
      } catch (error) {
        message.error(error.message, 4);
      } finally {
        isRenewing.value = false;
      }
    };

    const isDowngrade = (currentPlan, newPlan) => {
      const plans = ['gold', 'silver', 'basic'];
      const currentPlanIndex = plans.indexOf(currentPlan);
      const newPlanIndex = plans.indexOf(newPlan);
      if (currentPlanIndex === -1 || newPlanIndex === -1) return false;
      if (newPlanIndex > currentPlanIndex) return true;
      return false;
    };

    const isDowngradeToBasicModalVisible = ref(false);

    const action = ref('');
    const onRenewConfirm = (value) => {
      action.value = 'RENEW';
      paymentInfo.value = value;
      if (value.plan === 'basic' && !value.isNew) {
        if (isDowngrade(currentAd.value.plan, paymentInfo.value.plan)) {
          isDowngradeToBasicModalVisible.value = true;
        } else {
          renewAd(value);
        }
      } else {
        isConfirmPaymentModalVisible.value = true;
      }
    };

    const onPublishConfirm = (value) => {
      action.value = 'PUBLISHING';
      paymentInfo.value = value;
      console.log(value);
      // planPrices.value.gold = 22.99;
      if (value.plan === 'basic') {
        if (isDowngrade(currentAd.value.plan, paymentInfo.value.plan)) {
          isDowngradeToBasicModalVisible.value = true;
        } else if (value.isNew) {
          isConfirmPaymentModalVisible.value = true;
        } else {
          renewAd(value, true);
        }
      } else {
        isConfirmPaymentModalVisible.value = true;
      }
    };

    const onDowngradeToBasicModalConfirm = () => {
      renewAd(paymentInfo.value, true);
    };

    const onUpgradeConfirm = (value) => {
      action.value = 'UPGRADE';
      paymentInfo.value = value;
      isConfirmPaymentModalVisible.value = true;
    };

    const getTotalValue = () => (paymentInfo.value.plan
      ? planPrices.value[paymentInfo.value.plan]
      : 0.0) + (paymentInfo.value.isNew ? 4.99 : 0);

    const isPaymentLoading = ref(false);
    const onConfirmPayment = async () => {
      try {
        if (isDowngrade(currentAd.value.plan, paymentInfo.value.plan)) {
          action.value = `${action.value} PUBLISHING`;
        }
        isPaymentLoading.value = true;

        const payload = {
          amount: getTotalValue().toFixed(2),
          description: `${
            action.value ? `${action.value.toUpperCase()} - ` : ''
          }PURCHASE ${
            paymentInfo.value.plan
              ? `PLAN ${paymentInfo.value.plan.toUpperCase()}`
              : ''
          }${
            paymentInfo.value.isNew
              ? `${paymentInfo.value.plan ? ' WITH NEW TAG' : 'NEW TAG'}`
              : ''
          }`,
          custom1: paymentInfo.value.id,
          custom2: `${action.value ? `${action.value.toLowerCase()} ` : ''}${
            paymentInfo.value.plan
          }${paymentInfo.value.isNew ? ' isnew' : ''}`,
          backURL: `${
            process.env.NODE_ENV === 'development'
              ? 'http://localhost:8080'
              : 'https://www.realityescorts.com'
          }/post-ad/result/${paymentInfo.value.id}`,
        };
        const { data } = await AdService.initPayment(payload);
        await AdService.updatePayment(paymentInfo.value.id, {
          lastBuy: `pending${
            action.value ? ` ${action.value.toLowerCase()} ` : ''
          }${paymentInfo.value.plan}${paymentInfo.value.isNew ? ' isnew' : ''}`,
        });
        window.open(data, '_self');
        isPaymentLoading.value = false;
      } catch (error) {
        console.log(error);
        isPaymentLoading.value = false;
      } finally {
        isConfirmPaymentModalVisible.value = false;
      }
    };

    const getGoldPrice = () => {
      const expirationString = formatDistanceToNow(
        props.ad.expires_at ? new Date(props.ad.expires_at) : new Date(),
      );
      if (props.ad.plan === 'silver') {
        if (expirationString.includes('7')) return 7.68;
        if (expirationString.includes('6')) return 5.69;
        if (expirationString.includes('5')) return 3.7;
        return 'unavailable';
      }
      return planPrices.value.gold;
    };

    const getPlan = (lastBuy) => {
      if (lastBuy.includes('basic')) return 'basic';
      if (lastBuy.includes('silver')) return 'silver';
      if (lastBuy.includes('gold')) return 'gold';
      return '';
    };

    const getAction = (lastBuy) => {
      const actions = [];
      if (lastBuy.includes('publishing')) actions.push('publishing');
      if (lastBuy.includes('upgrade')) actions.push('upgrade');
      if (lastBuy.includes('renew')) actions.push('renew');
      return actions.join(' ');
    };

    const initPendingPayment = async (ad) => {
      try {
        isPaymentLoading.value = true;
        const { data } = await AdService.fetchPlansCost(props.ad.city.id);
        planPrices.value = {
          gold: poundsToFloat(data.goldPrice),
          silver: poundsToFloat(data.silverPrice),
          basic: 0,
        };

        action.value = getAction(ad.lastBuy);
        const goldPrice = getGoldPrice() === 'unavailable'
          ? planPrices.value.gold
          : getGoldPrice();
        planPrices.value.gold = goldPrice;

        paymentInfo.value = {
          plan: getPlan(ad.lastBuy),
          isNew: ad.lastBuy.includes('isnew'),
          id: ad.id,
        };

        onConfirmPayment();
      } catch (error) {
        console.log(error);
      } finally {
        isPaymentLoading.value = false;
      }
    };

    const isCancelPaymentLoading = ref(false);
    const cancelPendingPayment = async (id) => {
      try {
        isCancelPaymentLoading.value = true;
        await AdService.clearPayment(id);
        emit('onConfirm');
      } catch (error) {
        console.log(error);
      } finally {
        isCancelPaymentLoading.value = false;
      }
    };

    const alertMessage = computed(
      () => `You have a pending payment for your ${getPlan(
        props.ad.lastBuy,
      )} plan${props.ad.lastBuy.includes('isnew') ? ' with the new tag' : ''}.
              Then your changes will be applied only when payment is done.`,
    );

    function daysUntilExpiration(expirationDate) {
      const today = new Date();
      const expiration = new Date(expirationDate);
      const difference = expiration.getTime() - today.getTime();
      const days = Math.ceil(difference / (1000 * 3600 * 24));
      return days < 0 ? 0 : days - 1;
    }

    function isExpired(expirationDate) {
      const today = new Date();
      const expiration = new Date(expirationDate);
      return expiration < today;
    }

    const isRenewAdVisible = (ad) => ((isExpired(ad.expires_at) && !ad.isPayed)
        || (!isExpired(ad.expires_at)
          && ad.isPayed
          && daysUntilExpiration(ad.expires_at) <= 2))
      && !ad.lastBuy.includes('pending');

    const isPublishAdVisible = (ad) => !isExpired(ad.expires_at) && (!ad.isPayed && ad.plan !== 'basic');

    function canUpgrade(type, isNew) {
      return (
        type === 'basic'
        || type === 'silver'
        || type === 'gold'
        || isNew
        || type !== 'gold'
      );
    }

    const isUpgradeVisible = (ad) => canUpgrade(ad.plan, Boolean(ad.isNew))
      && !isExpired(ad.expires_at)
      && !ad.lastBuy.includes('pending')
      && (ad.isPayed || ad.plan === 'basic');

    return {
      isPublishAdVisible,
      isRenewAdVisible,
      isUpgradeVisible,
      getPlanIcon,
      boostAd,
      isBoosting,
      selectedAdId,
      goTo,
      formatDistanceToNowStrict,
      formatRelative,
      isBefore,
      formatDistanceToNow,
      showRenewModal,
      renewModalVisible,
      currentAd,
      upgradeModalVisible,
      showUpgradeModal,
      getTime,
      deleteModalVisible,
      showDeleteModal,
      deactivateModalVisible,
      showDeactivateModal,
      activateModalVisible,
      showActivateModal,
      isExpiring,
      isConfirmPaymentModalVisible,
      onConfirmPayment,
      onRenewConfirm,
      isRenewing,
      onUpgradeConfirm,
      planPrices,
      initPendingPayment,
      isPaymentLoading,
      cancelPendingPayment,
      isCancelPaymentLoading,
      alertMessage,
      isDowngrade,
      paymentInfo,
      isDowngradeToBasicModalVisible,
      onDowngradeToBasicModalConfirm,
      publishModalVisible,
      showPublishModal,
      onPublishConfirm,
      isPricesLoading,
      onSelectedPlanUpdate,
      onIsNewSelectedUpdate,
    };
  },
};
</script>

<style lang="scss" scoped>
@import './EnAdInfo.scss';
</style>
