import { computed } from 'vue';
import store from '@/store/store';
import useGlobal from '@/composables/useGlobal';

const useUser = () => {
  const { goTo } = useGlobal();

  const user = computed(() => store.getters['userStore/getUser']);
  const isAuthenticated = computed(() => store.getters['userStore/isAuthenticated']);
  const setUser = (data) => store.dispatch('userStore/setUser', data);

  const getLocalUser = () => {
    setUser({ ...JSON.parse(localStorage.getItem('user')) } || {});
  };

  const logout = () => {
    localStorage.removeItem('user');
    setUser({});
    goTo('/');
  };

  const isLightMode = computed(() => store.getters['userStore/isLightMode']);
  const setIsLightMode = (data) => store.dispatch('userStore/setIsLightMode', data);

  return {
    user,
    setUser,
    isAuthenticated,
    getLocalUser,
    logout,
    isLightMode,
    setIsLightMode,
  };
};

export default useUser;
