<template>
  <container class="d-flex justify-content-center my-15">
    <div class="w-100" style="max-width: 636px">
      <en-label class="mb-2">
        <span class="fs-13 fw-500 fw-medium ml-3">Location:</span>
      </en-label>

      <a-form-model
        ref="createAdForm"
        :model="ad"
        :rules="formRules"
        @submit="submitCreateAd"
      >
        <a-auto-complete
          :value="cityid"
          :data-source="cities"
          :disabled="(!isExpired(ad.expires_at) || loading) && isEditing"
          class="w-100 mb-8 z-0"
          placeholder="Search district here"
          @select="onCityIdChange"
        />

        <a-card class="mb-6" v-if="!isEditing">
          <img :src="discount" class="w-100 mb-5" />

          <h6 class="pink-color-1">Chose your plan</h6>
          <p class="mb-5 fs-11 fw-400">
            All plans will be available for 7 days
          </p>

          <a-row class="mb-3" :gutter="[20, 20]">
            <a-col :md="12" :sm="24">
              <en-plan-card
                icon="white-crown"
                name="Gold Vip"
                :value="`£${
                  parseFloat(plansCost.goldPrice.replace(',', '.')) * 2
                }`"
                :isLoading="isPricesLoading"
                :discountValue="`£${plansCost.goldPrice}`"
                id="gold"
                hasDiscount
                :selected="plan === 'gold'"
                color="#ffffff"
                backgroundColor="#F7B422"
                :items="goldItems"
                @click:selected="onPlanSelect"
              />
            </a-col>
            <a-col :md="12" :sm="24">
              <en-plan-card
                name="Silver"
                id="silver"
                :value="`£${
                  parseFloat(plansCost.silverPrice.replace(',', '.')) * 2
                }`"
                :discountValue="`£${plansCost.silverPrice}`"
                color="#ffffff"
                backgroundColor="#838282"
                :isLoading="isPricesLoading"
                icon="white-star"
                :selected="plan === 'silver'"
                :items="silverItems"
                @click:selected="onPlanSelect"
              />
            </a-col>
            <a-col :md="12" :sm="24">
              <en-plan-card
                name="Basic"
                id="basic"
                color="#000000"
                value="Free"
                backgroundColor="#fff"
                :isLoading="isPricesLoading"
                :selected="plan === 'basic'"
                :items="freeItems"
                @click:selected="onPlanSelect"
              />
            </a-col>
          </a-row>

          <en-label class="mt-4 mb-4">
            <span class="fs-13 fw-medium ml-3">Improve your profile views</span>
          </en-label>
          <en-service-tag
            name="Tag new"
            value="£4,99"
            icon="white-fire"
            :selected="isnew"
            @click:selected="isnew = $event"
          />
        </a-card>

        <a-card>
          <h6 class="pink-color-1 mb-6">Ad informations</h6>

          <a-radio-group v-model="candidate">
            <a-radio value="Independent"> Independent </a-radio>
            <a-radio value="Agency"> Agency </a-radio>
          </a-radio-group>

          <a-input
            v-if="candidate === 'Agency'"
            class="mt-5"
            v-model="agName"
            placeholder="Agency Name"
          />

          <en-label icon="pink-people" iconWidth="15px" class="mt-6 mb-4">
            <span class="fs-13 fw-medium ml-3">Name</span>
          </en-label>
          <a-form-model-item prop="name">
            <a-input
              v-model="name"
              placeholder="Type the name here"
              :maxLength="14"
            />
            <div v-if="name" class="fs-10 black-color text-end">
              {{ name.length }} / 14
            </div>
          </a-form-model-item>

          <en-label icon="pink-clipboard" iconWidth="15px" class="mt-4 mb-4">
            <span class="fs-13 fw-medium ml-3">Title</span>
          </en-label>

          <a-form-model-item prop="title">
            <a-input v-model="title" placeholder="Type the title here">
              <a-dropdown
                slot="suffix"
                placement="bottomCenter"
                :trigger="['click']"
              >
                <a-icon
                  @click="(e) => e.preventDefault()"
                  type="smile"
                  style="color: #999"
                />
                <picker
                  slot="overlay"
                  :showPreview="false"
                  class="mt-4"
                  @select="onTitleEmojiSelect"
                />
              </a-dropdown>
            </a-input>
          </a-form-model-item>

          <en-label icon="pink-clipboard" iconWidth="15px" class="mt-4 mb-4">
            <span class="fs-13 fw-medium ml-3">Description</span>
          </en-label>
          <div class="position-relative">
            <a-form-model-item prop="description">
              <a-textarea
                v-model="description"
                placeholder="Type the description here"
                :auto-size="{ minRows: 4, maxRows: 16 }"
                style="padding: 10px 40px 10px 20px"
              />
            </a-form-model-item>
            <a-dropdown
              slot="suffix"
              placement="bottomCenter"
              :trigger="['click']"
            >
              <a-icon
                @click="(e) => e.preventDefault()"
                type="smile"
                style="color: #999; position: absolute; right: 20px; top: 12px"
              />
              <picker
                slot="overlay"
                :showPreview="false"
                class="mt-4"
                @select="onDescriptionEmojiSelect"
              />
            </a-dropdown>
          </div>

          <en-label icon="pink-phone" iconWidth="15px" class="mt-4 mb-4">
            <span class="fs-13 fw-medium ml-3">Phone</span>
          </en-label>

          <en-phone-number
            v-model="phone"
            size="lg"
            @update="onPhoneUpdate"
            default-country-code="GB"
          />

          <en-label icon="pink-pin" iconWidth="15px" class="mb-4 mt-5">
            <span class="fs-13 fw-medium ml-3">Districts locations</span>
          </en-label>

          <a-form-model-item
            prop="districtid"
            :class="{ 'ad-district-is-invalid': districtid[0] }"
          >
            <a-row :gutter="[20, 20]" v-if="!isCityLoading">
              <a-col
                v-for="index in locationAmount"
                :key="index"
                :md="8"
                :sm="24"
              >
                <a-popover trigger="click" placement="bottom">
                  <a-auto-complete
                    slot="content"
                    :value="districtid[index - 1]"
                    :data-source="districts"
                    placeholder="Search district here"
                    @select="onDistrictSelect($event, index)"
                    :filter-option="filterOption"
                  />
                  <div
                    v-if="districtid[index - 1]"
                    class="d-flex align-items-center
                    justify-content-center text-center black-color mt-2 px-2 pointer"
                  >
                    {{ getLocationLabel(districtid[index - 1]) }}

                    <a-button
                      type="primary"
                      shape="circle"
                      size="small"
                      style="top: 0; right: 0"
                      class="position-absolute z-1"
                      @click.stop="removeDistrict(index)"
                    >
                      <div
                        class="d-flex justify-content-center align-items-center"
                      >
                        <a-icon type="close" />
                      </div>
                    </a-button>
                  </div>
                  <a-button
                    v-else
                    block
                    type="primary"
                    @click="(e) => e.preventDefault()"
                    class="overflow-hidden"
                  >
                    <div
                      class="d-flex align-items-center justify-content-center"
                    >
                      {{ index === 1 ? 'Main ' : '' }}Location<a-icon
                        type="plus"
                        class="ml-2"
                      />
                    </div>
                  </a-button>
                </a-popover>
              </a-col>
            </a-row>
          </a-form-model-item>

          <a-row class="mt-5" :gutter="[24, 42]">
            <a-col :md="12" :sm="24">
              <en-label icon="pink-calendar" iconWidth="15px" class="mb-4">
                <span class="fs-13 fw-medium ml-3">Age</span>
              </en-label>
              <a-form-model-item prop="age">
                <a-input-number v-model="age" class="w-100" :min="18" />
              </a-form-model-item>
            </a-col>
            <a-col :md="12" :sm="24">
              <en-label icon="pink-eye" iconWidth="15px" class="mb-4">
                <span class="fs-13 fw-medium ml-3">Eye color</span>
              </en-label>
              <a-form-model-item prop="eyes">
                <a-select class="w-100" v-model="eyes">
                  <a-select-option
                    v-for="eyeColor in EyesColors"
                    :key="eyeColor"
                    :value="eyeColor"
                  >
                    {{ eyeColor }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :md="12" :sm="24">
              <en-label icon="pink-face" iconWidth="15px" class="mb-4">
                <span class="fs-13 fw-medium ml-3">Hair</span>
              </en-label>
              <a-form-model-item prop="hair">
                <a-select class="w-100" v-model="hair">
                  <a-select-option
                    v-for="hairColor in HairColors"
                    :key="hairColor"
                    :value="hairColor"
                  >
                    {{ hairColor }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :md="12" :sm="24">
              <en-label icon="pink-face" iconWidth="15px" class="mb-4">
                <span class="fs-13 fw-medium ml-3">Height (cm)</span>
              </en-label>
              <a-form-model-item prop="height">
                <a-input-number
                  v-model="height"
                  :max="250"
                  :min="50"
                  :default-value="150"
                  decimalSeparator=","
                  class="w-100"
                />
              </a-form-model-item>
            </a-col>
            <a-col :md="24" :sm="24">
              <en-label icon="pink-globe" iconWidth="15px" class="mb-4">
                <span class="fs-13 fw-medium ml-3">Nationality</span>
              </en-label>
              <a-form-model-item prop="ethnicityid">
                <a-auto-complete
                  v-model="ethnicityid"
                  class="w-100"
                  :data-source="ethnicities"
                  placeholder="Search district here"
                  :filter-option="filterOption"
                />
              </a-form-model-item>
            </a-col>
          </a-row>

          <hr v-if="plan === 'gold'" class="my-6" />

          <ol v-if="plan === 'gold'">
            <li v-for="index in 3" :key="index" class="mb-5">
              <a-select v-model="tags[index - 1]" class="en-tag-selector w-100">
                <a-select-option
                  v-for="adTag in adTags"
                  :key="adTag.id"
                  :value="adTag.id"
                >
                  <span
                    class="ml-2 py-1 px-3"
                    :style="{
                      backgroundColor: adTag.color,
                      height: '20px',
                      borderRadius: '2px',
                    }"
                  >
                    {{ adTag.name }}
                  </span>
                </a-select-option>
              </a-select>
            </li>
          </ol>

          <hr class="my-6" />
          <h6 class="mt-5 mb-5 pink-color-1">Services</h6>
          <a-checkbox @change="onCheckAll" :checked="checkAll" class="mb-5">
            Check all
          </a-checkbox>
          <div
            class="d-flex justify-content-between sm-flex-column"
            v-for="(service, index) in services"
            :key="service.id"
          >
            <div class="mb-2">
              <a-checkbox
                @change="onCheckboxChange(index, $event)"
                :checked="service.checked"
              >
                {{ service.name }}
              </a-checkbox>
            </div>

            <div class="d-flex align-items-baseline mb-5">
              <div class="mr-2 text-nowrap">Extras:</div>
              <a-input
                class="en-underline-input"
                v-model="service.price"
                v-money="moneyMask"
                :disabled="!service.checked"
                size="small"
              />
            </div>
          </div>

          <h6 class="mt-5 mb-5 pink-color-1">Price</h6>
          <a-table
            v-if="!loading"
            :columns="columns"
            :data-source="priceTable"
            size="small"
            :pagination="false"
          >
            <template slot="time" slot-scope="{ time }">
              {{ time }}
            </template>
            <template slot="inCall" slot-scope="{ key }">
              <a-input
                class="en-underline-input"
                size="small"
                v-model="rates.inCall[key]"
                v-money="moneyMask"
              />
            </template>
            <template slot="outCall" slot-scope="{ key }">
              <a-input
                class="en-underline-input"
                size="small"
                v-model="rates.outCall[key]"
                v-money="moneyMask"
              />
            </template>
          </a-table>
        </a-card>

        <h6 class="mt-5 mb-5 pink-color-1">Photo</h6>

        <a-modal
          title="Image crop"
          :visible="isCropModalVisible"
          @ok="onCropConfirm"
          @cancel="isCropModalVisible = false"
          :confirmLoading="isConfirmLoading"
          :maskClosable="false"
        >
          <div class="position-relative">
            <cropper
              style="height: 500px; width: 600px"
              ref="cropper"
              :src="currentFile.image"
              @change="onCropChange"
              image-restriction="none"
              :stencil-props="{ aspectRatio: 9 / 11 }"
              :canvas="{ fillColor: '#000000' }"
            />
            <en-crop-vertical-buttons>
              <en-crop-square-button title="Zoom In" @click="zoom(2)">
                <a-icon type="zoom-in" />
              </en-crop-square-button>
              <en-crop-square-button title="Zoom Out" @click="zoom(0.5)">
                <a-icon type="zoom-out" />
              </en-crop-square-button>
              <en-crop-square-button title="Move Top" @click="move('top')">
                <a-icon type="arrow-up" />
              </en-crop-square-button>
              <en-crop-square-button title="Move Right" @click="move('right')">
                <a-icon type="arrow-right" />
              </en-crop-square-button>
              <en-crop-square-button
                title="Move Bottom"
                @click="move('bottom')"
              >
                <a-icon type="arrow-down" />
              </en-crop-square-button>
              <en-crop-square-button title="Move Left" @click="move('left')">
                <a-icon type="arrow-left" />
              </en-crop-square-button>
            </en-crop-vertical-buttons>
            <div class="d-flex justify-content-center">
              <preview
                :style="{ backgroundColor: '#000' }"
                class="crop-preview"
                :width="112"
                :height="137"
                :image="preview.image"
                :coordinates="preview.coordinates"
              />
            </div>
          </div>
        </a-modal>

        <div class="button-wrapper position-relative">
          <a-button
            class="mb-5"
            @click="$refs.file.click()"
            :disabled="loadImageButtonDisabled"
          >
            <input
              class="d-none"
              type="file"
              ref="file"
              @change="loadImage($event)"
              accept="image/*"
            />
            Load photo
          </a-button>

          <a-row :gutter="[20, 20]">
            <template v-for="(photo, index) in photos">
              <a-col :sm="12" :md="8" :key="index" v-if="index % 2 === 0">
                <div
                  class="position-relative pointer"
                  style="width: 190px; height: 190px"
                  @click="selectMainPhoto(photo)"
                >
                  <a-button
                    type="primary"
                    shape="circle"
                    size="small"
                    style="top: -10px; right: -10px"
                    class="position-absolute z-1"
                    @click="removePhoto(index)"
                  >
                    <div
                      class="d-flex justify-content-center align-items-center"
                    >
                      <a-icon type="close" />
                    </div>
                  </a-button>
                  <a-avatar
                    :src="photo"
                    :class="{ 'main-photo': mainPhoto === photos[index + 1] }"
                    shape="square"
                    :size="190"
                  />
                  <div
                    class="main-photo-label position-absolute z-1"
                    v-if="mainPhoto === photos[index + 1]"
                  >
                    Main photo
                  </div>
                </div>
              </a-col>
            </template>
          </a-row>
        </div>

        <template v-if="plan === 'gold'">
          <h6 class="mt-5 mb-5 pink-color-1">Vídeo</h6>

          <div>
            <a-button
              class="mb-5"
              @click="$refs.videoFile.click()"
              :disabled="!!videos.length"
            >
              <input
                class="d-none"
                type="file"
                ref="videoFile"
                @change="loadVideo($event)"
                accept=".mp4, .mov, .avi, .mkv"
              />
              Load video
            </a-button>
            <div>
              <en-skeleton
                v-if="isLoadingVideo"
                type="rect"
                height="200px"
                width="360px"
                :radius="0"
                wave-color="rgba(255, 255, 255, 0.3)"
                animation="wave"
              />

              <div
                v-if="!isLoadingVideo && !!videos.length"
                class="position-relative"
                style="width: 360px"
              >
                <a-button
                  type="primary"
                  shape="circle"
                  size="small"
                  style="top: -10px; right: -10px"
                  class="position-absolute z-1"
                  @click="videos = []"
                >
                  <div class="d-flex justify-content-center align-items-center">
                    <a-icon type="close" />
                  </div>
                </a-button>
                <video
                  width="360px"
                  height="auto"
                  style="max-width: 500px"
                  controls
                >
                  <source :src="videos[0]" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
        </template>

        <a-card class="my-6" v-if="!isAuthenticated">
          <h1 class="fs-20 mb-11">Register</h1>

          <en-label icon="pink-face" iconWidth="15px" class="mb-4">
            <span class="fs-13 fw-medium ml-3">Account name</span>
          </en-label>
          <a-form-model-item prop="accname">
            <a-input
              v-model="accname"
              placeholder="Enter your account name here"
            />
          </a-form-model-item>

          <en-label icon="pink-mail" iconWidth="15px" class="my-4">
            <span class="fs-13 fw-medium ml-3">E-mail</span>
          </en-label>
          <a-form-model-item prop="email">
            <a-input v-model="email" placeholder="Enter your email here" />
          </a-form-model-item>

          <en-label icon="pink-lock" iconWidth="15px" class="my-4">
            <span class="fs-13 fw-medium ml-3">Password</span>
          </en-label>
          <a-form-model-item prop="password">
            <a-input
              type="password"
              v-model="password"
              placeholder="Enter your password here"
            />
          </a-form-model-item>
        </a-card>

        <a-checkbox v-model="acceptedTerms" class="mt-5 w-100">
          I accept <router-link to="/terms" target="_blank">terms</router-link>
        </a-checkbox>

        <a-button
          block
          type="primary"
          class="mt-5"
          html-type="submit"
          :loading="processing"
          :disabled="!acceptedTerms"
        >
          {{ submitButtonLabel }}
        </a-button>
      </a-form-model>
    </div>

    <a-modal
      v-model="isConfirmPaymentModalVisible"
      title="Confirm payment"
      @ok="onConfirmPayment"
      okText="Confirm"
      :confirmLoading="processing"
    >
      {{
        isAuthenticated
          ? `The Ad will be available after payment is confirmed.
          Click in confirm to redirect to payment page and create ad.`
          : `The Ad will be available after payment is confirmed.
          Please, click in confirm to login and publish ad.`
      }}
    </a-modal>
  </container>
</template>

<script>
import {
  computed, reactive, ref, toRefs,
} from 'vue';
import { formatDistanceToNowStrict } from 'date-fns';
import EnLabel from '@/components/atoms/EnLabel.vue';
import EnPlanCard from '@/components/molecules/EnPlanCard.vue';
import EnServiceTag from '@/components/molecules/EnServiceTag.vue';
import EyesColors from '@/json/EyesColors.json';
import HairColors from '@/json/HairColors.json';
import LocationService from '@/services/LocationService';
import AdService from '@/services/AdService';
import { message } from 'ant-design-vue';
import useUser from '@/composables/useUser';
import useGlobal from '@/composables/useGlobal';
import { Picker } from 'emoji-mart-vue';
import useAd from '@/composables/useAd';
import discount from '@/assets/images/svg/discount.svg';
import 'vue-advanced-cropper/dist/style.css';
import { Cropper, Preview } from 'vue-advanced-cropper';
import EnCropSquareButton from '@/components/atoms/EnCropSquareButton.vue';
import EnCropVerticalButtons from '@/components/atoms/EnCropVerticalButtons.vue';

export default {
  props: {
    id: { type: [String, Number] },
  },
  components: {
    EnLabel,
    EnPlanCard,
    EnServiceTag,
    Picker,
    Cropper,
    Preview,
    EnCropSquareButton,
    EnCropVerticalButtons,
  },
  metaInfo() {
    return {
      title: 'Reality Escorts',
      meta: [{
        name: 'description', content: 'Find the best independent and agency escorts at Reality Escorts.',
      }],
    };
  },
  setup(props) {
    const { user, isAuthenticated } = useUser();
    const {
      goTo,
      emailValidation,
      nameValidation,
      passwordValidation,
      scrollTop,
    } = useGlobal();

    const preview = ref({});

    const isCropModalVisible = ref(false);

    function getMimeType(file, fallback = null) {
      const byteArray = new Uint8Array(file).subarray(0, 4);
      let header = '';
      for (let i = 0; i < byteArray.length; i += 1) {
        header += byteArray[i].toString(16);
      }
      switch (header) {
        case '89504e47':
          return 'image/png';
        case '47494638':
          return 'image/gif';
        case 'ffd8ffe0':
        case 'ffd8ffe1':
        case 'ffd8ffe2':
        case 'ffd8ffe3':
        case 'ffd8ffe8':
          return 'image/jpeg';
        default:
          return fallback;
      }
    }

    const currentFile = ref({ image: '', name: '', type: '' });
    const size = ref({ width: null, height: null });

    const onCropChange = ({ coordinates, image }) => {
      size.value.width = Math.round(coordinates.width);
      size.value.height = Math.round(coordinates.height);
      preview.value = { coordinates, image };
    };

    const actionUrl = `${process.env.VUE_APP_BASE_URL}/model-ad/upload`;

    const ad = reactive({
      name: '',
      title: '',
      description: '',
      age: '',
      eyes: '',
      hair: '',
      agName: '',
      height: '',
      phone: '',
      formattedPhone: '',
      mainPhoto: '',
      cityid: '4',
      districtid: ['', '', ''],
      candidate: 'Independent',
      ethnicityid: '',
      plan: 'gold',
      rates: {
        inCall: {
          15: '',
          30: '',
          60: '',
          90: '',
          120: '',
          180: '',
          240: '',
          480: '',
        },
        outCall: {
          15: '',
          30: '',
          60: '',
          90: '',
          120: '',
          180: '',
          240: '',
          480: '',
        },
      },
      isnew: false,
      serviceid: [],
      tags: ['', '', ''],
      photos: [],
      videos: [],
      accname: '',
      email: '',
      password: '',
    });

    const onPlanSelect = (plan) => {
      if (plan === 'basic') {
        ad.photos.splice(4);
        ad.photos = [...ad.photos];
        ad.videos = [];
        ad.districtid.splice(1);
        ad.tags = [];
      }
      if (plan === 'silver') {
        ad.photos.splice(6);
        ad.photos = [...ad.photos];
        ad.videos = [];
        ad.tags = [];
      }
      ad.plan = plan;
    };

    const isEditing = computed(() => !!props.id);

    const { goldItems, silverItems, freeItems } = useAd();

    const cities = ref([]);
    const isCityLoading = ref(false);
    const fetchCities = async () => {
      try {
        isCityLoading.value = true;
        const { data } = await LocationService.fetchCity();
        cities.value = data.map(({ id, name }) => ({
          value: id,
          text: name,
        }));
      } catch (error) {
        console.log(error);
      } finally {
        isCityLoading.value = false;
      }
    };

    fetchCities();

    const districts = ref([]);
    const fetchDistrict = async (cityId) => {
      try {
        const { data } = await LocationService.fetchDistrict({ cityId });
        districts.value = data.map(({ id, name }) => ({
          value: id,
          text: name,
        }));
      } catch (error) {
        console.log(error);
      }
    };

    if (!isEditing.value) fetchDistrict(ad.cityid);

    const poundsToFloat = (poundsString) => {
      const cleanedString = poundsString.replace(',', '.');
      const floatNumber = parseFloat(cleanedString).toFixed(2);
      return parseFloat(floatNumber);
    };

    const plansCost = ref({ goldPrice: '', silverPrice: '', basicPrice: '' });
    const isPricesLoading = ref(false);
    const planPrices = ref({
      gold: 22.99,
      silver: 13.99,
      basic: 0,
    });

    const fetchPlansCost = async () => {
      try {
        isPricesLoading.value = true;
        const { data } = await AdService.fetchPlansCost(ad.cityid);
        plansCost.value = data;

        planPrices.value = {
          gold: poundsToFloat(data.goldPrice),
          silver: poundsToFloat(data.silverPrice),
          basic: 0,
        };
      } catch (error) {
        console.log(error);
      } finally {
        isPricesLoading.value = false;
      }
    };
    fetchPlansCost();

    const onCityIdChange = (value) => {
      ad.districtid = ['', '', ''];
      ad.cityid = value;
      fetchDistrict(value);
      fetchPlansCost();
    };

    const ethnicities = ref([]);
    const fetchEthnicity = async () => {
      try {
        const { data } = await LocationService.fetchEthnicity();
        ethnicities.value = data.map(({ id, name }) => ({
          value: id,
          text: name,
        }));
      } catch (error) {
        console.log(error);
      }
    };
    fetchEthnicity();

    const adTags = ref([]);
    const fetchTags = async () => {
      try {
        const { data } = await AdService.fetchTags();
        adTags.value = data;
      } catch (error) {
        console.log(error);
      }
    };
    fetchTags();

    const services = ref([]);
    const fetchServices = async () => {
      try {
        const { data } = await AdService.fetchServices();
        const servicesToHideIds = [
          '24',
          '34',
          '104',
          '134',
          '244',
          '264',
          '274',
          '284',
          '294',
          '354',
          '364',
        ];
        const filteredServices = data.filter(
          ({ id }) => !servicesToHideIds.includes(id),
        );
        services.value = filteredServices.map((service) => ({
          ...service,
          checked: false,
        }));
      } catch (error) {
        console.log(error);
      }
    };
    fetchServices();

    const filterOption = (input, option) => option.componentOptions.children[0].text
      .toUpperCase()
      .indexOf(input.toUpperCase()) >= 0;

    const columns = [
      {
        title: 'Time',
        width: '20%',
        scopedSlots: { customRender: 'time' },
      },
      {
        title: 'InCall',
        scopedSlots: { customRender: 'inCall' },
      },
      {
        title: 'OutCall',
        scopedSlots: { customRender: 'outCall' },
      },
    ];
    const priceTable = ref([
      { key: '15', time: '15 Min' },
      { key: '30', time: '30 Min' },
      { key: '60', time: '1 Hour' },
      { key: '90', time: '90 Min' },
      { key: '120', time: '2 Hours' },
      { key: '180', time: '3 Hours' },
      { key: '240', time: '4 Hours' },
      { key: '480', time: 'Overnight' },
    ]);

    const onCheckboxChange = (index, e) => {
      services.value[index].checked = e.target.checked;
    };

    const acceptedTerms = ref(false);

    const getVideoDuration = (file) => new Promise((resolve) => {
      const video = document.createElement('video');
      const fileURL = URL.createObjectURL(file);
      video.src = fileURL;
      video.ondurationchange = function () {
        resolve(this.duration);
      };
    });

    const onVideoRemove = (file) => {
      console.log(file.name);
    };

    const onVideoUploadChange = ({ file }) => {
      if (file.response) ad.videos.push(file.response);
    };

    const heightMask = {
      decimal: ',',
      thousands: '.',
      suffix: ' m',
      precision: 2,
    };

    const moneyMask = {
      decimal: ',',
      thousands: '.',
      prefix: '£ ',
      precision: 0,
    };
    const checkAll = ref(false);
    const onCheckAll = (value) => {
      checkAll.value = value.target.checked;
      if (checkAll.value) {
        services.value.forEach((service) => {
          service.checked = true;
        });
      } else {
        services.value.forEach((service) => {
          service.checked = false;
        });
      }
    };

    const onTitleEmojiSelect = (emoji) => {
      ad.title = `${ad.title}${emoji.native}`;
    };

    const onDescriptionEmojiSelect = (emoji) => {
      ad.description = `${ad.description}${emoji.native}`;
    };

    const getLocationLabel = (id) => districts.value.find((d) => d.value === id)?.text || '';

    const locationAmount = computed(() => (ad.plan !== 'basic' ? 3 : 1));

    const onDistrictSelect = (value, index) => {
      ad.districtid[index - 1] = value;
      ad.districtid = [...ad.districtid];
    };

    const isExpired = (expiresAt) => {
      if (!expiresAt) return false;
      const daysToExpire = Number(
        formatDistanceToNowStrict(new Date(expiresAt), { unit: 'day' }).split(
          ' ',
        )[0],
      );
      return daysToExpire <= 0 || new Date(expiresAt) < new Date();
    };

    const loading = ref(false);
    const fetchAds = async () => {
      try {
        loading.value = true;
        const { data } = await AdService.fetchAds({
          page: 1,
          id: props.id,
          update: true,
        });
        const [adDetail] = data.result;

        let clone = (({
          isAgency,
          isBoosted,
          isIndependent,
          isNew,
          city,
          isPublished,
          lastBoosted,
          userID,
          ...o
        }) => o)(adDetail);

        delete clone.created_at;
        delete clone.rates?.inCall?.rates_incall_id;
        delete clone.rates?.outCall?.rates_outcall_id;

        clone = {
          ...clone,
          agName: data.isAgency ? clone.agencyName : undefined,
          cityid: data.result[0].city.id,
          ethnicityid: clone.ethnicity.id,
          districtid: clone.district.map(({ id }) => id),
          serviceid: clone.service.map(({ id, value }) => ({
            id,
            price: value,
          })),
          tags: clone.tag.map(({ id }) => id),
          isnew: !!clone.isNew,
          candidate: data.isAgency ? 'Agency' : 'Independent',
          rates: {
            inCall: clone.rates?.inCall,
            outCall: clone.rates?.outCall,
          },
        };

        const selectedIds = adDetail.service.map(({ id }) => id);
        services.value.forEach((service) => {
          if (selectedIds.includes(service.id)) {
            service.checked = true;
            service.price = adDetail.service.find(
              ({ id }) => id === service.id,
            ).value;
          }
        });
        delete clone.agencyName;
        delete clone.district;
        delete clone.ethnicity;
        delete clone.service;

        const inCall = {};
        const outCall = {};

        if (clone.rates.length) {
          priceTable.value.forEach(({ key }) => {
            [, inCall[key]] = clone.rates.inCall[key].split(' ');
            inCall[key] = parseFloat(inCall[key]);
            [, outCall[key]] = clone.rates.outCall[key].split(' ');
            outCall[key] = parseFloat(outCall[key]);
          });
        }

        delete clone.rates.inCall;
        delete clone.rates.outCall;

        clone.rates.inCall = inCall;
        clone.rates.outCall = outCall;

        if (!ad.mainPhoto) {
          [, ad.mainPhoto] = ad.photos;
        }

        Object.assign(ad, JSON.parse(JSON.stringify(clone)));

        if (isEditing.value) fetchDistrict(ad.cityid);
      } catch (error) {
        message.error(error.response.data, 4);
      } finally {
        loading.value = false;
      }
    };

    if (isEditing.value) fetchAds();

    const cropper = ref();

    const crop = () => {
      const { canvas } = cropper.value.getResult();
      canvas.toBlob((blob) => {
        console.log(blob);
        // Do something with blob: upload to a server, download and etc.
      }, currentFile.value.type);
    };

    const reset = () => {
      currentFile.value = {
        image: '',
        type: '',
        name: '',
      };
    };

    const loadImage = (event) => {
      const { files } = event.target;
      if (files && files[0]) {
        if (currentFile.value.image) {
          URL.revokeObjectURL(currentFile.value.image);
        }
        const blob = URL.createObjectURL(files[0]);
        const reader = new FileReader();
        reader.onload = (e) => {
          currentFile.value.image = blob;
          currentFile.value.type = getMimeType(e.target.result, files[0].type);
          currentFile.value.name = files[0].name;
        };
        reader.readAsArrayBuffer(files[0]);
        isCropModalVisible.value = true;
      }
    };

    const destroyed = () => {
      if (currentFile.value.image) {
        URL.revokeObjectURL(currentFile.value.image);
      }
    };

    const selectMainPhoto = (url) => {
      const index = ad.photos.indexOf(url);
      if (isAuthenticated.value) {
        ad.mainPhoto = ad.photos[index + 1];
      } else {
        ad.mainPhoto = ad.photos[index];
      }
    };

    const convertToBase64 = (file) => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

    const isConfirmLoading = ref(false);

    const onCropConfirm = () => {
      const { canvas } = cropper.value.getResult();
      if (canvas) {
        const form = new FormData();
        canvas.toBlob(async (blob) => {
          try {
            isConfirmLoading.value = true;
            const file = new File(
              [blob],
              `${
                Math.random().toString(36).substring(2, 15)
                + Math.random().toString(36).substring(2, 15)
              }.jpg`,
            );

            if (isAuthenticated.value) {
              form.append('attachment', file);
              form.append('waterMark', true);
              const { data } = await AdService.uploadFile(form);
              ad.photos = [...ad.photos, ...data];
              if (ad.photos.length === 2) {
                [, ad.mainPhoto] = ad.photos;
              }
            } else {
              const base64Photo = await convertToBase64(file);
              ad.photos = [...ad.photos, base64Photo, file];
              if (ad.photos.length === 2) {
                ad.mainPhoto = base64Photo;
                selectMainPhoto(base64Photo);
              }
            }
          } catch (error) {
            console.log(error);
          } finally {
            isConfirmLoading.value = false;
            isCropModalVisible.value = false;
          }
        }, 'image/jpg');
      }
    };

    const loadImageButtonDisabled = computed(() => {
      if (ad.photos.length / 2 >= 4 && ad.plan === 'basic') return true;
      if (ad.photos.length / 2 >= 6 && ad.plan !== 'gold') return true;
      if (ad.photos.length / 2 >= 8) return true;
      return false;
    });

    const removePhoto = (index) => {
      ad.photos.splice(index, 2);
      ad.photos = [...ad.photos];
    };

    const videoFile = ref();

    const isLoadingVideo = ref(false);
    const loadVideo = async (event) => {
      const { files } = event.target;
      if (files && files[0]) {
        try {
          const duration = await getVideoDuration(files[0]);
          if (duration > 60) {
            message.error('Up to 1 minute video only.', 4);
            videoFile.value.value = '';
            return;
          }
          isLoadingVideo.value = true;
          if (isAuthenticated.value) {
            const form = new FormData();
            form.append('attachment', files[0]);
            const { data } = await AdService.uploadFile(form);
            ad.videos.push(data);
          } else {
            const base64Video = await convertToBase64(files[0]);
            ad.videos = [base64Video, files[0]];
          }
        } catch (error) {
          console.log(error);
        } finally {
          isLoadingVideo.value = false;
        }
      }
    };

    const submitButtonLabel = computed(() => {
      if (props.id) {
        return 'Save';
      }
      if (ad.plan === 'basic' && !ad.isnew) return 'Publish';
      return 'Finish payment';
    });

    const zoom = (factor) => {
      cropper.value.zoom(factor);
    };

    const move = (direction) => {
      if (direction === 'left') {
        cropper.value.move(-size.value.width / 4);
      } else if (direction === 'right') {
        cropper.value.move(size.value.width / 4);
      } else if (direction === 'top') {
        cropper.value.move(0, -size.value.height / 4);
      } else if (direction === 'bottom') {
        cropper.value.move(0, size.value.height / 4);
      }
    };

    const isMainPhoto = (url) => {
      const index = ad.photos.indexOf(url);
      return ad.mainPhoto === ad.photos[index + 1];
    };

    const removeDistrict = (index) => {
      ad.districtid[index - 1] = '';
      Object.assign(ad, JSON.parse(JSON.stringify(ad)));
    };

    const createAdForm = ref();

    const validateDistrictId = (rule, value, callback) => {
      const isValid = value[0] !== '';
      if (!isValid) {
        callback(new Error('Please select a district for all locations'));
      } else {
        callback();
      }
    };

    const defaultRules = computed(() => ({
      name: [
        {
          required: true,
          message: 'Please input the name.',
          trigger: 'blur',
        },
      ],
      title: [
        {
          required: true,
          message: 'Please input the title.',
          trigger: 'blur',
        },
      ],
      description: [
        {
          required: true,
          message: 'Please input the description.',
          trigger: 'blur',
        },
      ],
      phone: [
        {
          required: true,
          message: 'Please input the phone.',
          trigger: 'blur',
        },
      ],
      age: [
        { required: true, message: 'Please input the age.', trigger: 'blur' },
      ],
      eyes: [
        {
          required: true,
          message: 'Please select the eyes color.',
          trigger: 'blur',
        },
      ],
      hair: [
        {
          required: true,
          message: 'Please select the hair color.',
          trigger: 'blur',
        },
      ],
      height: [
        {
          required: true,
          message: 'Please input the height.',
          trigger: 'blur',
        },
      ],
      ethnicityid: [
        {
          required: true,
          message: 'Please select the nationality.',
          trigger: 'blur',
        },
      ],
      districtid: [
        {
          validator: (rule, _, callback) => validateDistrictId(rule, ad.districtid, callback),
          message: 'Please select at least the main location.',
          trigger: 'blur',
        },
      ],
    }));

    const notAuthenticatedRules = {
      accname: [
        {
          validator: nameValidation,
          trigger: ['blur', 'change'],
          required: true,
        },
      ],
      email: [
        {
          validator: emailValidation,
          trigger: ['blur', 'change'],
          required: true,
        },
      ],
      password: [
        {
          validator: passwordValidation,
          trigger: ['blur', 'change'],
          required: true,
        },
      ],
    };

    const formRules = computed(() => (isAuthenticated.value
      ? defaultRules
      : { ...defaultRules, notAuthenticatedRules }));

    const totalValue = ref(0);

    const getTotalValue = () => planPrices.value[ad.plan] + (ad.isnew ? 4.99 : 0);

    const initPayment = async (id) => {
      try {
        totalValue.value = getTotalValue();
        const payload = {
          amount: totalValue.value.toFixed(2),
          description: `PURCHASE PLAN ${ad.plan.toUpperCase()}${
            ad.isnew ? ' WITH NEW TAG' : ''
          }`,
          custom1: id,
          custom2: `${ad.plan}${ad.isnew ? ' isnew' : ''}`,
          backURL: `${
            process.env.NODE_ENV === 'development'
              ? 'http://localhost:8080'
              : 'https://www.realityescorts.com'
          }/post-ad/result/${id}`,
        };
        const { data } = await AdService.initPayment(payload);
        await AdService.updatePayment(id, {
          lastBuy: `pending ${ad.plan}${ad.isnew ? ' isnew' : ''}`,
        });
        if (isAuthenticated.value) window.open(data, '_self');
      } catch (error) {
        console.log(error);
      }
    };

    const processing = ref(false);
    const isConfirmPaymentModalVisible = ref(false);

    const submitAd = async () => {
      try {
        processing.value = true;
        ad.serviceid = services.value
          .filter(({ checked }) => checked)
          .map(({ id, price }) => ({ id, price }));

        ad.districtid = ad.districtid.filter(Boolean);
        ad.tags = ad.tags.filter(Boolean);
        delete ad.tag;
        if (ad.candidate === 'Independent') delete ad.agName;
        if (props.id) delete ad.id;

        if (isAuthenticated.value) {
          delete ad.accname;
          delete ad.email;
          delete ad.password;
          const payload = { ...ad, phone: ad.formattedPhone };
          if (props.id) {
            await AdService.updateAd(props.id, payload);
          } else {
            const { data } = await AdService.createAd(payload);
            if ((ad.plan === 'gold' || ad.plan === 'silver') || Boolean(ad.isnew)) {
              await initPayment(data.id);
            }
          }
          goTo('/my-account');
        } else {
          const payload = {
            ...ad,
            phone: ad.formattedPhone,
            photos: ad.photos.filter((photo) => typeof photo === 'string'),
            videos: ad.videos.filter((photo) => typeof photo === 'string'),
          };
          const { data } = await AdService.createAdNotAuthenticated(payload);
          if ((ad.plan === 'gold' || ad.plan === 'silver') || Boolean(ad.isnew)) {
            initPayment(data.id);
          } else goTo('/login');
        }
        isConfirmPaymentModalVisible.value = false;
        message.success(`Ad ${props.id ? 'updated' : 'created'}!`);
      } catch (error) {
        message.error(error.response.data.name, 4);
      } finally {
        processing.value = false;
      }
    };

    const onConfirmPayment = () => {
      submitAd();
    };

    const submitCreateAd = (e) => {
      e.preventDefault();
      createAdForm.value.validate(async (valid) => {
        if (valid) {
          if (ad.plan !== 'basic' && !isEditing.value) {
            isConfirmPaymentModalVisible.value = true;
          } else {
            submitAd();
          }
        } else {
          const validateFields = [
            'name',
            'title',
            'description',
            'age',
            'eyes',
            'districtid',
            'hair',
            'height',
            'ethnicityid',
          ];
          createAdForm.value.validateField(validateFields, (errorMessage) => {
            if (errorMessage) message.error(errorMessage, 4);
            scrollTop();
          });
        }
      });
    };

    const onPhoneUpdate = (value) => {
      ad.formattedPhone = value.formattedNumber;
    };

    return {
      isExpired,
      onConfirmPayment,
      isConfirmPaymentModalVisible,
      onPhoneUpdate,
      isMainPhoto,
      selectMainPhoto,
      submitButtonLabel,
      videoFile,
      removePhoto,
      loadImageButtonDisabled,
      isConfirmLoading,
      currentFile,
      onCropConfirm,
      preview,
      loadImage,
      cropper,
      crop,
      onPlanSelect,
      destroyed,
      goldItems,
      silverItems,
      getVideoDuration,
      freeItems,
      EyesColors,
      HairColors,
      cities,
      ethnicities,
      districts,
      filterOption,
      ...toRefs(ad),
      adTags,
      services,
      ad,
      columns,
      priceTable,
      onCheckboxChange,
      acceptedTerms,
      submitAd,
      processing,
      user,
      heightMask,
      moneyMask,
      actionUrl,
      onCheckAll,
      checkAll,
      onTitleEmojiSelect,
      onDescriptionEmojiSelect,
      getLocationLabel,
      locationAmount,
      onVideoUploadChange,
      onVideoRemove,
      onDistrictSelect,
      discount,
      onCropChange,
      getMimeType,
      reset,
      isCropModalVisible,
      loadVideo,
      isLoadingVideo,
      zoom,
      move,
      isEditing,
      removeDistrict,
      createAdForm,
      formRules,
      submitCreateAd,
      isAuthenticated,
      loading,
      initPayment,
      onCityIdChange,
      isCityLoading,
      plansCost,
      isPricesLoading,
    };
  },
};
</script>

<style lang="scss">
.cropper {
  height: 600px;
  width: 600px;
  background: #ddd;
}

.main-photo {
  border: 4px solid #d4145a;
}

.main-photo-label {
  background-color: #d4145a;
  bottom: 4px;
  padding: 2px 10px;
  border-radius: 0 3px 0 0;
  color: #fff;
  font-size: 11px;
}

.ad-district-is-invalid .ant-form-explain {
  display: none;
}

.special-price {
  position: relative;
  display: flex;
  justify-content: end;

  &-polygon {
    top: -16px;
    left: 0;
    position: absolute;
  }

  &-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 50px);
    background-color: #f21f50;
    border-radius: 5px;
  }
}
</style>
