<template>
  <container class="d-flex justify-content-center my-15">
    <div class="w-mobile">
      <h1 class="fs-20 mb-11">REALITYESCORTS PRIVACY POLICY</h1>

      <section>
        <p class="fw-light">
          REALITYESCORTS is committed to protecting your privacy. This Privacy
          Policy explains how we collect, use, disclose, and safeguard your
          personal information when you use the REALITYESCORTS Platform. We will
          only use the information that we collect about you in accordance with
          the applicable privacy laws including but not limited to the EU
          General Data Protection Regulation (GDPR), 2016. REALITYESCORTS
          reserves the right, at their sole discretion, to modify this Privacy
          Policy at any time, and such modifications shall be effective
          immediately upon posting of the same on the Platform. You agree to
          review this document periodically to be aware of such modifications
          and your continued access or use of the Platform shall be deemed as a
          conclusive acceptance of such modified Privacy Policy.
        </p>
        <p class="fw-light">
          IF YOU DO NOT AGREE WITH THE TERMS OF THIS PRIVACY POLICY, YOU ARE
          ADVISED NOT TO CONTINUE USING THE PLATFORM ANY LONGER.
        </p>
      </section>

      <section>
        <h6 class="mb-5 mt-5">1. WHAT INFORMATION WE COLLECT</h6>
        <p class="fw-light">
          While it is perfectly possible to visit and browse the REALITYESCORTS
          Platform without disclosing any personal information, if you choose to
          proceed by actively engaging with us by publishing any Content, you
          will be asked to provide some personal information including your
          first name, last name, email address and phone number. For each
          visitor of our Platform, we expressly collect non-personally
          identifiable information from and about your computer like your IP
          address, web browser type, version and language, operating system type
          and version, pages viewed while browsing the site, page access times,
          referring website address etc. If you are accessing our services
          through a mobile handheld device, we may request access or permission
          to certain system information including your mobile device ID number,
          model, manufacturer, etc. If you wish to change any of the access
          permissions, you may do so in your device’s settings or write to us
          using the contact details given at the end of this page.
        </p>
      </section>

      <section>
        <h6 class="mb-5 mt-5">2. HOW WE USE YOUR INFORMATION</h6>
        <p class="fw-light">
          Having accurate information about you permits us to provide you with a
          smooth, efficient, and customised experience. We will securely process
          your personal information and use it for administration and operation
          of the Platform, including but not limited to the following purposes
        </p>
        <ul class="fw-light">
          <li>To provide quality services</li>
          <li>To update and maintain the user accounts</li>
          <li>To personalise user experiences and advertising</li>
          <li>To improve future Platform development</li>
          <li>
            To enable third parties to carry out technical and logistical
            functions on our behalf
          </li>
          <li>To notify you about changes to our service</li>
        </ul>
        <p class="fw-light">
          Where you have provided us with consent to use your personal
          information, you have the right to withdraw this consent at any time
          by contacting us directly or following the ‘unsubscribe’ link provided
          in any electronic communications you receive from us.
        </p>
        <p class="fw-light">
          Any personal information that we process will be stored on a secure
          server behind a firewall. We will not retain your personal information
          for longer than is necessary for the processing. Where you have
          registered and provided your consent to receive communications from
          us, then we will retain your personal information for this purpose
          until your consent is withdrawn.
        </p>
      </section>

      <section>
        <h6 class="mb-5 mt-5">3. DISCLOSURE OF YOUR INFORMATION</h6>
        <p class="fw-light">
          We may share information we have collected about you in certain
          situations as follows:
        </p>

        <p>a) Third-Party Service Providers</p>
        <p class="fw-light">
          We may share your information with third parties that perform services
          for us or on our behalf, including payment processing, data analysis,
          email delivery, hosting services, customer service, and marketing
          assistance.
        </p>

        <p>b) Third-Party Advertisers</p>
        <p class="fw-light">
          We may use third-party advertising companies to serve ads when you
          visit the Platform. These companies may use information about your
          visits to our Platform and other websites that are contained in web
          cookies in order to provide advertisements about goods and services
          that may be of interest to you.
        </p>

        <p>c) Marketing Communications</p>
        <p class="fw-light">
          With your informed consent (opt-in) we may share your information with
          third parties for marketing purposes, as permitted by law.
        </p>

        <p>d) By Law or to Protect Rights</p>
        <p class="fw-light">
          If we believe the release of information about you is necessary to
          respond to legal process, to investigate or remedy potential
          violations of our policies, or to protect the rights, property, and
          safety of others, we may share your information as permitted or
          required by any applicable law, rule, or regulation. This includes
          exchanging information with other entities for fraud protection and
          credit risk reduction.
        </p>
        <p class="fw-light">
          Under no circumstances do we sell your personal information. We
          disclose only that personal information that is necessary for the
          third party to deliver their specific service and we take all
          reasonable measures to ensure they keep your information fully secure
          and not use it for any purpose other than the one under which it was
          disclosed.
        </p>
      </section>

      <section>
        <h6 class="mb-5 mt-5">4. USE OF COOKIES</h6>
        <p class="fw-light">
          We may use cookies, web beacons, tracking pixels, Google analytics and
          other tracking technologies on our Platform to help customise our
          services and improve your user experience. When you access our
          Platform, your personal information is not collected through the use
          of tracking technology. Cookies are small files that a site or its
          service provider transfers to your computer’s hard drive through your
          web browser. Most browsers are set to accept cookies by default. You
          can remove or reject cookies, but be aware that such action could
          affect the availability and functionality of the Platform. You may not
          decline web beacons. However, they can be rendered ineffective by
          declining all cookies or by modifying your web browser’s settings to
          notify you each time a cookie is tendered, permitting you to accept or
          decline cookies on an individual basis. Additionally, REALITYESCORTS
          may occasionally provide external links and/or advertisements
          submitted by or directed towards third party websites and services.
          These third-party websites may use cookies or similar tracking
          technology to help manage and optimise your online experience with us.
          REALITYESCORTS accepts no responsibility or liability for the content
          and activities of these third-party websites. If you decide to
          purchase any goods and/or services from any such third-party website,
          you agree to do so at your own risk and this Privacy Policy remains no
          longer applicable.
        </p>
      </section>

      <section>
        <h6 class="mb-5 mt-5">5. INFORMATION STORAGE AND SECURITY</h6>
        <p class="fw-light">
          The personal information which we hold will be held securely in
          accordance with our internal security policy and the appropriate data
          protection laws as and where applicable. REALITYESCORTS has
          implemented generally accepted standards of technology and operational
          security in order to protect personal information from loss, misuse,
          or unauthorised alteration or destruction. Unfortunately, no method of
          transmission over the Internet, or method of electronic storage, is
          100% secure. Whilst we strive to use all reasonable efforts to protect
          your personal information, we cannot guarantee its absolute security.
          We will notify you promptly in the event of any breach of your
          personal information which might expose you to serious risk. We will
          never collect sensitive information about you without your explicit
          and informed consent.
        </p>
      </section>

      <section>
        <h6 class="mb-5 mt-5">6. INFORMATION RETENTION AND TRANSFER</h6>
        <p class="fw-light">
          REALITYESCORTS may retain your personal information for as long as it
          is necessary to fulfil the purpose for which it was collected, or as
          required or permitted by applicable laws. We will cease to retain your
          personal information, or remove the means by which the information can
          be associated with you, as soon as it is reasonable to assume that
          such retention no longer serves the purpose for which it was
          collected, and is no longer necessary for legal or business purposes.
          All third parties are carefully screened before transfer of any
          personal information, so we can ensure that there are adequate
          controls in place and, where relevant, that such third parties are
          GDPR compliant. In case any of any international transfer of personal
          information, REALITYESCORTS takes all reasonable steps to ensure that
          your personal information continues to receive a standard of
          protection that is at least comparable to that provided under the
          applicable laws including GDPR.
        </p>
      </section>

      <section>
        <h6 class="mb-5 mt-5">7. YOUR RIGHTS UNDER GDPR</h6>
        <p class="fw-light">
          Under the EU General Data Protection Regulation (GDPR), you possess a
          number of rights in relation to your personal information. These
          rights include
        </p>
        <ul class="fw-light">
          <li>the right to be informed;</li>
          <li>the right of access;</li>
          <li>the right to rectification;</li>
          <li>the right to erasure;</li>
          <li>the right to restrict processing;</li>
          <li>the right to data portability;</li>
          <li>the right to object;</li>
          <li>
            and rights in relation to automated decision making and profiling.
          </li>
        </ul>
        <p class="fw-light">
          We are committed to upholding these rights at all times. If you wish
          to exercise any of these rights, please send us a request that (a)
          provides sufficient detail to allow us to verify that you are the
          person about whom we have collected personal information, and (b)
          describes your request in sufficient detail to allow us to understand,
          evaluate, and respond to it as soon as possible. Please note it will
          not be possible for us to respond to requests that do not adequately
          meet the above criteria.
        </p>

      </section>

      <section>
        <h6 class="mb-5 mt-5 text-uppercase">8. Governing Law and Jurisdiction</h6>
        <p class="fw-light">
          Governing Law. Estonia law, including its statutes of limitations
          governs all matters arising out of or relating to our Websites or this
          agreement without giving effect to any conflicts of law principles.
        </p>
      </section>

      <section>
        <h6 class="mb-5 mt-5">9. CONTACT US</h6>
        <p class="fw-light">
          REALITYESCORTS has a “Data Protection Officer” who is responsible for
          matters relating to privacy and data protection. This Data Protection
          Officer can be reached at the following address:
        </p>
        <ul class="fw-light">
          <li>the right to be informed;</li>
          <li>the right of access;</li>
          <li>the right to rectification;</li>
          <li>the right to erasure;</li>
          <li>the right to restrict processing;</li>
          <li>the right to data portability;</li>
          <li>the right to object;</li>
          <li>
            and rights in relation to automated decision making and profiling.
          </li>
        </ul>
        <p class="fw-light">
          We are committed to upholding these rights at all times. If you wish
          to exercise any of these rights, please send us a request that (a)
          provides sufficient detail to allow us to verify that you are the
          person about whom we have collected personal information, and (b)
          describes your request in sufficient detail to allow us to understand,
          evaluate, and respond to it as soon as possible. Please note it will
          not be possible for us to respond to requests that do not adequately
          meet the above criteria.
        </p>

        Company information:
        <p class="fw-light">
          M2XdirectoryADS OÜ registration code (16315970)
          <br />
          Address: Harju maakond, Tallinn, Nõmme linnaosa, Kukermiidi tn 5,
          11216, Estonia.
          <br />
          Email: contact@realityescorts.com
        </p>

        <p class="fw-light">
          If you have any further questions, comments or requests regarding this
          Privacy Policy, please email us at contact@realityescorts.com.
        </p>
      </section>

      <section class="mt-10">
        <i class="fw-light"
        >This Privacy Policy was last updated in January 2022.</i
        >
      </section>
    </div>
  </container>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: 'Reality Escorts',
      meta: [{
        name: 'description', content: 'Find the best independent and agency escorts at Reality Escorts.',
      }],
    };
  },
};
</script>
