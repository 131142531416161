<template>
  <header>
    <div
      class="
        container
        px-16
        py-7
        d-flex
        align-center
        justify-space-between
        md-justify-center
      "
    >
      <a-dropdown
        v-model="menuIsOpen"
        class="re-header-dropdown d-none lg-d-block"
        placement="bottomCenter"
        :trigger="['click']"
        :getPopupContainer="(trigger) => trigger.parentNode"
      >
        <en-burger-menu :isOpen="menuIsOpen" class="mr-15" />
        <div slot="overlay" class="pb-10 pt-5">
          <div class="container">
            <div class="d-flex justify-content-center">
              <a-dropdown v-if="isAuthenticated" :trigger="['click']">
                <span class="text-center mb-5 fw-300 pointer">
                  <a-avatar
                    :src="profilePhoto"
                    size="small"
                    style="background-color: #d4145a"
                  >
                    {{ user.name[0] }}
                  </a-avatar>
                  <span class="ml-2">Welcome,</span>
                  <span class="pink-color-1 fw-600 ml-1">{{ user.name }}</span
                  >!
                  <a-icon type="down" @click="(e) => e.preventDefault()" />
                </span>
                <a-menu slot="overlay">
                  <a-menu-item key="0" @click="logout"> Logout </a-menu-item>
                </a-menu>
              </a-dropdown>
            </div>
            <div class="d-flex justify-content-center">
              <router-link
                class="
                  ant-btn ant-btn-primary
                  mr-4
                  white-color
                  text-decoration-none
                "
                to="/post-ad"
                @click.native="postAd"
              >
                Post your Ad
              </router-link>

              <router-link
                v-if="isAuthenticated"
                class="ant-btn pink-color-1 text-decoration-none"
                to="/my-account"
                @click.native="myAccount"
              >
                My account
              </router-link>
              <a-button v-else @click="login"> Login </a-button>
            </div>
          </div>
        </div>
      </a-dropdown>
      <router-link to="/">
        <img class="en-logo pointer" :src="logo" />
      </router-link>

      <div class="ml-15 lg-d-none">
        <router-link
          class="ant-btn ant-btn-primary mr-4 white-color text-decoration-none"
          to="/post-ad"
          @click.native="postAd"
        >
          Post your Ad
        </router-link>
        <router-link
          v-if="isAuthenticated"
          class="ant-btn pink-color-1 text-decoration-none"
          to="/my-account"
          @click.native="myAccount"
        >
          My account
        </router-link>
        <a-button v-else @click="login"> Login </a-button>
        <a-dropdown v-if="isAuthenticated" :trigger="['click']">
          <span class="text-center ml-5 fw-300 pointer">
            <span class="position-relative">
              <img v-if="user.isVerified" class="verified-icon ws-15 position-absolute" />
              <a-avatar
                :src="profilePhoto"
                size="small"
                style="background-color: #d4145a"
              >{{ user.name[0] }}</a-avatar
              >
            </span>
            <span class="ml-2">Welcome,</span>
            <span class="pink-color-1 fw-600 ml-1">{{ user.name }}</span
            >!
            <a-icon type="down" @click="(e) => e.preventDefault()" />
          </span>
          <a-menu slot="overlay">
            <a-menu-item key="0" @click="logout"> Logout </a-menu-item>
          </a-menu>
        </a-dropdown>
      </div>
      <div class="ml-15 d-none lg-d-block" />
    </div>
  </header>
</template>

<script>
import { computed, ref } from 'vue';
import useGlobal from '@/composables/useGlobal';
import EnBurgerMenu from '@/components/molecules/EnBurgerMenu.vue';
import useUser from '@/composables/useUser';
import lightLogo from '@/assets/images/svg/reality-escorts-light.svg';
import darkLogo from '@/assets/images/svg/reality-escorts-dark.svg';
import verified from '@/assets/images/svg/blue-verify.svg';

export default {
  components: {
    EnBurgerMenu,
  },
  setup() {
    const { goTo } = useGlobal();
    const {
      user, isAuthenticated, logout, isLightMode,
    } = useUser();

    const menuIsOpen = ref(false);

    const postAd = () => {
      menuIsOpen.value = false;
    };

    const login = () => {
      goTo('/login');
      menuIsOpen.value = false;
    };

    const myAccount = () => {
      menuIsOpen.value = false;
    };

    const profilePhoto = computed(
      () => JSON.parse(localStorage.getItem('user')).photo,
    );

    const logo = computed(() => (isLightMode.value ? lightLogo : darkLogo));

    return {
      menuIsOpen,
      goTo,
      postAd,
      login,
      user,
      isAuthenticated,
      logout,
      myAccount,
      profilePhoto,
      logo,
      verified,
    };
  },
};
</script>

<style lang="scss">
header {
  z-index: 1;
  position: relative;
  background-color: #212121;
  box-shadow: 0px 15px 50px -15px #fff3;
  .en-logo {
    height: 20px;
  }

  .ant-dropdown {
    width: 100% !important;
    top: 85px !important;

    &-content {
      background-color: #212121;
      box-shadow: 0px 15px 25px -15px #fff3;
    }
  }
}

.verified-icon {
  top: 14px;
  z-index: 2;
  right: -6px;
}

.light-mode {
  header {
    background-color: #fff;
    color: #212121;

    .ant-dropdown {
      z-index: 2;
      &-content {
        background-color: #fff;
        box-shadow: 0px 15px 25px -15px #fff3;
      }
    }
  }
}
</style>
