<template>
  <a-card class="mt-7">
    <h3 v-if="!loading && !isAdsLoading" class="mb-0">{{locationTitle}}</h3>
    <en-skeleton
      v-else
      type="rect"
      rounded
      wave-color="rgba(255, 255, 255, 0.3)"
      :width="350"
      :height="32"
      :radius="25"
      animation="wave"
    />
    <a-row :gutter="[40, 25]" class="mt-6">
      <template v-if="!loading && !isAdsLoading">
        <a-col
          :md="12"
          :sm="24"
          class="location pointer"
          v-for="location in locations"
          :key="location.id"
          @click="$emit('click:onLocationSelect', {
            url: location.urlName
          })"
        >
          <div  class="d-flex justify-content-between">
            <div>{{ getLocationName(location) }}</div>
            <a>
              {{ location.adQuant }}
            </a>
          </div>
        </a-col>
      </template>
      <template v-else>
        <a-col :md="12" :sm="24" v-for="item in 8" :key="item">
          <div class="d-flex justify-content-between">
            <div>
              <en-skeleton
                type="rect"
                rounded
                wave-color="rgba(255, 255, 255, 0.3)"
                :width="300"
                :height="16"
                :radius="50"
                animation="wave"
              />
            </div>
            <div>
              <en-skeleton
                type="rect"
                rounded
                wave-color="rgba(255, 255, 255, 0.3)"
                :width="30"
                :height="16"
                :radius="50"
                animation="wave"
              />
            </div>
          </div>
        </a-col>
      </template>
    </a-row>
  </a-card>
</template>

<script>
import { ref, watch, computed } from 'vue';
import LocationService from '@/services/LocationService';
import router from '@/router/router';

export default {
  props: {
    locationType: { type: String },
    locationInfo: { type: Object },
    params: { type: Object },
    isAdsLoading: { type: Boolean },
  },
  setup(props) {
    const getLocationRequest = () => {
      const {
        region, subRegion, city, district,
      } = router.history.current.params;
      if (district) return 'fetchCity';
      if (city) return 'fetchCity';
      if (subRegion) return 'fetchSubRegion';
      if (region) return 'fetchRegion';
      return 'fetchCountry';
    };

    const getLocationUrlName = () => {
      const {
        country, region, subRegion, city, district,
      } = router.history.current.params;
      if (district) return city;
      if (city) return city;
      if (subRegion) return subRegion;
      if (region) return region;
      return country;
    };

    const locations = ref([]);
    const loading = ref(false);
    const fetchLocation = async () => {
      try {
        loading.value = true;
        const params = {
          gold: props.params.plan === 'gold' ? true : undefined,
          isNew: props.params.isnew,
          hasAd: true,
          name: getLocationUrlName(),
        };
        const { data } = await LocationService[getLocationRequest()](params);
        setTimeout(() => {
          locations.value = data;
        });
      } catch (error) {
        console.log(error);
      } finally {
        loading.value = false;
      }
    };
    fetchLocation();

    const locationNames = computed(() => ({
      country: props.locationInfo.country.name,
      region: props.locationInfo.region.name,
      subregion: props.locationInfo.sub_region.name,
      city: props.locationInfo.city.name,
      district: props.locationInfo.city.name,
    }));

    const titleLabels = {
      country: 'regions',
      region: 'subregions',
      subregion: 'cities',
      city: 'districts',
      district: 'districts',
    };

    const locationTitle = computed(() => (locations.value.length
      ? `${locationNames.value[props.locationType]} ${titleLabels[props.locationType]}`
      : `No ${locationNames.value[props.locationType]} ${titleLabels[props.locationType]} found`));

    watch(() => ({ locationType: props.locationType, params: props.params }), () => {
      fetchLocation();
    });

    const getLocationName = (location) => (location.urlName === 'london-region' ? 'London' : location.name);

    return {
      locations,
      loading,
      locationTitle,
      getLocationName,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.location {
  &:hover {
    background-color: $select-bg-color;
  }
}
</style>
