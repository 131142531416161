<template>
  <container class="d-flex justify-content-center my-15">
    <div class="w-100">
      <div class="w-mobile w-100 mx-auto">
        <h1 class="fs-20 mb-11 d-flex align-items-center">
          My Account

          <a-popover
            v-if="!isLoading && !isActive"
            title="Last step required"
            placement="bottom"
          >
            <template slot="content">
              <p style="width: 300px">
                You need to confirm your email address. Please check your emails
                and click on the link to activate your account.
              </p>
              <a-button
                type="primary"
                class="mt-2 mb-3"
                :loading="isResendConfirmationLoading"
                size="small"
                @click="resendConfirmationLink"
              >
                Resend confirmation link
              </a-button>
            </template>
            <a-tag class="ml-3" color="#ff0000">
              E-mail activation pending
            </a-tag>
          </a-popover>
        </h1>
        <a-tabs :active-key="selectedTab" @change="onTabChange">
          <a-tab-pane key="1" tab="My ads" />
          <a-tab-pane key="2" tab="Profile" />
          <a-tab-pane key="3" :disabled="isVerified || isLoading || isInAnalysis">
            <template #tab>
              <div class="d-flex align-items-center">
                Verification
                <span
                  class="fs-10 ml-1"
                  :class="isVerified || isLoading ? 'gray-color-1' : 'pink-color-1'">
                  ({{verificationLabel}})
                </span>
              </div>
            </template>
          </a-tab-pane>
        </a-tabs>
      </div>
      <my-ads v-if="selectedTab === '1'" />
      <profile class="w-mobile w-100 mx-auto" v-if="selectedTab === '2'" />
      <verification-tab
        class="w-mobile w-100 mx-auto"
        v-if="selectedTab === '3'"
        @verificationSent="onVerificationSent"
      />
    </div>
  </container>
</template>

<script>
import { ref, computed } from 'vue';
import AuthService from '@/services/AuthService';
import useUser from '@/composables/useUser';
import { message } from 'ant-design-vue';
import MyAds from './MyAds.vue';
import Profile from './Profile.vue';
import VerificationTab from './VerificationTab.vue';

export default {
  metaInfo() {
    return {
      title: 'Reality Escorts',
      meta: [{
        name: 'description', content: 'Find the best independent and agency escorts at Reality Escorts.',
      }],
    };
  },
  components: { MyAds, Profile, VerificationTab },
  setup() {
    const { user } = useUser();
    const selectedTab = ref('1');
    const onTabChange = (key) => {
      selectedTab.value = key;
    };

    const currentStatus = ref('');
    const isLoading = ref(false);
    const isInAnalysis = ref(false);
    const checkStatus = async () => {
      try {
        isLoading.value = true;
        const { data } = await AuthService.checkStatus();
        currentStatus.value = data.status;
        user.value.isVerified = Boolean(data.verified);
        localStorage.setItem('user', JSON.stringify({ ...user.value, verified: data.verified }));
        isInAnalysis.value = data.verificationProcessStatus;
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };
    checkStatus();

    const isActive = computed(() => currentStatus.value === 'Active');

    const isResendConfirmationLoading = ref(false);
    const resendConfirmationLink = async () => {
      try {
        isResendConfirmationLoading.value = true;
        await AuthService.resendConfirmationLink();
        message.success('Activation email sent. Please check your inbox.');
      } catch (error) {
        message.error(error.response.data.name ? error.response.data.name : error.response.data);
        checkStatus();
      } finally {
        isResendConfirmationLoading.value = false;
      }
    };

    const isVerified = computed(() => user.value.isVerified);

    const verificationLabel = computed(() => {
      if (isLoading.value) return 'checking';
      if (isVerified.value) return 'verified';
      if (isInAnalysis.value) return 'processing';
      return 'pending';
    });

    const onVerificationSent = () => {
      selectedTab.value = '2';
      checkStatus();
    };

    return {
      onTabChange,
      selectedTab,
      isActive,
      isLoading,
      resendConfirmationLink,
      isResendConfirmationLoading,
      isVerified,
      verificationLabel,
      isInAnalysis,
      onVerificationSent,
    };
  },
};
</script>
