<template>
  <container class="d-flex justify-content-center my-15">
    <div class="login w-100">
      <a-button
        type="primary"
        block
        class="mt-14 mb-8"
        @click="goTo('/post-ad')"
      >
        Post your Ad
      </a-button>

      <a-form-model
        ref="loginForm"
        :model="form"
        :rules="rules"
        @submit="submit"
        layout="vertical"
      >
        <a-form-model-item :colon="false" prop="email">
          <div class="d-flex align-center lh-0" slot="label">
            <a-icon type="pink-mail" class="mr-2" />E-mail
          </div>
          <a-input
            class="input-shadow"
            v-model="form.email"
            placeholder="Enter your email here"
          />
        </a-form-model-item>
        <a-form-model-item :colon="false" prop="password">
          <div class="d-flex align-center lh-0" slot="label">
            <a-icon type="pink-lock" class="mr-2" />Password
          </div>
          <a-input
            class="input-shadow"
            type="password"
            v-model="form.password"
            placeholder="Enter your password here"
          />
        </a-form-model-item>
        <a-button
          type="secondary"
          html-type="submit"
          class="mb-5 mt-5"
          block
          :loading="loading"
        >
          Enter
        </a-button>
      </a-form-model>

      <div class="d-flex">
        <a-button type="link" block @click="goTo('/recover')">
          I forgot my password
        </a-button>
        <a-button type="primary" block @click="goTo('/register')">
          Register
        </a-button>
      </div>
    </div>
  </container>
</template>

<script>
import { reactive, ref } from 'vue';
import { message } from 'ant-design-vue';
import useGlobal from '@/composables/useGlobal';
import useUser from '@/composables/useUser';
import AuthService from '@/services/AuthService';

export default {
  metaInfo() {
    return {
      title: 'Reality Escorts',
      meta: [{
        name: 'description', content: 'Find the best independent and agency escorts at Reality Escorts.',
      }],
    };
  },
  setup() {
    const { goTo } = useGlobal();
    const { setUser } = useUser();

    const form = reactive({
      email: '',
      password: '',
    });

    const emailValidation = (rule, value, callback) => {
      const re = /\S+@\S+\.\S+/;
      if (!re.test(value)) callback(new Error('Please input a valid e-mail'));
      else callback();
    };

    const passwordValidation = (rule, value, callback) => {
      if (value.length < 6) {
        callback(new Error('Please enter at least 6 characters'));
      }
      callback();
    };

    const rules = ref({
      email: [{ validator: emailValidation, trigger: 'change' }],
      password: [{ validator: passwordValidation, trigger: 'change' }],
    });

    const loginForm = ref();

    const loading = ref(false);
    const submit = (e) => {
      e.preventDefault();
      loginForm.value.validate(async (valid) => {
        if (valid) {
          try {
            loading.value = true;
            const { data } = await AuthService.login(form);
            setUser(data);
            localStorage.setItem('user', JSON.stringify(data));
            message.success('Welcome!', 4);
            goTo('/my-account');
          } catch (error) {
            message.error('Please, check the credentials are right.', 4);
          } finally {
            loading.value = false;
          }
        }
      });
    };

    return {
      form,
      goTo,
      rules,
      submit,
      loading,
      loginForm,
    };
  },
};
</script>

<style lang="scss">
.login {
  max-width: 489px;
}
</style>
