<template>
  <footer class="gray-color-1 fw-medium">
    <router-link class="gray-color-1" to="/faq">FAQ</router-link> •
    <router-link class="gray-color-1" to="/contact-us">Contact Us</router-link>
    • <router-link class="gray-color-1" to="/privacy">Privacy</router-link> •
    <router-link class="gray-color-1" to="/terms">Terms</router-link>
    <br />
    <!-- <router-link class="gray-color-1" to="/locations">Locations</router-link> •
    <router-link class="gray-color-1" to="/language">Language</router-link> -->
    <div class="d-flex align-items-center justify-content-center my-4">
      <img class="visa-logo" :src="visaLogo" />
      <img class="master-card-logo pointer" :src="masterCardLogo" />
    </div>
    <div class="mb-2 p-1">
      Harju maakond, Tallinn, Nõmme linnaosa, Kukermiidi tn 5, 11216, Estonia.
    </div>
    <div class="pb-12">M2XdirectoryADS OÜ (16315970) ©{{year}} REALITYESCORTS</div>
  </footer>
</template>

<script>
import { computed } from 'vue';
import useUser from '@/composables/useUser';
import visaLightLogo from '@/assets/images/svg/visa-light.svg';
import visaDarkLogo from '@/assets/images/svg/visa-dark.svg';
import masterCardLightLogo from '@/assets/images/svg/master-card-light.svg';
import masterCardDarkLogo from '@/assets/images/svg/master-card-dark.svg';

export default {
  setup() {
    const { isLightMode } = useUser();
    const visaLogo = computed(() => (
      isLightMode.value ? visaLightLogo : visaDarkLogo
    ));
    const masterCardLogo = computed(() => (
      isLightMode.value ? masterCardLightLogo : masterCardDarkLogo
    ));

    const year = computed(() => new Date().getFullYear());

    return {
      visaLogo,
      masterCardLogo,
      year,
    };
  },
};
</script>

<style lang="scss">
footer {
  text-align: center;

  .visa-logo {
    height: 65px;
  }

  .master-card-logo {
    height: 40px;
  }
}
</style>
