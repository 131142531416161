<template>
  <container class="d-flex justify-content-center my-15">
    <div class="w-mobile">
      <h1 class="fs-20 mb-11">REALITYESCORTS TERMS AND CONDITIONS</h1>

      <section>
        <p class="fw-light">
          The website
          <a href="https://www.realityescorts.com" target="_blank"
          >www.realityescorts.com</a
          >
          and/or any mobile application that facilitates access to the website
          (together, "REALITYESCORTS", “we”, “us” or “the Platform”) is owned
          and operated by M2XdirectoryADS OÜ, a company duly registered under
          the laws of Estonia with Registry Code 16315970 and registered office
          at Harju maakond, Tallinn, Nõmme linnaosa, Kukermiidi tn 5, 11216.
        </p>
        <p class="fw-light">
          PLEASE READ THIS DOCUMENT CAREFULLY BEFORE ACCESSING OR USING THE
          REALITYESCORTS PLATFORM. BY ACCESSING OR USING THE PLATFORM, YOU
          CONFIRM YOU ARE ABOVE 18 YEARS OF AGE AND AGREE TO BE BOUND BY OUR
          TERMS AND CONDITIONS SET FORTH BELOW. IF YOU DO NOT WISH TO BE BOUND
          BY THESE TERMS, YOU ARE ADVISED NOT TO CONTINUE USING THE PLATFORM ANY
          LONGER.
        </p>
      </section>

      <section>
        <h6 class="mb-5 mt-5">1. REGISTRATION OF USER ACCOUNT</h6>
        <p class="fw-light">
          REALITYESCORTS is an online classified advertising website proving
          member-only advertisement services. In order to successfully use the
          REALITYESCORTS Platform, you must be at least 18 years of age.
          Availability of all REALITYESCORTS services is conditional on the
          creation of a registered user account with us. You must not use any
          other person's account to access the REALITYESCORTS Platform, or allow
          anyone else to access yours. You are responsible for maintaining the
          confidentiality of your account login information and are fully
          responsible for all activities that occur under your Account. You
          agree to notify us immediately of any unauthorised use, or suspected
          unauthorised use of your account or any other breach of security.
          REALITYESCORTS cannot and will not be liable for any loss or damage
          arising from your failure to comply with the above requirements.
        </p>
      </section>

      <section>
        <h6 class="mb-5 mt-5">2. CONTENT DISCLAIMER</h6>
        <p class="fw-light">
          REALITYESCORTS Platform allows its registered users to post, link,
          share, publish and otherwise make available and advertise certain
          personal services by uploading textual and graphical content
          (“Content”) in or through the Platform. You are solely responsible for
          such Content and hereby ensure that it does not offend any third-party
          rights, including but not limited to any applicable intellectual
          property rights, privacy rights, publicity rights, contractual rights
          and does not in any way be considered against applicable laws.
          REALITYESCORTS regularly scan all Content for
          quality control purposes, including monitoring of live chats, live
          camera interactions, and usage of certain keywords that may refer to
          human trafficking, sex trafficking, exploitation of children,
          non-consensual sex, prostitution and any other potentially illegal
          activities. Any Content deemed to include any of the above mentioned
          prohibited activities will be removed immediately and the user banned
          from using the Platform. (a) the availability or non-availability of
          any advertised Content, (b) the willingness or ability of any user to
          provide the advertised Content, (c) whether one will find the
          advertised Content relevant, useful, accurate or satisfactory, or (d)
          whether the advertised Content will be relevant to one’s specific
          needs and circumstances. REALITYESCORTS reserves the right, but are
          not obligated to, delete, modify, edit or update any Content that we,
          in our sole discretion, deem to be in violation of these Terms and
          Conditions or deem to be otherwise unacceptable. Notwithstanding the
          above, by accepting these Terms and Conditions you grant us the right
          and license to use, modify, display and distribute such Content by and
          through the REALITYESCORTS Platform and make it available to other
          users, who may also use such Content subject to these Terms and
          Conditions.
        </p>
      </section>

      <section>
        <h6 class="mb-5 mt-5">3. ACCEPTABLE USE OF THE PLATFORM</h6>
        <p class="fw-light">
          In order to maximise your user experience in using REALITYESCORTS, and
          avoid, eliminate or minimise disruptive activities, we kindly ask you
          to maintain the following:
        </p>

        <ul class="fw-light">
          <li>
            do not use REALITYESCORTS in any way or take any action that may
            undermine, disrupt, damage or manipulate its working functionality,
            performance, availability or accessibility;
          </li>
          <li>
            do not copy, decompile, reverse engineer, disassemble, attempt to
            derive the source code of, modify or create derivative works of
            REALITYESCORTS, or any updates or any part thereof;
          </li>
          <li>
            do not use REALITYESCORTS in any manner that is unlawful, illegal,
            fraudulent or harmful, or in connection with any unlawful, illegal,
            fraudulent or harmful purpose or activity;
          </li>
          <li>
            do not impersonate any person or entity, misrepresent any
            affiliation with another person, entity, or association, use false
            IP addresses or headers, or otherwise conceal your identity for any
            purpose;
          </li>
          <li>
            do not violate, plagiarise, or infringe on the rights of third
            parties, including without limitation copyright, trademark, trade
            secret, privacy, publicity or other proprietary rights;
          </li>
          <li>
            do not use data collected from REALITYESCORTS for any direct
            marketing activity (including without limitation email marketing,
            SMS marketing, telemarketing and direct mailing).
          </li>
        </ul>
      </section>

      <section>
        <h6 class="mb-5 mt-5">4. FEEDBACK AND REVIEWS</h6>
        <p class="fw-light">
          You are welcome to submit honest feedback, ratings, reviews and
          comments about any advertised Content published by and through the
          REALITYESCORTS Platform. REALITYESCORTS does not screen, edit, publish
          or review such feedback and reviews prior to their appearance on the
          Platform and they do not reflect any official statement, view or
          opinion of REALITYESCORTS in any manner whatsoever. However,
          REALITYESCORTS reserves the right to monitor all feedback and to
          remove any feedback which it considers in its absolute discretion to
          be inappropriate, offensive, defamatory or otherwise in breach of
          these Terms and Conditions.
        </p>
      </section>

      <section>
        <h6 class="mb-5 mt-5">5. SUSPENSION OF SERVICE</h6>
        <p class="fw-light">
          REALITYESCORTS reserves the right to fully or partially suspend,
          discontinue, or cease providing, at any time and from time to time,
          temporarily or permanently, any of the Services with or without
          notice, and shall have no liability or responsibility to you in any
          manner whatsoever if it chooses to do so. All provisions of these
          Terms and Conditions which by their nature should survive such
          suspension or termination shall survive, including, without
          limitation, intellectual property ownership, warranty disclaimers,
          indemnity and limitations of liability.
        </p>
      </section>

      <section>
        <h6 class="mb-5 mt-5">6. PAYMENT AND REFUNDS</h6>
        <p class="fw-light">
          Publication of Content on the REALITYESCORTS Platform is conditional
          upon confirmation of user age and payment. Once both have been
          approved by us, Content is displayed on the Platform for a specified
          period of time and is auto-disabled after the expiry of the said
          period. Deactivation of Content is also possible from the user’s end,
          although no refund will be made available in such a case, irrespective
          of the period of availability. REALITYESCORTS accepts online payment
          through international debit and credit cards (Visa and Mastercard). It
          is your responsibility to ensure that a valid card number and billing
          address is provided for us to successfully process your payment. If
          your card is issued in a foreign currency other than £Euro, foreign
          exchange rates may be applicable as per your Bank’s rules and
          regulations. In case you have any question about the method of
          payment, feel free to reach out to our customer support before making
          the payment. We do not store any financial information including card
          details from our customers and accept no liability for any error in
          any third-party payment gateway under any circumstances whatsoever.
        </p>
      </section>

      <section>
        <h6 class="mb-5 mt-5">7. THIRD PARTY LINKS AND ADVERTISEMENTS</h6>
        <p class="fw-light">
          REALITYESCORTS may occasionally provide external links and
          advertisements submitted by or directed towards third party websites
          and services. Unless expressly stated otherwise, such advertisements
          do not signify that REALITYESCORTS endorses and/or is associated with
          such third-party websites and services in any manner including, but
          not limited to, the content, accuracy, quality, nature,
          appropriateness, decency, functionality, performance, reliability,
          completeness, timeliness, validity, safety, legality or any other
          aspect thereof. We accept no responsibility for third party
          advertisements contained within REALITYESCORTS. If you agree to
          purchase goods and/or services from any third party who advertises in
          this Platform, you do so at your own risk and these Terms and
          Conditions remains no longer applicable.
        </p>
      </section>

      <section>
        <h6 class="mb-5 mt-5">8. INTELLECTUAL PROPERTY AND COPYRIGHT POLICY</h6>
        <p class="fw-light">
          With the exception of any user-generated Content containing personal
          information, all information and content displayed in the Platform
          including text, graphics, designs, photographs, videos, typefaces,
          look and feel, format, and the like is owned and/or licensed by
          REALITYESCORTS, and are protected by international laws on patents,
          copyrights, trade secrets, trade names, trademarks (whether registered
          or unregistered), domain names and other applicable rights. By
          agreeing to these Terms and Conditions you understand and agree to not
          modify, copy, reproduce, republish, upload, post, transmit, or
          distribute, in any manner, any material on or from REALITYESCORTS,
          including text, graphics, logos and designs for any purpose whatsoever
          or allow any third party to do so.
        </p>
        <p class="fw-light">
          Please notify us immediately if you believe any Content found on or
          linked to by this Platform violates your intellectual property rights.
          We value the intellectual property rights of all copyright holders and
          will process and investigate notices of alleged infringement and
          promise to take appropriate actions under applicable intellectual
          property laws with respect to any alleged or actual infringement.
        </p>
        <p class="fw-light">
          For REALITYESCORTS to effectively process your complaint, a
          notification of the claimed copyright infringement should be emailed
          to REALITYESCORTS’s Copyright Agent at: contact@realityescorts.com
          with the subject line “Takedown Request for Copyright Infringement”
          and containing the following information:
        </p>

        <p class="fw-light mb-1">
          a) a description of the copyrighted work or other intellectual
          property that you claim has been infringed;
        </p>
        <p class="fw-light mb-1">
          b) an electronic or physical signature of the person authorised to act
          on behalf of the owner of the copyright or other intellectual property
          interest;
        </p>
        <p class="fw-light mb-1">
          c) a detailed description of where the material that you claim is
          infringing is located on the Platform, so as to assist us with prompt
          identification;
        </p>
        <p class="fw-light mb-1">
          d) your postal address, telephone number, and email address;
        </p>
        <p class="fw-light mb-1">
          e) a statement by you that you have a good faith belief that the
          disputed use is not authorised by the copyright or intellectual
          property owner, its agent, or the law;
        </p>
        <p class="fw-light mb-1">
          f) a statement by you, made under penalty of perjury, that the above
          information in your Notice is accurate and that you are the copyright
          or intellectual property owner or authorised to act on the copyright
          or intellectual property owner’s behalf.
        </p>

        <p class="fw-light">
          If you believe that we have removed any of your Content that was not
          infringing, or that you have the authorisation from the copyright
          owner, the copyright owner’s agent, or pursuant to the law, to upload
          and use the material in or through your user account, you may send a
          written counter-notice containing the following information to the
          Copyright Agent:
        </p>

        <p class="fw-light mb-1">
          a) identification of the Content that has been removed or to which
          access has been disabled and the location at which the Content
          appeared before it was removed or disabled;
        </p>
        <p class="fw-light mb-1">b) your physical or electronic signature;</p>
        <p class="fw-light mb-1">
          c) a statement that you have a good faith belief that the Content was
          removed or disabled as a result of mistake or a misidentification of
          the Content; and
        </p>
        <p class="fw-light">
          d) your name, postal address, telephone number, and email address, a
          statement that you consent to the jurisdiction of the court(s) in
          Estonia and a statement that you will accept service of process from
          the person who provided notification of the alleged infringement.
        </p>

        <p class="fw-light">
          If a counter-notice is received by our Copyright Agent, REALITYESCORTS
          will send a copy of the counter-notice to the original complaining
          party informing that person that it may replace the removed Content or
          cease disabling it in ten (10) business days. Unless the copyright
          owner files an action seeking a court order against the Content
          provider or user, the removed Content may be replaced, or access to it
          restored in due time at our sole discretion.
        </p>
      </section>

      <section>
        <h6 class="mb-5 mt-5">9. NO WARRANTY</h6>
        <p class="fw-light">
          By using the REALITYESCORTS Platform, you hereby acknowledge and agree
          that such usage is at your sole risk and responsibility. To the
          maximum extent permitted by applicable law, REALITYESCORTS is provided
          on an “as is” and “as available” basis without warranties of any kind,
          either expressed or implied, including, but not limited to, all
          warranties of quality, accuracy, completeness, reliability, conditions
          of merchantability, fitness for a particular purpose, title or
          non-infringement. We do not warrant that the advertised Content
          offered through REALITYESCORTS will meet your specific requirements,
          that the Platform will be uninterrupted or error-free or free from
          viruses or similar harmful matter or that any defects in Platform will
          be immediately corrected. No oral or written communication offered by
          REALITYESCORTS will create any warranty for this purpose.
        </p>
      </section>

      <section>
        <h6 class="mb-5 mt-5">10. LIMITATION OF LIABILITY</h6>
        <p class="fw-light">
          Under no circumstances will REALITYESCORTS or any of its directors,
          shareholders, employees and agents be liable for any incidental,
          consequential, or indirect damages including, but not limited to,
          business interruption, damages for loss of profit, loss of data, loss
          of goodwill and the like arising out of the use or inability of use of
          the information or services provided on or downloaded from the
          REALITYESCORTS Platform, or any delay in the processing of such
          information or services. For removal of doubt, this includes
          situations where there is any failure or delay in performance of any
          of the obligations under these Terms and Conditions that is caused by
          events outside our reasonable control (force majeure events).
          Notwithstanding the above, nothing under these Terms and Conditions
          will limit or exclude any liabilities that may not be excluded under
          applicable law.
        </p>
      </section>

      <section>
        <h6 class="mb-5 mt-5">11. INDEMNIFICATION</h6>
        <p class="fw-light">
          You agree to indemnify, defend and hold harmless REALITYESCORTS, its
          officers, directors, employees, agents, consultants, representatives
          and agents from and against all losses, damages, suits, judgments,
          costs and expenses (including litigation costs and reasonable
          attorneys' fees) arising out of or in connection with (a) your use of
          or inability to use REALITYESCORTS, (b) your violation of any
          provision of these Terms and Conditions or your violation of any
          rights of a third party, or (c) your violation of any applicable laws,
          rules or regulations. REALITYESCORTS reserves the right, at its own
          cost, to assume the exclusive defense and control of any matter
          otherwise subject to indemnification by you, in which event you will
          offer complete cooperation with REALITYESCORTS in asserting any
          available defenses.
        </p>
      </section>

      <section>
        <h6 class="mb-5 mt-5">12. ELECTRONIC COMMUNICATIONS</h6>
        <p class="fw-light">
          The communications between you and REALITYESCORTS use electronic
          means. For contractual purposes, you (a) consent to receive
          communications from REALITYESCORTS in an electronic form; and (b)
          agree that all terms and conditions, agreements, notices, disclosures,
          and other communications that REALITYESCORTS provides to you
          electronically satisfy any legal requirement that such communications
          would satisfy if it were be in a hardcopy writing. With your informed
          consent (opt-in), such electronic communication may also include
          periodic emails and/or other messages for our internal marketing and
          promotional purposes. We take all reasonable measures to ensure that
          our marketing communications are compliant with the applicable laws
          including GDPR.
        </p>
      </section>

      <section>
        <h6 class="mb-5 mt-5">13. AGE VERIFICATION PROCEDURE</h6>
        <p class="fw-light">
          By entering and/or registering on the Platform the user agrees with
          and accepts the following:
        </p>
        <p class="fw-light">
          13.1. REALITYESCORTS services are only available for users over the
          age of 18 (21 in some regions). Therefore, no sexually explicit
          material is within the easy reach of minors.
        </p>
        <p class="fw-light">
          13.2. Users under the age of 18 (21 in some regions) are not allowed
          to be members of REALITYESCORTS. Under no circumstances does
          REALITYESCORTS present users as children.
        </p>
        <p class="fw-light">
          13.3. All users must fulfil three major requirements:
        </p>
        <div class="ml-4">
          <p class="fw-light">
            13.3.1. Signing of an agreement declaring, namely, the genuineness
            of personal data and age provided;
          </p>
          <p class="fw-light">
            13.3.2. Providing of a scanned copy of her photo ID;
          </p>
          <p class="fw-light">
            13.3.3. Providing of a photo taken of the user, holding her photo ID
            next to her face.
          </p>
        </div>

        <p class="fw-light">
          13.4. The accounts of users are immediately and permanently suspended
          if they violate REALITYESCORTS Terms and Conditions.
        </p>
        <p class="fw-light">
          13.5. REALITYESCORTS reserves the right to apply immediate and
          permanent suspension in case any user name is offensive, refers to
          minors or upon the slightest suspicion of forgery.
        </p>
        <p class="fw-light">
          13.6 Identity verification department periodically conducts additional
          checks of users who are already registered.
        </p>

        <p class="fw-light">
          13.7. The name of any user as indicated in their passport / driver’s
          license may or may not coincide with their username in the
          REALITYESCORT Platform.
        </p>
        <p class="fw-light">
          13.8. Information about any registered user of RELAITYESCORTS that was
          found on other websites / social networks, with a different age,
          username, location or other personal details will not be the
          responsibility of REALITYESCORTS under any circumstances whatsoever.
        </p>
        <p class="fw-light">
          13.9. REALITYESCORTS will always grant full cooperation to legal
          authorities investigating any fraudulent transactions and other
          matters falling under legal jurisdiction as well as responds to
          subpoenas and court orders.
        </p>
      </section>

      <section>
        <h6 class="mb-5 mt-5">14. GENERAL INFORMATION</h6>
        <p class="fw-light">
          These Terms and Conditions shall be governed by and construed in
          accordance with the laws of Estonia, without regard to principles of
          conflict of laws, and all disputes as to the validity, execution,
          performance, interpretation or termination of this Agreement will be
          submitted to the exclusive jurisdiction of the Courts thereof. If any
          provision of these Terms and Conditions is found by a court of
          competent jurisdiction to be invalid, such invalidity will not affect
          the enforceability of any other part or provision of this Agreement.
        </p>

        <p class="fw-light">
          REALITYESCORTS reserves the right, at their sole discretion, to modify
          these Terms and Conditions at any time, and such modifications shall
          be effective immediately upon posting of the same on the Platform. You
          agree to review this document periodically to be aware of such
          modifications and your continued access or use of REALITYESCORTS shall
          be deemed as a conclusive acceptance of such modified Terms and
          Conditions.
        </p>

        <p class="fw-light">
          If you have any further questions, comments or requests regarding
          these Terms and Conditions, please contact us at
          contact@realityescorts.com.
        </p>
      </section>

      <section class="mt-10">
        <i class="fw-light">
          These Terms and Conditions were last updated in January 2022.
        </i>
      </section>

      <section>
        <h6 class="mb-5 mt-5 text-uppercase">
          15. GOVERNING LAW AND JURISDICTION
        </h6>
        <p class="fw-light">
          Governing Law. Estonia law, including its statutes of limitations
          governs all matters arising out of or relating to our Websites or this
          agreement without giving effect to any conflicts of law principles.
        </p>
      </section>
    </div>
  </container>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: 'Reality Escorts',
      meta: [{
        name: 'description', content: 'Find the best independent and agency escorts at Reality Escorts.',
      }],
    };
  },
};
</script>
