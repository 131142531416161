<template>
  <button
    class="toggle-button"
    type="button"
    :class="buttonClass"
    @click="$emit('click:selected')"
  >
    {{ buttonLabel }}
  </button>
</template>

<script>
import { computed } from 'vue';

export default {
  props: {
    selected: { type: Boolean },
  },
  setup(props) {
    const buttonLabel = computed(() => (props.selected ? 'Selected' : 'Click here'));
    const buttonClass = computed(() => (props.selected ? 'selected' : ''));
    return {
      buttonLabel,
      buttonClass,
    };
  },
};
</script>

<style lang="scss" >
@mixin white-circle {
  content: "";
  width: 9px;
  height: 9px;
  display: block;
  background-color: #fff;
  border-radius: 50px;
}

.toggle-button {
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 50px;
  display: flex;
  font-size: 9px;
  line-height: 1;
  color: #fff;
  padding: 3px 6px;
  align-items: center;
  font-weight: 600;

  &.selected {
    background-color: #14d4a0;
    color: #000000;
    &:before {
      display: none;
    }

    &:after {
      @include white-circle;
      margin-left: 10px;
    }
  }

  &:before {
    @include white-circle;
    margin-right: 10px;
  }
}
</style>
