<template>
  <a-modal
    title="Delete ad"
    :visible="value"
    :confirm-loading="isLoading"
    @cancel="$emit('input', false)"
    width="600px"
  >
    Are you sure to delete {{currentAd.name}}?
    <template slot="footer">
      <a-button key="back" @click="$emit('input', false)"> Cancel </a-button>
      <a-button
        key="submit"
        type="primary"
        :loading="isLoading"
        @click="submit"
      >
        Delete
      </a-button>
    </template>
  </a-modal>
</template>

<script>
import { ref } from 'vue';
import { message } from 'ant-design-vue';
import AdService from '@/services/AdService';

export default {
  props: {
    value: { type: Boolean },
    currentAd: { type: Object },
  },
  setup(props, { emit }) {
    const isLoading = ref(false);
    const submit = async () => {
      try {
        isLoading.value = true;
        await AdService.deleteAd(props.currentAd.id);
        message.success('Ad removed!', 4);
        emit('onConfirm');
        emit('input', false);
      } catch (error) {
        message.error(error.message, 4);
        emit('input', false);
      } finally {
        isLoading.value = false;
      }
    };

    return {
      submit,
      isLoading,
    };
  },
};
</script>
