<template>
  <div class="help-item">
    <h2 class="fs-18 fw-normal">Q: {{ title }}</h2>
    <p class="fs-15 gray-color-1 mb-3">Answer:</p>
    <p class="fs-11">{{ response }}</p>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String },
    response: { type: String },
  },
};
</script>
