<template>
  <a-card class="mt-7">
    <a-row :gutter="[24, 42]">
      <a-col :md="12" :sm="24">
        <en-label icon="pink-calendar" iconWidth="15px" class="mb-4">
          <span class="fs-13 fw-medium ml-3">Age</span>
        </en-label>
        <a-input-number v-model="age" class="w-100" :min="18" />
      </a-col>
      <a-col :md="12" :sm="24">
        <en-label icon="pink-eye" iconWidth="colors" class="mb-4">
          <span class="fs-13 fw-medium ml-3">Eye color</span>
        </en-label>
        <a-select v-model="eyes" class="w-100">
          <a-select-option
            v-for="eyeColor in EyesColors"
            :key="eyeColor"
            :value="eyeColor"
          >
            {{ eyeColor }}
          </a-select-option>
        </a-select>
      </a-col>
      <a-col :md="12" :sm="24">
        <en-label icon="pink-face" iconWidth="15px" class="mb-4">
          <span class="fs-13 fw-medium ml-3">Hair</span>
        </en-label>
        <a-select v-model="hair" class="w-100">
          <a-select-option
            v-for="hairColor in HairColors"
            :key="hairColor"
            :value="hairColor"
          >
            {{ hairColor }}
          </a-select-option>
        </a-select>
      </a-col>
      <a-col :md="12" :sm="24">
        <en-label icon="pink-face" iconWidth="15px" class="mb-4">
          <span class="fs-13 fw-medium ml-3">Height (cm)</span>
        </en-label>
        <a-input-number
          v-model="height"
          :max="250"
          :min="50"
          :default-value="150"
          decimalSeparator=","
          class="w-100"
        />
      </a-col>
      <a-col :md="24" :sm="24">
        <en-label icon="pink-globe" iconWidth="15px" class="mb-4">
          <span class="fs-13 fw-medium ml-3">Nationality</span>
        </en-label>
        <a-auto-complete
          v-model="ethnicityid"
          class="w-100"
          :data-source="ethnicities"
          placeholder="Search ethnicity here"
          :filter-option="filterOption"
        />
      </a-col>
      <a-col :md="24" :sm="24">
        <en-label icon="pink-tag" iconWidth="15px" class="mb-4">
          <span class="fs-13 fw-medium ml-3">Tag</span>
        </en-label>
        <a-select v-model="tag" class="en-tag-selector w-100">
          <a-select-option
            v-for="adTag in adTags"
            :key="adTag.id"
            :value="adTag.id"
          >
            <span
              class="ml-2 py-1 px-3"
              :style="{
                backgroundColor: adTag.color,
                height: '20px',
                borderRadius: '2px',
              }"
            >
              {{ adTag.name }}
            </span>
          </a-select-option>
        </a-select>
      </a-col>
      <a-col :md="24" :sm="24">
        <div class="d-flex justify-content-center">
          <a-button type="primary" @click="filter"> Apply filters </a-button>
        </div>
      </a-col>
    </a-row>
  </a-card>
</template>

<script>
import { reactive, toRefs } from 'vue';
import EnLabel from '@/components/atoms/EnLabel.vue';
import EyesColors from '@/json/EyesColors.json';
import HairColors from '@/json/HairColors.json';
import AdService from '@/services/AdService';

export default {
  props: {
    ethnicities: { type: Array },
    adTags: { type: Array },
    filters: { type: Object },
  },
  components: {
    EnLabel,
  },
  setup(props, { emit }) {
    const filterOption = (input, option) => option.componentOptions.children[0].text
      .toUpperCase()
      .indexOf(input.toUpperCase()) >= 0;

    const defaultFilters = {
      ethnicityid: props.filters.ethnicityid ?? '',
      age: props.filters.age ?? '',
      eyes: props.filters.eyes ?? '',
      hair: props.filters.hair ?? '',
      height: props.filters.height ?? '',
      tag: props.filters.tag ?? '',
    };

    const params = reactive({
      ...defaultFilters,
    });

    const filter = () => {
      emit('click:filter', params);
      Object.assign(params, defaultFilters);
    };

    return {
      EyesColors,
      HairColors,
      filterOption,
      AdService,
      filter,
      ...toRefs(params),
    };
  },
};
</script>
