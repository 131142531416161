<template>
  <a-modal
    title="Renew ad"
    :visible="value"
    @cancel="$emit('input', false)"
    width="600px"
  >
    <div class="d-flex mb-5 black-white-filter">
      <div
        class="ad-info-image pointer"
        @click="goTo(`/model/${currentAd.id}`)"
      >
        <a-avatar :src="currentAd.photos[0]" shape="square" class="mr-5" :size="150" />
      </div>
      <div class="ad-info-details">
        <div class="ad-info-details-views">
          {{ currentAd.views }}
          <a-icon class="ml-2 ih-11" type="white-eye" />
        </div>
        <p class="fs-10 fw-medium mb-4 white-color" v-if="currentAd.created_at">
          Created at
          {{ formatRelative(new Date(currentAd.created_at), new Date()) }}
        </p>

        <div class="d-flex mb-2">
          <div
            v-if="currentAd.plan !== 'basic'"
            :class="`ad-info-details-plan-${currentAd.plan}${
              currentAd.isNew ? ' mr-2' : ''
            }`"
          >
            <a-icon class="ih-11" :type="getPlanIcon(currentAd.plan)" />
          </div>
          <div v-if="currentAd.isNew" class="ad-info-details-plan-new">
            <a-icon class="ih-14" type="white-fire" />
          </div>
        </div>

        <div class="fs-18 fw-semi-bold mb-1">
          {{ currentAd.name }}
        </div>

        <div class="fs-12 fw-normal mb-2">
          {{ currentAd.title }}
        </div>

        <div>
          <a-tag
            v-for="(tag, index) in currentAd.tag"
            color="#14CDD4"
            :key="index"
          >
            {{ tag.name }}
          </a-tag>
        </div>
      </div>
    </div>
    <a-row class="mb-3" :gutter="[20, 20]">
      <a-col :md="12" :sm="24">
        <en-plan-card
          icon="white-crown"
          name="Gold Vip"
          :value="`£${plansPrices.gold}`"
          id="gold"
          :selected="currentAd.selectedPlan === 'gold'"
          color="#ffffff"
          backgroundColor="#F7B422"
          :isLoading="isPricesLoading"
          :items="goldItems"
          @click:selected="$emit('update:selectedPlan', $event)"
        />
      </a-col>
      <a-col :md="12" :sm="24">
        <en-plan-card
          name="Silver"
          id="silver"
          :value="`£${plansPrices.silver}`"
          color="#ffffff"
          backgroundColor="#838282"
          :isLoading="isPricesLoading"
          icon="white-star"
          :selected="currentAd.selectedPlan === 'silver'"
          :items="silverItems"
          @click:selected="$emit('update:selectedPlan', $event)"
        />
      </a-col>

      <a-col :md="12" :sm="24">
        <en-plan-card
          name="Basic"
          id="basic"
          value="Free"
          :isLoading="isPricesLoading"
          color="#000000"
          backgroundColor="#fff"
          icon="white-star"
          :selected="currentAd.selectedPlan === 'basic'"
          :items="freeItems"
          @click:selected="$emit('update:selectedPlan', $event)"
        />
      </a-col>
    </a-row>
    <en-service-tag
      name="Tag new"
      value="£4,99"
      icon="white-fire"
      :selected="currentAd.isNewSelected"
      @click:selected="$emit('update:isNewSelected', $event)"
    />

    <template slot="footer">
      <a-button key="back" @click="$emit('input', false)"> Cancel </a-button>
      <a-button
        key="submit"
        type="primary"
        :disabled="isPricesLoading"
        :loading="isLoading"
        @click="renewAd"
      >
        Renew
      </a-button>
    </template>
  </a-modal>
</template>

<script>
import useAd from '@/composables/useAd';
import EnPlanCard from '@/components/molecules/EnPlanCard.vue';
import EnServiceTag from '@/components/molecules/EnServiceTag.vue';
import {
  formatDistanceToNowStrict,
  formatRelative,
  isBefore,
  formatDistanceToNow,
} from 'date-fns';

export default {
  props: {
    value: { type: Boolean },
    isLoading: { type: Boolean },
    isPricesLoading: { type: Boolean },
    currentAd: { type: Object },
    plansPrices: { type: Object },
  },
  components: {
    EnPlanCard,
    EnServiceTag,
  },
  setup(props, { emit }) {
    const {
      goldItems, silverItems, freeItems, getPlanIcon,
    } = useAd();
    const renewAd = () => {
      const payload = {
        plan: props.currentAd.selectedPlan,
        isNew: props.currentAd.isNewSelected,
        id: props.currentAd.id,
      };
      emit('onRenew', payload);
      emit('input', false);
    };

    return {
      goldItems,
      silverItems,
      freeItems,
      renewAd,
      formatDistanceToNowStrict,
      formatRelative,
      isBefore,
      formatDistanceToNow,
      getPlanIcon,
    };
  },
};
</script>

<style lang="scss">
@import "../EnAdInfo.scss";
</style>
