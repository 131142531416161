<template>
  <div
    class="burger-menu"
    :class="burgerClass"
    @click="$emit('click:toggle', !isOpen)"
  >
    <div class="burger-menu-line"></div>
    <div class="burger-menu-line"></div>
    <div class="burger-menu-text">Menu</div>
  </div>
</template>

<script>
import { computed } from 'vue';

export default {
  props: {
    isOpen: Boolean,
  },
  setup(props) {
    const burgerClass = computed(() => (props.isOpen ? 'opened' : ''));
    return { burgerClass };
  },
};
</script>

<style lang="scss">
.light-mode {
  .burger-menu {
    &-line {
      background-color: #000;
    }
  }
}

.burger-menu {
  cursor: pointer;
  &-line {
    background-color: #fff;
    height: 0.25rem !important;
    margin: 0.3rem 0;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 2px;

    &:first-child {
      width: 2.125rem;
      transform: rotate(0);
    }

    &:nth-child(2) {
      width: 1.75rem;
      transform: translateY(0) rotate(0);
    }
  }

  &-text {
    line-height: 0.4rem;
    font-size: 10px;
    opacity: 1;
  }

  &.opened {
    .burger-menu-line {
      &:first-child {
        transform: rotate(45deg);
      }

      &:nth-child(2) {
        width: 2.125rem;
        transform: translateY(0.8rem) rotate(-45deg);
      }
    }

    .burger-menu-text {
      opacity: 0;
    }
  }
}
</style>
