<template>
  <a-modal
    :visible="value"
    :footer="null"
    :dialog-style="{ top: '20px' }"
    wrapClassName="re-advisory-modal"
    width="99%"
    :closable="false"
  >
    <a-row :gutter="[45, 45]">
      <a-col :sm="24" :md="24" :lg="12">
        <div class="re-advisory-modal-flyer">
          <a-avatar
            class="re-advisory-modal-flyer-image"
            :src="advisoryImage"
            :size="700"
            shape="square"
          />
          <div class="re-advisory-modal-flyer-info">
            <p class="fs-30 fw-medium white-color m-0">Welcome to</p>
            <img :src="logo" class="mt-2 mb-5" />
            <p class="fs-12 fw-semi-bold white-color">
              You are about to enter a website that is designed for ADULTS ONLY
              and may contain pictures, graphics and other content that some
              viewers may find offensive. If you are under the age of 18, if
              such material offends you or if it is illegal to view such
              material as per the applicable laws of the state or country from
              which you are accessing the website, we request you to please exit
              the website now.
            </p>
          </div>
        </div>
      </a-col>
      <a-col :sm="24" :md="24" :lg="12">
        <a-row :gutter="[20, 20]">
          <a-col :sm="24">
            <p class="fs-18 pink-color-1 fw-semi-bold">
              PLEASE READ THE FOLLOWING CAREFULLY BEFORE PROCEEDING FURTHER
            </p>
          </a-col>
          <a-col :sm="24">
            <p class="fs-20 fw-semi-bold">
              By entering the website, you acknowledge and agree that
            </p>
          </a-col>
          <a-col :sm="24" :md="12">
            <p class="fs-12 fw-semi-bold">
              • You are above 18 years of age (or the legal age of majority as
              per your local or national laws).
            </p>
          </a-col>
          <a-col :sm="24" :md="12">
            <p class="fs-12 fw-semi-bold">
              • You will not allow anyone below the age of 18 (or under the age
              of majority as per your local or national laws) to have access to
              the website.
            </p>
          </a-col>
          <a-col :sm="24" :md="12">
            <p class="fs-12 fw-semi-bold">
              • You are not offended by nudity, pornography, sexually explicit
              materials, or consensual adult sexual activities.
            </p>
          </a-col>
          <a-col :sm="24" :md="12">
            <p class="fs-12 fw-semi-bold">
              • You have read and accepted the website Terms & Conditions and
              Privacy Policy and accept all responsibility for your own actions.
            </p>
          </a-col>
          <a-col :sm="24" :md="12">
            <p class="fs-12 fw-semi-bold">
              • If you are accessing the website with the intention of uploading
              any adult content, you further acknowledge and agree that you will
              not upload any pornographic content depicting actual sexual
              intercourse, non-consensual sexual activity, sexual activity with
              an underage person, rape, self-harm, torture, mutilation,
              bestiality, necrophilia, genital mutilation, human trafficking and
              such other similar activities.
            </p>
          </a-col>
          <a-col :sm="24" :md="12">
            <p class="fs-12 fw-semi-bold">
              • Uploading of any adult content on behalf of anyone without their
              express informed consent is strictly prohibited and will attract
              penal provisions.
            </p>

            <a href="https://www.rtalabel.org" target="_blank"><img :src="rtaLogo" class="mt-12" /></a>
          </a-col>
        </a-row>
        <a-row :gutter="[45, 20]" class="mt-5">
          <a-col :sm="24" :md="12">
            <a-button block @click="close"> EXIT </a-button>
          </a-col>
          <a-col :sm="24" :md="12">
            <a-button block type="primary" @click="$emit('input', false)">
              ENTER NOW
            </a-button>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </a-modal>
</template>

<script>
import advisoryImage from '@/assets/images/svg/advisory-image.svg';
import logo from '@/assets/images/svg/reality-escorts-dark.svg';
import rtaLogo from '@/assets/images/png/rta-logo.png';

export default {
  props: {
    value: { type: Boolean },
  },
  setup() {
    const close = () => {
    };

    return {
      advisoryImage,
      logo,
      rtaLogo,
      close,
    };
  },
};
</script>

<style lang="scss">
.re-advisory-modal {
  &-flyer {
    position: relative;

    &-image {
      width: 100% !important;
      max-width: 100%;
      height: 630px !important;
      border-radius: 19px !important;

    }

    &-info {
      padding: 25px 33px;
      position: absolute;
      bottom: 0;

      img {
        height: auto;
        max-width: 500px;
        width: 100%;
      }
    }
  }

  .ant-modal {
    max-width: 1276px;

    .ant-modal-content {
    border-radius: 19px;
    margin: 0 5px;

    background-color: #fff;
    .ant-modal-body {
      :is(h1, h2, h3, h4, h5, h6, p) {
        color: #000;
      }
      border-radius: 19px;

      background-color: #fff;
    }
  }
  }

}
</style>
