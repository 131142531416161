<template>
  <container class="d-flex justify-content-center my-15">
    <div class="w-mobile w-100">
      <div
        v-if="isLoading"
        class="d-flex align-center justify-center flex-column mt-10 mb-16"
      >
        <en-skeleton
          type="rect"
          width="72px"
          rounded
          :radius="500"
          height="72px"
          class="mx-5 mb-8 md-d-none"
          wave-color="rgba(255, 255, 255, 0.3)"
          animation="wave"
        />
        <en-skeleton
          type="rect"
          width="243px"
          rounded
          :radius="5"
          height="27px"
          class="mx-5 mb-4 md-d-none"
          wave-color="rgba(255, 255, 255, 0.3)"
          animation="wave"
        />
        <en-skeleton
          type="rect"
          width="443px"
          rounded
          :radius="5"
          height="16px"
          class="mx-5 mb-2 md-d-none"
          wave-color="rgba(255, 255, 255, 0.3)"
          animation="wave"
        />
        <en-skeleton
          type="rect"
          width="180px"
          rounded
          :radius="5"
          height="16px"
          class="mx-5 mb-16 md-d-none"
          wave-color="rgba(255, 255, 255, 0.3)"
          animation="wave"
        />
      </div>
      <template v-else>
        <a-result
          v-if="isPaymentFound"
          status="success"
          title="Successfully Purchased Ad!"
          sub-title="Now you can see your Ad online!"
        >
          <template #extra>
            <a-button key="console" type="primary" @click="goTo('/my-account')">
              Go to My Account
            </a-button>
          </template>
        </a-result>
        <a-result
          v-else
          status="error"
          title="Payment not found"
          sub-title="Please make sure you payed the Ad.
         If you have any problem with the payment contact us."
        >
          <template #extra>
            <a-button key="console" type="primary" @click="goTo('/contact-us')">
              Contact Us
            </a-button>
          </template>
        </a-result>
      </template>
    </div>
  </container>
</template>

<script>
import useGlobal from '@/composables/useGlobal';
import AdService from '@/services/AdService';
import { ref } from 'vue';
import { message } from 'ant-design-vue';

export default {
  metaInfo() {
    return {
      title: 'Reality Escorts',
      meta: [{
        name: 'description', content: 'Find the best independent and agency escorts at Reality Escorts.',
      }],
    };
  },
  props: {
    id: { type: String },
  },
  setup(props) {
    const { goTo } = useGlobal();

    const isLoading = ref(false);

    const isPaymentFound = ref(false);

    const updateAd = async (ad) => {
      try {
        const payload = { plan: ad.plan, isNew: false };
        if (ad.lastBuy.includes('basic')) payload.plan = 'basic';
        if (ad.lastBuy.includes('gold')) payload.plan = 'gold';
        if (ad.lastBuy.includes('silver')) payload.plan = 'silver';
        if (ad.lastBuy.includes('isnew')) payload.isNew = true;
        if (ad.lastBuy.includes('publishing')) await AdService.renewAd(props.id, { ...payload, isPublishing: true });
        if (ad.lastBuy.includes('renew') && !ad.lastBuy.includes('publishing')) await AdService.renewAd(props.id, payload);
        else await AdService.upgradeAd(props.id, payload);
        await AdService.clearPayment(props.id);
        await AdService.activateAd(props.id);
        isPaymentFound.value = true;
      } catch (error) {
        if (error.response.data.code === 3000) {
          message.warning('Payment successfully processed! Your ad will be published after verifying your profile.', 4);
          isPaymentFound.value = true;
        } else if (error.response.data.name.includes('check your email')) {
          message.warning('Payment successfully processed! Your ad will be published once your email is activated.', 4);
          isPaymentFound.value = true;
        }
      }
    };

    const checkPayment = async (id) => {
      try {
        isLoading.value = true;
        const { data } = await AdService.fetchAds({ page: 1, id, profile: true });
        const [ad] = data.result;
        if (ad.lastBuy) {
          await updateAd(ad);
        } else {
          isPaymentFound.value = false;
        }
      } catch (error) {
        isPaymentFound.value = false;
      } finally {
        isLoading.value = false;
      }
    };

    if (props.id) {
      checkPayment(props.id);
    } else {
      goTo('/post-ad');
    }

    return {
      goTo,
      isLoading,
      isPaymentFound,
    };
  },
};
</script>
