<template>
  <div>
    <a-row :gutter="[20, 20]" class="mt-10">
      <a-col :sm="24" v-for="ad in ads" :key="ad.id">
        <en-ad-info :ad="ad" @onConfirm="onConfirm" />
      </a-col>
    </a-row>

    <div
      class="d-flex justify-content-center mt-15"
      v-if="!ads.length && !isLoading"
    >
      No ads found
    </div>
    <template v-if="isLoading">
      <a-row :gutter="[20, 20]">
        <a-col :sm="24" v-for="item in 4" :key="item">
          <en-ad-info-skeleton />
        </a-col>
      </a-row>
    </template>

    <div class="d-flex justify-content-center w-100">
      <a-button
        type="link"
        v-if="hasNextPage && !isLoading"
        @click="loadMoreData"
      >
        Load more
      </a-button>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { message } from 'ant-design-vue';
import useGlobal from '@/composables/useGlobal';
import useUser from '@/composables/useUser';
import AdService from '@/services/AdService';
import EnAdInfo from '@/components/organisms/EnAdInfo/EnAdInfo.vue';
import EnAdInfoSkeleton from '@/components/organisms/EnAdInfoSkeleton.vue';

export default {
  components: { EnAdInfo, EnAdInfoSkeleton },
  setup() {
    const { goTo } = useGlobal();
    const { user } = useUser();
    const params = ref({ page: 1, limit: 6, profile: true });
    const isLoading = ref(false);
    const ads = ref([]);

    const hasNextPage = ref(false);

    const getDeadline = (ad) => {
      let boostTime = 0;
      if (ad.plan === 'silver') boostTime = 60;
      if (ad.plan === 'gold') boostTime = 30;
      return new Date(ad.lastBoosted).getTime() + 1000 * 60 * boostTime;
    };

    const fetchUserAds = async (parameters) => {
      try {
        isLoading.value = true;
        const { data } = await AdService.fetchAds(parameters);
        ads.value = [
          ...ads.value,
          ...data.result.map((ad) => ({ ...ad, deadline: getDeadline(ad) })),
        ];
        hasNextPage.value = !!data.next.page;
      } catch (error) {
        message.error(error.message, 4);
      } finally {
        isLoading.value = false;
      }
    };

    fetchUserAds(params.value);

    const loadMoreData = () => {
      params.value = { ...params.value, page: params.value.page + 1 };
      fetchUserAds(params.value);
    };

    const onConfirm = () => {
      ads.value = [];
      params.value = { page: 1, limit: 5, profile: true };
      fetchUserAds(params.value);
    };

    return {
      goTo,
      ads,
      isLoading,
      hasNextPage,
      loadMoreData,
      params,
      fetchUserAds,
      user,
      onConfirm,
    };
  },
};
</script>
