<template>
  <a-form-model
    ref="profileForm"
    :model="profile"
    :rules="rules"
    @submit="handleSubmit"
  >
    <div class="mt-10 p-1">
      <a-row :gutter="[20, 20]">
        <a-col :sm="24" :md="12">
          <a-upload
            name="attachment"
            list-type="picture-card"
            class="avatar-uploader"
            :class="imageUrl ? 'has-image' : ''"
            :show-upload-list="false"
            :action="actionUrl"
            :before-upload="beforeUpload"
            :headers="{ authorization: user.acessToken }"
            @change="handleChange"
          >
            <a-avatar
              :src="imageUrl"
              v-if="imageUrl"
              shape="square"
              :size="200"
            />
            <div v-else>
              <a-icon :type="loading ? 'loading' : 'white-camera'" />
            </div>
          </a-upload>
        </a-col>
        <a-col :sm="24" :md="12">
          <en-label icon="pink-people" iconWidth="colors" class="mb-4">
            <span class="fs-13 fw-medium ml-3">Name</span>
          </en-label>
          <a-input v-model="name" class="mb-5" placeholder="Enter your name" />
          <en-label icon="pink-mail" iconWidth="colors" class="mb-4">
            <span class="fs-13 fw-medium ml-3">E-mail</span>
          </en-label>
          <a-input
            v-model="email"
            class="mb-3"
            disabled
            placeholder="Enter your email here"
          />
        </a-col>
      </a-row>
      <div class="w-50 pr-2 mt-3">
        <en-label icon="pink-calendar" iconWidth="15px" class="mb-4">
          <span class="fs-13 fw-medium ml-3">Age</span>
        </en-label>
        <a-input-number v-model="age" class="w-100" :min="18" />
      </div>
      <div class="w-100 pr-2 mt-10">
        <a-button
          type="primary"
          block
          class="d-flex align-items-center justify-content-center"
          @click="isChangingPassword = !isChangingPassword"
        >
          <a-icon type="white-lock" /> Redefine password
        </a-button>
        <a-form-model-item v-if="isChangingPassword" prop="password">
          <a-input
            v-model="password"
            class="mt-5"
            placeholder="Enter your new password"
            type="password"
            autocomplete="off"
          />
        </a-form-model-item>

        <a-button
          type="secondary"
          block
          class="mt-10"
          html-type="submit"
          :loading="processing"
        >
          Save
        </a-button>
      </div>
    </div>
  </a-form-model>
</template>

<script>
import {
  computed, reactive, ref, toRefs,
} from 'vue';
import { message } from 'ant-design-vue';
import EnLabel from '@/components/atoms/EnLabel.vue';
import useUser from '@/composables/useUser';
import AuthService from '@/services/AuthService';

export default {
  components: {
    EnLabel,
  },
  setup() {
    const { user, setUser } = useUser();

    const profile = reactive({
      ...JSON.parse(localStorage.getItem('user')),
      password: '',
    });

    const loading = ref(false);
    const imageUrl = ref(profile.photo);

    const handleChange = (info) => {
      if (info.file.status === 'uploading') {
        loading.value = true;
        return;
      }

      if (info.file.status === 'done') {
        imageUrl.value = info.file.response;
        profile.photo = info.file.response;
        loading.value = false;
      }
    };

    const beforeUpload = (file) => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        message.error('You can only upload JPG file!');
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
      }
      return isJpgOrPng && isLt2M;
    };

    const actionUrl = `${process.env.VUE_APP_BASE_URL}/model-ad/upload`;
    const isChangingPassword = ref(false);

    const processing = ref(false);
    const submitProfileUpdate = async () => {
      try {
        if (!isChangingPassword.value) profile.password = '';
        processing.value = true;
        await AuthService.updateAccount(profile);
        localStorage.setItem('user', JSON.stringify(profile));
        delete profile.password;
        setUser(profile);
        message.success('Profile updated!');
      } catch (error) {
        message.error('An error has occurred.', 4);
      } finally {
        processing.value = false;
      }
    };

    const passwordValidation = (rule, value, callback) => {
      if (value.length < 6) {
        callback(new Error('Please enter at least 6 characters'));
      }
      callback();
    };

    const rules = computed(() => {
      if (isChangingPassword.value) {
        return {
          password: [{ validator: passwordValidation, trigger: 'change' }],
        };
      }
      return {};
    });

    const profileForm = ref();

    const handleSubmit = (e) => {
      e.preventDefault();
      profileForm.value.validate(async (valid) => {
        if (valid) {
          submitProfileUpdate();
          isChangingPassword.value = false;
        }
      });
    };

    return {
      ...toRefs(profile),
      loading,
      profile,
      imageUrl,
      handleChange,
      beforeUpload,
      actionUrl,
      user,
      submitProfileUpdate,
      processing,
      isChangingPassword,
      rules,
      profileForm,
      handleSubmit,
    };
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_variables.scss";

.avatar-uploader {
  width: 200px;
  height: 200px;

  &-image {
    width: 100%;
    height: auto;
  }

  .ant-upload {
    padding: 0px !important;

    &-select-picture-card {
      margin: 0px !important;
      border: none !important;
      width: 200px !important;
      height: 200px !important;
      background-color: $primary-color !important;
      border-radius: 5px !important;
    }
  }
}
</style>
