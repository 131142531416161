<template>
  <div class="w-100">
    <div :class="`${ad.plan}-divider`" />
    <container class="d-flex justify-content-center mb-15">
      <en-model-detail-skeleton class="w-100" v-if="loading" />
      <div v-else class="model-detail w-100">
        <div class="model-detail-body">
          <div class="modal-detail-body-info mt-6">
            <a-row :gutter="{ xs: 8, sm: 16, md: 35 }" class="z-0">
              <a-col :sm="24" :md="12">
                <div class="d-none md-d-block mb-10">
                  <div class="d-flex align-items-center">
                    <div
                      class="ad-plan-icon"
                      v-if="ad.plan === 'gold' || ad.plan === 'silver'"
                      :class="ad.plan"
                    >
                      <a-icon class="ih-35" :type="planIcon" />
                    </div>
                    <div
                      v-if="ad.isNew"
                      class="d-flex align-items-center justify-content-center"
                      style="width: 70px; height: 70px"
                    >
                      <a-icon v-if="ad.isNew" class="ih-35" type="green-fire" />
                    </div>
                  </div>
                  <div class="model-detail-header mt-8">
                    <h1 class="fs-36 fw-semi-bold mb-3">
                      {{ ad.name }}
                      <img v-if="ad.userVerified" class="verified-icon ws-35" />
                    </h1>
                    <p class="fs-13 fw-semi-bold">{{ ad.title }}</p>
                  </div>
                </div>
                <en-carousel
                  v-if="adMedia.length"
                  class="mt-20"
                  :items="adMedia"
                />
                <div class="d-flex justify-content-center">
                  <video
                    v-if="ad.videos.length"
                    width="100%"
                    height="auto"
                    class="mt-5"
                    style="max-width: 500px"
                    controls
                  >
                    <source :src="ad.videos[0]" />
                    Your browser does not support the video tag.
                  </video>
                </div>
                <div class="shadow my-5">
                  <a-row>
                    <a-col :xs="6">
                      <div
                        class="model-detail-body-contact-item"
                        @click="goTo('/')"
                      >
                        <a-icon type="pink-home" />
                        <div class="fs-15">Home</div>
                      </div>
                    </a-col>
                    <a-col :xs="6">
                      <div
                        class="model-detail-body-contact-item"
                        @click="phoneCall(ad.phone)"
                      >
                        <a-icon type="pink-phone" />
                        <div class="fs-15">Call</div>
                      </div>
                    </a-col>
                    <a-col :xs="6">
                      <div
                        class="model-detail-body-contact-item"
                        @click="
                          sendMessageByWhatsApp(whatsAppMessage, ad.phone)
                        "
                      >
                        <a-icon type="green-whatsapp" />
                        <div class="fs-15">Whatsapp</div>
                      </div>
                    </a-col>
                    <a-col :xs="6">
                      <div
                        class="model-detail-body-contact-item"
                        @click="goTo(`/contact-us/${ad.id}`)"
                      >
                        <a-icon type="solution" />
                        <div class="fs-15">Report</div>
                      </div>
                    </a-col>
                  </a-row>
                </div>
              </a-col>
              <a-col :sm="24" :md="12">
                <div class="md-d-none mb-10">
                  <div class="d-flex align-items-center">
                    <div
                      class="ad-plan-icon"
                      v-if="ad.plan === 'gold' || ad.plan === 'silver'"
                      :class="ad.plan"
                    >
                      <a-icon class="ih-35" :type="planIcon" />
                    </div>
                    <div
                      v-if="ad.isNew"
                      class="d-flex align-items-center justify-content-center"
                      style="width: 70px; height: 70px"
                    >
                      <a-icon v-if="ad.isNew" class="ih-35" type="green-fire" />
                    </div>
                  </div>
                  <div class="model-detail-header mt-8">
                    <h1 class="fs-36 fw-semi-bold mb-3">
                      {{ ad.name }}
                      <img v-if="ad.userVerified" class="verified-icon ws-35" />
                    </h1>
                    <p class="fs-13 fw-semi-bold">{{ ad.title }}</p>
                  </div>
                </div>

                <h4 class="fs-36 fw-semi-bold mb-5">About me</h4>

                <p
                  class="fs-13"
                  style="white-space: pre; white-space: pre-wrap"
                >
                  {{ ad.description }}
                </p>
              </a-col>
            </a-row>

            <hr />

            <a-row :gutter="{ xs: 8, sm: 16, md: 35 }">
              <a-col :sm="24" :md="12">
                <h3 class="fs-20 fw-semi-bold mt-8 mb-7">Informations</h3>

                <a-row class="mb-5">
                  <a-col :xs="12">
                    <en-label icon="pink-calendar" iconWidth="15px">
                      <div class="fs-13 ml-2 fw-semi-bold lh-0 mt-1">Age</div>
                    </en-label>
                  </a-col>
                  <a-col :xs="12">
                    <div class="fs-13 fw-semi-bold lh-0 mt-1">{{ ad.age }}</div>
                  </a-col>
                </a-row>

                <a-row class="mb-5">
                  <a-col :xs="12">
                    <en-label icon="pink-eye" iconWidth="15px">
                      <div class="fs-13 ml-2 fw-semi-bold lh-0 mt-1">Eyes</div>
                    </en-label>
                  </a-col>
                  <a-col :xs="12">
                    <div class="fs-13 fw-semi-bold lh-0 mt-1">
                      {{ ad.eyes }}
                    </div>
                  </a-col>
                </a-row>
                <a-row class="mb-5">
                  <a-col :xs="12">
                    <en-label icon="pink-face" iconWidth="15px">
                      <div class="fs-13 ml-2 fw-semi-bold lh-0 mt-1">Hair</div>
                    </en-label>
                  </a-col>
                  <a-col :xs="12">
                    <div class="fs-13 fw-semi-bold lh-0 mt-1">
                      {{ ad.hair }}
                    </div>
                  </a-col>
                </a-row>

                <a-row class="mb-5">
                  <a-col :xs="12">
                    <en-label icon="pink-face" iconWidth="15px">
                      <div class="fs-13 ml-2 fw-semi-bold lh-0 mt-1">
                        Height
                      </div>
                    </en-label>
                  </a-col>
                  <a-col :xs="12">
                    <div class="fs-13 fw-semi-bold lh-0 mt-1">
                      {{ ad.height }}
                    </div>
                  </a-col>
                </a-row>

                <a-row class="mb-5">
                  <a-col :xs="12">
                    <en-label icon="pink-globe" iconWidth="15px">
                      <div class="fs-13 ml-2 fw-semi-bold lh-0 mt-1">
                        Nationality
                      </div>
                    </en-label>
                  </a-col>
                  <a-col :xs="12">
                    <div class="fs-13 fw-semi-bold lh-0 mt-1">
                      {{ ad.ethnicity.name }}
                    </div>
                  </a-col>
                </a-row>
              </a-col>
              <a-col :sm="24" :md="12">
                <h3 class="fs-20 fw-semi-bold mb-7 mt-8">Contact</h3>

                <a-row class="mb-5" :gutter="[20, 30]">
                  <a-col :xs="12">
                    <en-label iconWidth="15px">
                      <div class="fs-13 ml-2 fw-medium mt-1">Phone:</div>
                    </en-label>
                  </a-col>
                  <a-col :xs="12">
                    <div class="fs-13 fw-light mt-1">{{ ad.phone || "-" }}</div>
                  </a-col>
                  <a-col :xs="12">
                    <en-label iconWidth="15px">
                      <div class="fs-13 ml-2 fw-medium mt-1">Location:</div>
                    </en-label>
                  </a-col>
                  <a-col :xs="12">
                    <div class="fs-13 fw-light mt-1">{{ ad.city.name }}</div>
                  </a-col>
                  <a-col :xs="12">
                    <en-label iconWidth="15px">
                      <div class="fs-13 ml-2 fw-medium mt-1">
                        District{{ ad.district.length > 1 ? "s" : "" }}:
                      </div>
                    </en-label>
                  </a-col>
                  <a-col :xs="12">
                    <div
                      v-for="district in ad.district"
                      class="fs-13 fw-light mt-1 mb-2"
                      :key="district.id"
                    >
                      {{ district.name }}
                    </div>
                  </a-col>
                </a-row>
              </a-col>
            </a-row>

            <hr />
            <a-row :gutter="{ xs: 8, sm: 16, md: 35 }">
              <a-col :sm="24" :md="12">
                <h3 class="fs-20 fw-semi-bold mb-7 mt-8">Rates</h3>
                <a-table
                  :columns="columns"
                  :data-source="rates"
                  size="small"
                  :pagination="false"
                  :locale="{
                    emptyText: 'No InCall or OutCall informed'
                  }"
                />
              </a-col>
              <a-col :sm="24" :md="12">
                <h3 class="fs-20 fw-semi-bold mb-7 mt-8">Services</h3>
                <a-row
                  v-for="service in ad.service"
                  :key="service.id"
                  class="mb-5"
                  :gutter="[10, 15]"
                >
                  <a-col :xs="12">
                    <en-display-box>{{ service.name }}</en-display-box>
                  </a-col>
                  <a-col :xs="12">
                    <en-display-box label="Price">{{
                      service.value
                    }}</en-display-box>
                  </a-col>
                </a-row>
              </a-col>
            </a-row>
          </div>
        </div>
      </div>
    </container>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { message } from 'ant-design-vue';
import EnLabel from '@/components/atoms/EnLabel.vue';
import EnDisplayBox from '@/components/atoms/EnDisplayBox.vue';
import EnModelDetailSkeleton from '@/components/molecules/EnModelDetailSkeleton.vue';
import AdService from '@/services/AdService';
import EnCarousel from '@/components/organisms/EnCarousel.vue';
import useGlobal from '@/composables/useGlobal';
import 'vueperslides/dist/vueperslides.css';

export default {
  props: {
    id: { type: [String, Number] },
  },
  metaInfo() {
    return { ...this.metaInfo };
  },
  components: {
    EnLabel,
    EnDisplayBox,
    EnModelDetailSkeleton,
    EnCarousel,
  },
  setup(props) {
    const metaInfo = ref({
      title: 'Reality Escorts',
      meta: [{
        name: 'description', content: 'Find the best independent and agency escorts at Reality Escorts.',
      }],
    });
    const breadcrumb = [
      { title: '', icon: 'home', link: '/' },
      { title: 'Ad detail', icon: '', link: '/' },
    ];
    const columns = [
      {
        title: 'Time',
        dataIndex: 'time',
        width: '20%',
        scopedSlots: { customRender: 'time' },
      },
      {
        title: 'InCall',
        dataIndex: 'inCall',
        scopedSlots: { customRender: 'inCall' },
        align: 'center',
      },
      {
        title: 'OutCall',
        dataIndex: 'outCall',
        scopedSlots: { customRender: 'outCall' },
        align: 'center',
      },
    ];

    const {
      sendMessageByWhatsApp, phoneCall, goTo, extractLastSubstring,
    } = useGlobal();

    const incrementAdViews = async () => {
      try {
        await AdService.incrementViews(extractLastSubstring(props.id));
      } catch (error) {
        message.error(error.response.data, 4);
      }
    };

    incrementAdViews();

    const ad = ref({});

    const adMedia = computed(() => {
      let waterMarkedPhotos = [
        ...ad.value.photos
          .map((url) => {
            if (url.includes('waterMarked')) return { url, type: 'image' };
            return false;
          })
          .filter(Boolean),
      ];

      if (ad.value.mainPhoto) {
        const indexOfMainPhotoUrl = ad.value.photos.indexOf(ad.value.mainPhoto);
        const waterMarkedMainPhotoUrl = ad.value.photos[indexOfMainPhotoUrl - 1];
        waterMarkedPhotos = [
          { url: waterMarkedMainPhotoUrl, type: 'image' },
          ...waterMarkedPhotos.filter(({ url }) => url !== waterMarkedMainPhotoUrl),
        ];
      }

      return waterMarkedPhotos;
    });

    const getMetaInfo = () => ({
      title: `${ad.value.name} | ${ad.value.city.name} | Reality Escorts`,
      meta: [{
        name: 'description', content: `Find ${ad.value.name} exclusively on Reality Escorts.
       We have hundreds of beautiful call girls in your area to meet now`,
      }],
    });

    const loading = ref(false);
    const fetchAds = async () => {
      try {
        loading.value = true;
        const { data } = await AdService.fetchAds({ page: 1, id: extractLastSubstring(props.id) });
        const [adDetail] = data.result;
        ad.value = adDetail;
        metaInfo.value = getMetaInfo();
      } catch (error) {
        message.error(error.response.data, 4);
      } finally {
        loading.value = false;
      }
    };
    fetchAds();

    const timeLabels = {
      15: '15 Min',
      30: '30 Min',
      60: '1 Hour',
      90: '90 Min',
      120: '2 Hours',
      180: '3 Hours',
      240: '4 Hours',
      480: 'Overnight',
    };

    const getTimeLabel = (rateKey) => timeLabels[rateKey];

    const getRateValue = (stringValue) => (parseFloat(stringValue?.slice(2)) === 0 || !stringValue ? '-' : stringValue);

    const rates = computed(() => (Array.isArray(ad.value.rates)
      ? []
      : Object.keys(ad.value.rates.inCall)
        .filter((rateKey) => rateKey !== 'rates_incall_id')
        .map((rateKey) => ({
          key: rateKey,
          time: getTimeLabel(rateKey),
          inCall: getRateValue(ad.value.rates.inCall[rateKey]),
          outCall: getRateValue(ad.value.rates.outCall[rateKey]),
        }))));

    const planIcon = computed(() => {
      if (ad.value.plan === 'gold') return 'black-crown';
      if (ad.value.plan === 'silver') return 'black-star';
      return '';
    });

    const whatsAppMessage = computed(
      () => 'Hi, I just saw your profile on realityescorts.com I would like to see you…',
    );

    return {
      columns,
      rates,
      ad,
      loading,
      breadcrumb,
      adMedia,
      planIcon,
      sendMessageByWhatsApp,
      whatsAppMessage,
      phoneCall,
      goTo,
      metaInfo,
    };
  },
};
</script>

<style lang="scss" scoped>
.ad-plan-icon {
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.gold {
    background-color: #f7b422;
  }

  &.silver {
    background-color: #707070;
  }
}
.model-detail {
  &-body {
    &-contact {
      display: flex;
      justify-content: center;
      border-radius: 5px;
      box-shadow: 0px -20px 50px #0003;

      &-item {
        padding: 20px;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        align-items: center;
        i {
          margin-bottom: 8px;
        }
        &:not(:last-child) {
          margin-right: 40px;
        }
      }
    }

    &-info {
      color: #000;
    }
  }
}
</style>
