<template>
  <div class="en-layout">
    <en-header />
    <main>
      <section>
        <router-view />
      </section>
      <div id="components-back-top-demo-custom">
        <a-back-top>
          <a-icon type="arrow-up" />
        </a-back-top>
      </div>
      <en-footer />
    </main>
    <re-advisory-modal v-model="isAdvisoryModalVisible" />
  </div>
</template>

<script>
import EnFooter from '@/components/molecules/EnFooter.vue';
import EnHeader from '@/components/molecules/EnHeader.vue';
import ReAdvisoryModal from '@/components/molecules/ReAdvisoryModal.vue';
import useUser from '@/composables/useUser';
import { ref, watchEffect } from 'vue';
import router from '@/router/router';

export default {
  components: { EnFooter, EnHeader, ReAdvisoryModal },
  setup() {
    const { getLocalUser, setIsLightMode, isAuthenticated } = useUser();
    getLocalUser();

    const lightLayoutPaths = ['my-account', 'post-ad', 'model'];

    const isMainPage = ref(false);

    const isAdvisoryModalVisible = ref(
      !isAuthenticated.value && isMainPage.value,
    );

    watchEffect(() => {
      isMainPage.value = router.app.$route.fullPath === '/';
      isAdvisoryModalVisible.value = !isAuthenticated.value && isMainPage.value;
      const el = document.querySelector('body');
      if (lightLayoutPaths.includes(router.app.$route.fullPath.split('/')[1])) {
        el.classList.add('light-mode');
        setIsLightMode(true);
      } else {
        el.classList.remove('light-mode');
        setIsLightMode(false);
      }
    });

    return {
      isAdvisoryModalVisible,
    };
  },
};
</script>

<style lang="scss" scoped>
main {
  z-index: 0;
  min-height: calc(100vh - 126px);
}
</style>
