<template>
  <div class="page-404 my-15">
    <a-card >
      <div slot="title">404 - Page Not Found</div>
      <div slot="cover">
      </div>
      <div>
        <p>Oops! The page you are looking for cannot be found.</p>
        <p>Please check the URL or click the button below to go back to the homepage.</p>
      </div>
      <a-button type="primary" block @click="$router.push('/')">Go Home</a-button>
    </a-card>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: 'Reality Escorts',
      meta: [{
        name: 'description', content: 'Find the best independent and agency escorts at Reality Escorts.',
      }],
    };
  },
};
</script>

<style>
.page-404 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.page-404 a-card {
  text-align: center;
}

.page-404 a-button {
  margin-top: 24px;
}
</style>
