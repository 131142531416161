<template>
  <router-link :to="`/model/${formatUrlName(data.name, data.id)}`" class="gallery-item">
    <div class="gallery-item-header" :class="data.plan">
      <img v-if="data.userVerified" class="verified-icon ws-20" />

      <div class="fs-10 mt-2 px-8">
        {{ location }}
      </div>

      <div class="gallery-item-header-icon">
        <a-icon class="ih-11" :type="planIcon" />
        <a-icon
          v-if="data.isNew"
          class="gallery-item-header-new-icon ih-20"
          :class="data.plan !== 'basic' && 'mt-9'"
          type="green-fire"
        />

      </div>
    </div>
    <img :src="photo" />
    <div class="gallery-item-footer">
      <div>
        <a-tag v-for="(tag, index) in data.tag" :color="tag && tag.color" class="mb-1" :key="index">
          {{ tag && tag.name }}
        </a-tag>
      </div>
      <a-tooltip placement="bottom">
        <template slot="title">{{ description }}</template>
        <span class="fs-20 fw-light text-truncate" style="max-width: 100%">{{
          description
        }}</span>
      </a-tooltip>
    </div>
  </router-link>
</template>

<script>
import { computed } from 'vue';
import useGlobal from '@/composables/useGlobal';

export default {
  props: {
    data: { type: Object },
  },
  setup(props) {
    const { formatUrlName } = useGlobal();
    const photo = computed(() => (props.data.mainPhoto || props.data.photos[1]));
    const location = computed(
      () => `${props.data.city.name} ${
        props.data.district.length ? `- ${props.data.district[0].name}` : ''
      }`,
    );
    const description = computed(
      () => `${props.data.name} - ${props.data.age}`,
    );
    const planIcon = computed(() => {
      if (props.data.plan === 'gold') return 'white-crown';
      if (props.data.plan === 'silver') return 'white-star';
      return '';
    });

    return {
      photo,
      location,
      description,
      planIcon,
      formatUrlName,
    };
  },
};
</script>

<style lang="scss">
.gallery-item {
  position: relative;
  width: 100%;
  height: 300px;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-items: center;
  max-height: 300px;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    color: #fff;
    &:before {
      opacity: 0.2;
    }
  }

  &:before {
    content: "";
    background-color: #000;
    opacity: 0.3;
    position: absolute;
    left: 0;
    transition: opacity 0.3s;
    width: 100%;
    height: 100%;
  }

  img {
    width: auto;
    height: 400px;
  }

  .verified-icon {
    position: absolute;
    top: 5px;
    z-index: 2;
    left: 5px;
    height: unset;
  }

  &-header {
    width: 100%;
    text-align: center;
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;

    @mixin icon-content {
      height: 27px;
      width: 27px;
      position: absolute;
      right: 0;
    }

    &.gold {
      border-top: 4px solid #f7b422;
      .gallery-item-header-icon {
        @include icon-content;
        background-color: #f7b422;
      }
    }

    &-new-icon {
      margin-top: 5px;
      height: 27px;
      width: 27px;
      position: absolute;
      right: 0;
    }

    &.silver {
      border-top: 4px solid #707070;
      .gallery-item-header-icon {
        @include icon-content;
        background-color: #707070;
      }
    }
  }

  &-footer {
    padding: 5px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    bottom: 0;
  }
}
</style>
