import router from '@/router/router';

const useGlobal = () => {
  const goTo = (link) => router.push(link).catch(() => { });

  const sendMessageByWhatsApp = (message, phone) => {
    window.open(`https://api.whatsapp.com/send?&text=${message}&phone=${phone}`, '_blank');
  };

  const phoneCall = (phoneNumber) => {
    window.open(`tel:${phoneNumber}`, '_self');
  };

  const emailValidation = (rule, value, callback) => {
    const re = /\S+@\S+\.\S+/;
    if (!re.test(value)) callback(new Error('Please input a valid e-mail'));
    else callback();
  };

  const nameValidation = (rule, value, callback) => {
    if (value.length === 0) callback(new Error('Please input a valid name'));
    callback();
  };

  const passwordValidation = (rule, value, callback) => {
    if (value.length < 6) { callback(new Error('Please enter at least 6 characters')); }
    callback();
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'auto' });
  };

  const formatUrlName = (name, id) => {
    const friendlyURL = name.toLowerCase().replace(/[^a-zA-Z0-9]+/g, '-');
    const finalURL = `${friendlyURL}-${id}`;
    return finalURL;
  };

  const extractLastSubstring = (str) => {
    const lastIndex = str.lastIndexOf('-');
    return str.slice(lastIndex + 1);
  };

  return {
    goTo,
    sendMessageByWhatsApp,
    phoneCall,
    emailValidation,
    nameValidation,
    passwordValidation,
    scrollTop,
    formatUrlName,
    extractLastSubstring,
  };
};

export default useGlobal;
