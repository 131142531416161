<template>
  <container class="d-flex justify-content-center my-15">
    <div class="login w-100">
      <a-spin tip="Redirecting..." :spinning="isLogging">
        <h1 class="fs-20 mb-11">Register</h1>

        <a-form-model
          ref="registerForm"
          :model="form"
          :rules="rules"
          layout="vertical"
        >
          <a-form-model-item :colon="false" prop="name">
            <div class="d-flex align-center lh-0" slot="label">
              <a-icon type="pink-face" class="mr-2" />Name
            </div>
            <a-input
              class="input-shadow"
              v-model="form.name"
              placeholder="Enter your name here"
            />
          </a-form-model-item>
          <a-form-model-item :colon="false" prop="email">
            <div class="d-flex align-center lh-0" slot="label">
              <a-icon type="pink-mail" class="mr-2" />E-mail
            </div>
            <a-input
              class="input-shadow"
              v-model="form.email"
              placeholder="Enter your email here"
            />
          </a-form-model-item>
          <a-form-model-item :colon="false" prop="password">
            <div class="d-flex align-center lh-0" slot="label">
              <a-icon type="pink-lock" class="mr-2" />Password
            </div>
            <a-input
              class="input-shadow"
              type="password"
              v-model="form.password"
              placeholder="Enter your password here"
            />
          </a-form-model-item>
        </a-form-model>

        <a-button
          type="secondary"
          class="mb-5 mt-5"
          :loading="loading"
          @click="submit"
          block
        >
          Enter
        </a-button>
      </a-spin>
    </div>
  </container>
</template>

<script>
import { reactive, ref } from 'vue';
import { message } from 'ant-design-vue';
import AuthService from '@/services/AuthService';
import useGlobal from '@/composables/useGlobal';
import useUser from '@/composables/useUser';

export default {
  metaInfo() {
    return {
      title: 'Reality Escorts',
      meta: [{
        name: 'description', content: 'Find the best independent and agency escorts at Reality Escorts.',
      }],
    };
  },
  setup() {
    const {
      goTo, emailValidation, nameValidation, passwordValidation,
    } = useGlobal();

    const { setUser } = useUser();

    const form = reactive({
      name: '',
      email: '',
      password: '',
    });

    const isLogging = ref(false);

    const rules = ref({
      name: [{ validator: nameValidation, trigger: 'change' }],
      email: [{ validator: emailValidation, trigger: 'change' }],
      password: [{ validator: passwordValidation, trigger: 'change' }],
    });

    const registerForm = ref();

    const login = async () => {
      try {
        const payload = {
          email: form.email,
          password: form.password,
        };
        const { data } = await AuthService.login(payload);
        setUser(data);
        localStorage.setItem('user', JSON.stringify(data));
        message.success('Welcome!', 4);
        goTo('/my-account');
      } catch (error) {
        message.error(error.message, 4);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    };

    const loading = ref(false);
    const submit = () => {
      registerForm.value.validate(async (valid) => {
        if (valid) {
          try {
            loading.value = true;
            await AuthService.signUp({ ...form, teste: 1 });
            message.success('Registered with success. Check your e-mail.', 4);
            loading.value = false;
            isLogging.value = true;
            setTimeout(login, 2000);
          } catch (error) {
            message.error(error.response.data.name, 4);
            loading.value = false;
          }
        }
      });
    };

    return {
      registerForm,
      form,
      rules,
      submit,
      loading,
      isLogging,
    };
  },
};
</script>

<style lang="scss">
.login {
  max-width: 489px;
}
</style>
