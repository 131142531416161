<template>
  <div class="service-tag">
    <div class="service-tag-name">
      <a-icon slot="prefix" class="mr-5" :type="icon" />
      <div class="fs-17 fw-medium">{{ name }}</div>
    </div>
    <div class="service-tag-value">
      <div class="fs-16 fw-semi-bold mb-2">{{ value }}</div>
      <en-toggle-button
        :selected="selected"
        @click:selected="$emit('click:selected', !selected)"
      />
    </div>
  </div>
</template>

<script>
import EnToggleButton from '@/components/atoms/EnToggleButton.vue';

export default {
  components: { EnToggleButton },
  props: {
    name: String,
    icon: String,
    value: String,
    selected: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.service-tag {
  display: flex;
  width: 100%;
  &-name {
    display: flex;
    width: 100%;
    align-items: center;
    background-color:#14D4A0;
    border-radius: 5px 0px 0px 5px;
    padding: 12px 24px;
  }

  &-value {
    width: 150px;
    color: #fff;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: #000;
    border-radius: 0px 5px 5px 0px;
    padding: 12px 16px;
  }
}
</style>
