<template>
  <a-breadcrumb  separator=">">
    <template v-for="(location, index) in locations">
      <a-breadcrumb-item
        v-if="isBreadcrumbItemVisible(location)"
        :key="index"
      >
        <span @click="onBreadcrumbClick(location)">
          {{ location.name }} ({{ location.amount }})
        </span>
      </a-breadcrumb-item>
    </template>

  </a-breadcrumb>
</template>

<script>
import { computed } from 'vue';

export default {
  props: {
    locationInfo: { type: Object },
  },
  setup(props, { emit }) {
    const locations = computed(() => {
      const defaultLocation = {
        country: {
          name: 'United Kingdom',
          id: '1',
          urlName: 'united-kingdom',
          adQuant: 5,
        },
      };

      const currentLocationInfo = props.locationInfo ? props.locationInfo : defaultLocation;

      return Object.keys(currentLocationInfo).map((locationType) => {
        const { name, urlName, adQuant } = currentLocationInfo[locationType];
        return {
          locationType, name, urlName, amount: adQuant,
        };
      });
    });

    const getUrl = (locationType) => {
      let url = '/escorts';
      for (let i = 0; i < locations.value.length; i += 1) {
        if (locations.value[i].urlName === 'united-kingdom') locations.value[i].urlName = 'uk';
        url += `/${locations.value[i].urlName}`;
        if (locations.value[i].urlName && locations.value[i].locationType === locationType) break;
      }
      return url;
    };

    const onBreadcrumbClick = (location) => {
      emit('click:breadcrumb', getUrl(location.locationType));
    };

    const isBreadcrumbItemVisible = (location) => !!location.name
    && (location.urlName !== 'london-region' && location.urlName !== 'greater-london');

    return {
      locations,
      getUrl,
      onBreadcrumbClick,
      isBreadcrumbItemVisible,
    };
  },
};
</script>

<style >
.ant-breadcrumb > span:not(:last-child):hover {
  color: #D4145A;
  cursor: pointer;
}

.ant-breadcrumb > span:last-child {
  pointer-events: none;
}
</style>
